import axios from 'axios';

export class PlanService {
    static getAll() {
        return axios.get('/plan/query_all').then(res => res.data);
    }

    static del(id) {
        return axios.delete('/plan/delete/' + id);
    }

    static delMilestone(id) {
        return axios.delete(`/plan/delete_milestone/${id}`);
    }

    static edit(data) {
        return axios.put('/plan/edit', data);
    }

    static getListByLeader(projectCode, stage) {
        return axios.get('/plan/list_by_leader', {
            params: {
                projectCode,
                stage
            }
        });
    }
    static delCooperateBuilt(id) {
        return axios.delete(`/plan/delete_cooperate_built/${id}`);
    }
}
