import * as constants from './constants';
import axios from 'axios';
import { MonitorService } from '../../../services/monitor';
import ProjectService from '../../../services/project';
import { type } from 'os';
import { param } from 'jquery';
import { message } from 'antd';
import AreaCodeService from '../../../services/areaCode';

/**
 * @description 通用
 */
const setData = value => ({
    type: constants.SET_DATA,
    value
});

const setCurrent = value => ({
    type: constants.SET_CURRENT,
    value
});

const setCount = value => ({
    type: constants.SET_COUNT,
    value
});
const setAllProject = value => ({
    type: constants.GET_ALLPROJECT,
    value
});

const setAreaCode = value => ({
    type: constants.GET_ALLAREACODE,
    value
});
/**
 * @description 删除改变数组
 */

export const changeData = data => {
    return dispatch => {
        dispatch(setData(data));
    };
};

/**
 *
 * @param url
 * @param value
 * @param current
 * @param data
 * @description 请求带分页的列表
 */

export const getPageList = (url, value, current, data, type) => {
    return dispatch => {
        axios
            .get(url, {
                params: {
                    type: type,
                    key: value,
                    page: current,
                    size: 25
                }
            })
            .then(res => {
                if (res.data.list) {
                    const newData = [...data, ...res.data.list];
                    const newCount = Math.ceil(res.data.total / 25);
                    dispatch(setData(newData));
                    dispatch(setCurrent(current));
                    dispatch(setCount(newCount));
                }
            });
    };
};

/**
 * @description 获取场站列表
 */
export const getStationList = (value, current, data = []) => {
    return dispatch => {
        dispatch(getPageList('/monitor_station/query', value, current, data, 1));
    };
};

/**
 * @description 获取泵站列表
 */
export const getPumpStationList = (value, current, data = []) => {
    return dispatch => {
        dispatch(getPageList('/monitor_station/query', value, current, data, 2));
    };
};

/**
 *
 * @param value
 * @param current
 * @description 获取管线列表
 */

export const getPipelineList = (value, current, data = []) => {
    return dispatch => {
        dispatch(getPageList('/cad/query?remark=pt', value, current, data, ''));
    };
};

/**
 * @description 获取项目列表
 */

export const getAllProjectList = (key = '') => {
    return dispatch => {
        ProjectService.getAll(key).then(list => {
            const data = list.map(it => {
                return { value: it.projectCode, text: it.projectName };
            });
            dispatch(setAllProject(data));
        });
    };
};

/**
 * @description 获取行政区域
 */

export const getAreaList = () => {
    return dispatch => {
        AreaCodeService.get().then(list => {
            dispatch(setAreaCode(list));
        });
    };
};
