import React from 'react';
import { message, Popconfirm } from 'antd';

import { HIcon } from '../simple';
import './index.scss';

interface PropType {
    props: {
        data: any;
    };
}

const ListItem = ({ props }: any) => {
    const { data, activeLineItem, handleClickLineItem, handleEidtLineItem, handleDelLineItem } = props;
    return (
        <div
            className={['h-line-list-item', activeLineItem && activeLineItem.id === data.id ? 'h-line-list-item-active' : ''].join(' ')}
            onClick={() => handleClickLineItem(data)}
        >
            <span>{data.name ? data.name : '未命名'}</span>
            <div>
                <HIcon
                    iconName={'icon-edit'}
                    newClass={'h-clickable'}
                    onClick={e => {
                        e.stopPropagation();
                        // console.log(data);
                        // console.log(data);
                        handleEidtLineItem(true, 'init', data.id, null);
                    }}
                />
                <Popconfirm
                    title="是否确认删除"
                    onConfirm={e => {
                        e.stopPropagation();
                        handleDelLineItem(data.id);
                    }}
                    onCancel={() => message.info('已取消')}
                    okText="是"
                    cancelText="否"
                >
                    <i className={['h-icon', 'iconfont', 'icon-delete', 'h-clickable'].join(' ')} />
                </Popconfirm>
            </div>
        </div>
    );
};

export default ListItem;
