/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { message, Upload, Button, Select } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HIcon, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import axios from 'axios';
import { GroupDataTypeMap, SourceType, UploadDataType } from '../../../utils/static-data';
import { connect } from 'react-redux';
import { DocumentModel } from '../store/model';
import { actionCreators } from '../store';
import { GroupModel } from '../../../components/model';
import './newForm.scss';
import { DocumentService } from '../../../services/document';

const { Option } = Select;

interface Props {
    groupList: GroupModel[];
    allProjectList: SourceType[];
    addGroup: (name) => void;
    updateGroupChange: (data) => void;
    onDeleteGroup: (id) => void;
    getAllProjectList: () => void;
    onSuccess: () => void;
}

interface States {
    baseInfo: any;
    fileList: any[];
    validation: boolean;
    uidList: any[];
}

class NewForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            baseInfo: {
                projectCode: undefined,
                groupId: undefined,
                unit: ''
            },
            fileList: [],
            validation: false,
            uidList: []
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
    }

    onChange = (key, value, index) => {
        const { fileList } = this.state;
        fileList[index][key] = value;
        this.setState({ fileList });
    };

    onBaseInfoChange = (key, value) => {
        const { baseInfo } = this.state;
        baseInfo[key] = value;
        this.setState({ baseInfo });
    };

    //手动添加

    addManually = () => {
        const { fileList } = this.state;
        const data: any = {};
        data.uid = '';
        data.name = '';
        data.isPublic = 1;
        data.fileUrl = '';
        data.type = 9;
        data.remark = '';
        data.version = '';
        data.size = '';
        data.key = 1;
        data.format = '';
        this.setState({
            fileList: [...fileList, data]
        });
    };

    save = () => {
        const { fileList, baseInfo } = this.state;
        let a = 0;
        this.setState(
            {
                validation: true
            },
            () => {
                fileList.map(item => {
                    item = { ...item, ...baseInfo };
                    if (item.sizeUnit) {
                        if (item.sizeUnit === 'KB') {
                            item.size = Number(item.size) * 1000;
                        } else if (item.sizeUnit === 'MB') {
                            item.size = Number(item.size) * 1000 * 1000;
                        } else if (item.sizeUnit === 'GB') {
                            item.size = Number(item.size) * 1000 * 1000 * 1000;
                        }
                        item.size = item.size.toString();
                        DocumentService.edit(item).then(res => {
                            a = a + 1;
                            if (a === fileList.length) {
                                message.success('添加成功');
                                this.props.onSuccess();
                            }
                        });
                    } else {
                        DocumentService.edit(item).then(res => {
                            a = a + 1;
                            if (a === fileList.length) {
                                message.success('添加成功');
                                this.props.onSuccess();
                            }
                        });
                    }
                });
            }
        );
    };

    changeList = key => {
        const { fileList, uidList } = this.state;
        const newList = fileList;
        newList.splice(key, 1);
        uidList.splice(key, 1);
        this.setState({
            fileList: newList,
            uidList
        });
    };

    render() {
        const { allProjectList, groupList, addGroup, updateGroupChange, onDeleteGroup } = this.props;
        const {
            baseInfo: { projectCode, unit, groupId },
            fileList,
            validation,
            uidList
        } = this.state;
        const token = localStorage.getItem('token');
        const a = {
            png: 2,
            jpg: 2,
            jpeg: 2,
            svg: 2,
            dwg: 3,
            dxf: 3,
            a3x: 6,
            xlsx: 7,
            xls: 7,
            doc: 7,
            docx: 7,
            ppt: 7,
            pptx: 7,
            pdf: 7,
            mp4: 8
        };
        const uploadProps = {
            action: `${process.env.REACT_APP_API}/upload`,
            headers: { Authorization: `bearer ${token}` },
            multiple: true,
            data: file => {
                const type = file.name.slice(file.name.indexOf('.', -1) + 1);
                return {
                    type: a[type] ? a[type] : 9
                };
            },
            onChange: file => {
                const newList = fileList;
                const data: any = {};
                if (file.file.status === 'done' || file.file.status === 'uploading') {
                    const type = file.file.name.slice(file.file.name.indexOf('.') + 1);
                    data.name = file.file.name.slice(0, file.file.name.indexOf('.'));
                    data.isPublic = 1;
                    data.fileUrl = file.file.status === 'uploading' ? '' : file.file.response.data;
                    data.type = a[type] ? a[type] : 9;
                    data.remark = '';
                    data.version = '';
                    data.key = 0;
                    data.format = type;
                    data.status = file.file.status;
                    if (!uidList.includes(file.file.uid)) {
                        newList.push(data);
                        uidList.push(file.file.uid);
                    } else {
                        newList[uidList.indexOf(file.file.uid)] = data;
                    }
                }
                this.setState({
                    fileList: newList,
                    uidList
                });
            },
            progress: {
                strokeColor: {
                    '0%': '#1890ff',
                    '100%': '#1890ff'
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`
            }
        };
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onBaseInfoChange('projectCode', selected)}
                />
                <HInput label={'单位'} value={unit} onChange={e => this.onBaseInfoChange('unit', e.target.value)} />
                <HLabelInput label={'分组'} required={true}>
                    <HAddItemSelect
                        dataSource={groupList}
                        add={addGroup}
                        value={groupId}
                        isForm={true}
                        rule={{ required: true, validation, message: '请选择分组' }}
                        onChange={value => this.onBaseInfoChange('groupId', value)}
                        maxWidth={212}
                        minWidth={212}
                        saveChange={updateGroupChange}
                        onDelete={onDeleteGroup}
                    />
                </HLabelInput>
                <div className={'upload-box'}>
                    <div className={'upload-box__head'}>
                        <Upload {...uploadProps}>
                            <Button type={'ghost'}>自动导入</Button>
                        </Upload>
                        <Button type={'ghost'} onClick={this.addManually}>
                            手动导入
                        </Button>
                    </div>
                    <div className={'upload-box__list'}>
                        {fileList.map((item, i) => {
                            return item.key === 0 ? (
                                <UploadText1
                                    key={i}
                                    data={item}
                                    onChange={(key, value) => this.onChange(key, value, i)}
                                    onDelete={() => this.changeList(i)}
                                />
                            ) : (
                                <UploadText2
                                    key={i}
                                    data={item}
                                    onChange={(key, value) => this.onChange(key, value, i)}
                                    onDelete={() => this.changeList(i)}
                                    onChangeSizeUnit={value => {
                                        fileList[i].sizeUnit = value;
                                        this.setState({
                                            fileList
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>确定</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(NewForm);

const UploadText1 = ({ data, onChange, onDelete }) => {
    return (
        <div className={'upload-box__children1'} style={{ marginTop: '10px' }}>
            <div className={'children__head'}>
                <div className={`file-type ${data.format}`}>{data.format}</div>
                <HIcon iconName={'icon-close'} newClass={'clickable'} onClick={onDelete} />
            </div>
            <HInput label={'名称'} value={data.name} onChange={e => onChange('name', e.target.value)} />
            <HInput disabled={true} label={'文件路径'} value={data.fileUrl} />
            <HInput label={'版本号'} value={data.version} onChange={e => onChange('version', e.target.value)} />
            <HInput label={'备注'} value={data.remark} onChange={e => onChange('remark', e.target.value)} />
        </div>
    );
};

const UploadText2 = ({ data, onChange, onDelete, onChangeSizeUnit }) => {
    const [listVisible, setListVisible] = useState(false);
    const [activeData, setActiveData]: any = useState({});
    const [sizeUnit, setSizeUnit] = useState('B');
    const list = [
        {
            index: 2,
            title: '模型',
            class: 'model'
        },
        {
            index: 4,
            title: '地形',
            class: 'terrain'
        },
        {
            index: 8,
            title: '视频',
            class: 'video'
        },
        {
            index: 9,
            title: '其他',
            class: 'other'
        }
    ];
    return (
        <div className={'upload-box__children2'}>
            <div className={'children__head'}>
                <div className={'select-type'}>
                    <div
                        className={'file-type'}
                        onClick={() => {
                            setListVisible(!listVisible);
                        }}
                    >
                        {activeData.index ? <div className={`${activeData.class}`}>{activeData.title}</div> : <div>选择类型</div>}
                        <div></div>
                    </div>
                    {listVisible && (
                        <div className={'select-list'}>
                            {list.map(item => {
                                return (
                                    <div
                                        className={`${item.index === activeData.index && ''}`}
                                        onClick={() => {
                                            onChange('type', item.index);
                                            setActiveData(item);
                                            setListVisible(false);
                                        }}
                                    >
                                        {item.title}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <HIcon iconName={'icon-close'} newClass={'clickable'} onClick={onDelete} />
            </div>
            <HInput label={'名称'} value={data.name} onChange={e => onChange('name', e.target.value)} />
            <HInput label={'文件路径'} value={data.fileUrl} onChange={e => onChange('fileUrl', e.target.value)} />
            <HInput
                label={'文件大小'}
                value={data.size}
                onChange={e => onChange('size', e.target.value)}
                newClass={'aaa'}
                suffix={
                    <Select
                        size={'small'}
                        style={{ width: '50px' }}
                        defaultValue={sizeUnit}
                        onChange={value => {
                            setSizeUnit(value);
                            onChangeSizeUnit(value);
                        }}
                    >
                        <Option value={'B'}>B</Option>
                        <Option value={'KB'}>KB</Option>
                        <Option value={'MB'}>MB</Option>
                        <Option value={'GB'}>GB</Option>
                    </Select>
                }
            />
            <HInput label={'版本号'} value={data.version} onChange={e => onChange('version', e.target.value)} />
            <HInput label={'备注'} value={data.remark} onChange={e => onChange('remark', e.target.value)} />
        </div>
    );
};
