/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { Button, Form, Upload, Select } from 'antd';
import { HInput, HLabelInput, HSelect } from '../../../components';
import { connect } from 'react-redux';
import { actionCreators } from '../../upload/store';
import { SourceType } from '../../../utils/static-data';
import axios from 'axios';


const { Option } = Select;
interface Props {
    onSuccess: () => void;
    getNormalUserList: () => void;
    normalUserList: SourceType[];
    getAllProjectList: () => void;
    allProjectList: SourceType[];
}

interface State {
    formData: any;
    fileList: any[];                        //dxf文件
}


class AddForm extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: '',
                fileUrl: '',
                id: 0,
                isPublic: 1,
                coordinate: 2,
                type: 1,
                unit: '',
                principal: '',
                projectCode: undefined,
                size: 0
            },
            fileList: [],
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
        this.props.getNormalUserList();
    }

    /**
     * @description             内容改变
     * @param key               
     * @param value 
     */

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    /**
     * @description     上传
     */

    save = () => {
        const { onSuccess } = this.props;
        const { fileList, formData } = this.state;
        const file = new FormData();
        file.append('file', fileList[0]);
        axios.post('/cad/save_lp', file).then(res => {
            formData['id'] = res.data;
            axios.put('/cad/edit', formData).then(r => {
                onSuccess();
            });
        });
    };

    render() {
        const { normalUserList, allProjectList } = this.props;
        const { formData: {
            name, projectCode, principal, unit
        }, fileList } = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            accept: '.dxf',
            fileList
        };
        return (
            <div style={{ padding: '8px 16px' }}>
                <HLabelInput label={'缩略图'}>
                    <Upload {...props}>
                        {fileList.length < 1 && <Button>选择文件</Button>}
                    </Upload>
                </HLabelInput>
                <HSelect
                    label={'项目'}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput label={'单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                <HSelect label={'负责人'} value={principal} dataSource={normalUserList} onChange={selected => this.onChange('principal', selected)} />
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <Button onClick={this.save}>上传</Button>
                </div>
            </div>
        );
    }
}

const mapState = state => ({
    normalUserList: state.getIn(['upload', 'normalUserList']),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getNormalUserList() {
        dispatch(actionCreators.getNormalUserList());
    },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(AddForm);