/* eslint-disable react/prop-types */
import React, { Component, useEffect, useState } from 'react';
import { Switch, Button, Input, Select, DatePicker, Popover, Space, Tabs, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { HCollapse, HLabelInput, HIcon, HButton } from '../../../components';
import { CooperateBuilt, Milestone, PlanModel } from '../store/model';
import { HSelect, HInput } from '../../../components';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { SourceType, StateList, CooperateBuiltTypeList } from '../../../utils/static-data';
import HUploadThumbnail from '../../../components/upload';
import axios from 'axios';
import moment from 'moment';
import HAMap, { mapCenter, MapWebServiceKey } from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';
import { PlanService } from '../../../services/plan';

const { TextArea } = Input;
const { TabPane } = Tabs;

interface Props {
    isAdd?: boolean;
    isEdit?: boolean;
    editData?: PlanModel;
    projectList: SourceType[];
    getAllProjectList?: (key?) => void;
    getProjectList?: (key, current) => void;
    onSuccess: () => void;
}

interface States {
    id?: number;
    baseData: {
        logoUrl: string;
        mainTitle: string;
        projectCode: string;
        stage: string;
        subTitle: string;
        waterMark: string;
        id?: number;
    };
    shareData: {
        isEncrypt: number;
        password: string;
        isShare: number;
        sharePeriod: number;
        shareUrl?: string;
        isShow: number;
    };
    overview: string;
    imgList: string[];
    milestoneList: Milestone[];
    cooperateBuiltList: CooperateBuilt[];
    position: string;
    // searchData: any[];
    // searchValue: string;
    validation: boolean;
}

const DefaultData = {
    Milestone: {
        actualDate: undefined,
        name: '',
        planDate: undefined
    },
    CooperateBuilt: {
        name: '', //负责人
        unitName: '', //单位名称
        unitType: 1, //单位类型（1：建设单位，2：设计单位，3：施工单位，4：监理单位，5：运营单位，6：咨询单位,7: 管理单位,8:代建单位）
        unitTypeName: '建设单位' //单位类型名称
    },
    imgList: ['', '', '', '']
};

class HPlanForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            baseData: {
                logoUrl: '',
                mainTitle: '',
                projectCode: undefined,
                stage: undefined,
                subTitle: '',
                waterMark: ''
            },
            shareData: {
                isEncrypt: 0,
                password: '',
                isShare: 0,
                sharePeriod: 0,
                isShow: 0
            },
            overview: '',
            imgList: [...DefaultData.imgList],
            milestoneList: [{ ...DefaultData.Milestone }],
            cooperateBuiltList: [{ ...DefaultData.CooperateBuilt }],
            position: '',
            // searchData: [],
            // searchValue: ""
            validation: false
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (this.props.editData !== prevProps.editData) {
            this.setData();
        }
    }

    setData = () => {
        const { isAdd, editData } = this.props;
        if (!isAdd && editData) {
            const {
                id,
                logoUrl,
                mainTitle,
                projectCode,
                stage,
                subTitle,
                waterMark,
                isEncrypt,
                password,
                isShare,
                sharePeriod,
                isShow,
                overview,
                milestoneList,
                cooperateBuiltList,
                position,
                imgList
            } = this.props.editData;
            this.setState({
                id,
                baseData: {
                    logoUrl,
                    mainTitle,
                    projectCode,
                    stage,
                    subTitle,
                    waterMark
                },
                shareData: {
                    isEncrypt,
                    password,
                    isShare,
                    sharePeriod,
                    isShow
                },
                overview,
                milestoneList: milestoneList.length === 0 ? [{ ...DefaultData.Milestone }] : milestoneList,
                cooperateBuiltList,
                position: position.join(','),
                imgList: imgList || [...DefaultData.imgList]
            });
            this.init(position);
        } else {
            this.init();
        }
    };

    static marker = null;
    init = (position?) => {
        const havePosition = position && position.length > 0;
        HAMap.init({
            id: 'get-plan-center-map',
            center: havePosition ? position : mapCenter
        });
        if (havePosition) {
            HPlanForm.marker = HAMap.addCenterMarker(poiMarker, position);
        }
        HAMap.map.on('click', e => {
            if (HPlanForm.marker) {
                HPlanForm.marker.setMap(null);
                HPlanForm.marker = null;
            }
            HPlanForm.marker = HAMap.addCenterMarker(poiMarker, [e.lnglat.getLng(), e.lnglat.getLat()]);

            this.setState({
                position: e.lnglat.getLng() + ',' + e.lnglat.getLat()
            });
        });
    };

    baseDataChange = (key, value) => {
        const { baseData } = this.state;
        baseData[key] = value;
        this.setState({
            baseData
        });
    };

    textAreaChange = e => {
        this.setState({
            overview: e.target.value
        });
    };

    shareDataChange = (key, value) => {
        const { shareData } = this.state;
        shareData[key] = value;
        this.setState({
            shareData
        });
    };

    onAddMilestone = () => {
        const { milestoneList } = this.state;
        milestoneList.push({ ...DefaultData.Milestone });
        this.setState({
            milestoneList
        });
    };

    onDeleteMilestone = (i, id) => {
        const { milestoneList } = this.state;
        milestoneList.splice(i, 1);
        this.setState({
            milestoneList
        });
        PlanService.delMilestone(id).then(r => {
            message.success(r.data);
        });
    };

    onAddCooperateBuilt = t => {
        const defaultData = {
            name: '',
            unitName: '',
            unitType: t.value,
            unitTypeName: t.text
        };
        const { cooperateBuiltList } = this.state;
        cooperateBuiltList.push({ ...defaultData });
        this.setState({
            cooperateBuiltList
        });
        // console.log("cooperateBuiltList", cooperateBuiltList)
    };

    onDeleteCooperateBuilt = (i, id) => {
        const { cooperateBuiltList } = this.state;
        cooperateBuiltList.splice(i, 1);
        this.setState({
            cooperateBuiltList
        });
        PlanService.delCooperateBuilt(id).then(r => {
            message.success(r.data);
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { isAdd } = this.props;
                const { id, baseData, shareData, overview, imgList, milestoneList, cooperateBuiltList, position } = this.state;
                const { projectCode, stage, mainTitle } = baseData;
                if (projectCode && stage && mainTitle && position) {
                    const newM = milestoneList.filter(it => it.planDate);
                    const newC = cooperateBuiltList.filter(it => it.unitName || it.name);
                    const formData = {
                        ...baseData,
                        ...shareData,
                        overview,
                        imgList,
                        milestoneList: newM,
                        cooperateBuiltList: newC,
                        position
                    };
                    if (!isAdd) {
                        formData.id = id;
                    }
                    PlanService.edit(formData).then(() => {
                        message.success(`${formData.id ? '修改' : '添加'}成功`);
                        this.props.onSuccess();
                    });
                }
            }
        );
    };

    milestoneChange = (data, i) => {
        const { milestoneList } = this.state;
        milestoneList[i] = data;
        this.setState({
            milestoneList
        });
    };

    cooperateBuiltChange = (data, i) => {
        const { cooperateBuiltList } = this.state;
        cooperateBuiltList[i] = data;
        this.setState({
            cooperateBuiltList
        });
    };

    imgListChange = (i, url) => {
        const { imgList } = this.state;
        imgList[i] = url;
        this.setState({
            imgList
        });
    };

    render() {
        const {
            baseData: { logoUrl, mainTitle, projectCode, stage, subTitle, waterMark },
            shareData: { isEncrypt, isShare, password, sharePeriod, isShow },
            overview,
            imgList,
            milestoneList,
            cooperateBuiltList,
            position,
            validation
        } = this.state;
        const { projectList, isAdd } = this.props;
        return (
            <div>
                <HCollapse title={'基本信息'}>
                    <HSelect
                        label={'项目'}
                        rule={{ required: true, validation, message: '请选择项目' }}
                        value={projectCode}
                        dataSource={projectList}
                        onChange={selected => this.baseDataChange('projectCode', selected)}
                    />
                    <HSelect
                        label={'阶段'}
                        rule={{ required: true, validation, message: '请选择阶段' }}
                        value={stage}
                        dataSource={StateList}
                        onChange={selected => this.baseDataChange('stage', selected)}
                    />
                    <HInput
                        label={'主标题'}
                        rule={{ required: true, validation, message: '请输入主标题' }}
                        value={mainTitle}
                        onChange={e => this.baseDataChange('mainTitle', e.target.value)}
                    />
                    <HInput label={'副标题'} value={subTitle} onChange={e => this.baseDataChange('subTitle', e.target.value)} />
                    <HInput label={'水印'} value={waterMark} onChange={e => this.baseDataChange('waterMark', e.target.value)} />
                    <HLabelInput label={'logo'}>
                        <HUploadThumbnail type={2} defaultUrl={logoUrl} onChange={url => this.baseDataChange('logoUrl', url)} />
                    </HLabelInput>
                </HCollapse>
                <HCollapse title={'分享设置'}>
                    <HLabelInput label={'分享'}>
                        <Switch
                            style={{ width: 30 }}
                            checked={Boolean(isShare)}
                            size={'small'}
                            onChange={checked => this.shareDataChange('isShare', Number(checked))}
                        />
                        <Button
                            style={{ width: 46 }}
                            shape="round"
                            type={sharePeriod === 0 ? 'primary' : 'default'}
                            size={'small'}
                            onClick={() => this.shareDataChange('sharePeriod', 0)}
                        >
                            永久
                        </Button>
                        <Button
                            style={{ width: 46 }}
                            type={sharePeriod === 1 ? 'primary' : 'default'}
                            shape="round"
                            size={'small'}
                            onClick={() => this.shareDataChange('sharePeriod', 1)}
                        >
                            1日
                        </Button>
                        <Button
                            style={{ width: 46 }}
                            type={sharePeriod === 7 ? 'primary' : 'default'}
                            shape="round"
                            size={'small'}
                            onClick={() => this.shareDataChange('sharePeriod', 7)}
                        >
                            7日
                        </Button>
                    </HLabelInput>
                    <HLabelInput label={'加密'}>
                        <Switch
                            style={{ width: 30 }}
                            checked={Boolean(isEncrypt)}
                            size={'small'}
                            onChange={checked => this.shareDataChange('isEncrypt', Number(checked))}
                        />
                        <Input
                            size={'small'}
                            placeholder={'设定密码'}
                            value={password}
                            style={{ width: 160 }}
                            onChange={e => this.shareDataChange('password', e.target.value)}
                        />
                    </HLabelInput>
                    <HLabelInput label={'填报展示'}>
                        <div style={{ width: "198px" }}>
                            <Switch
                                style={{ width: 30 }}
                                checked={Boolean(isShow)}
                                size={'small'}
                                onChange={checked => this.shareDataChange('isShow', Number(checked))}
                            />
                        </div>
                    </HLabelInput>
                </HCollapse>
                <HCollapse title={'项目位置'}>
                    <HInput label={'坐标信息'} rule={{ required: true, validation, message: '请选择位置信息' }} value={position} readOnly={true} />
                    <div className={'h-plan-map'} id={'get-plan-center-map'} />
                </HCollapse>

                <HCollapse title={'项目概况'}>
                    <TextArea allowClear value={overview} onChange={this.textAreaChange} />
                    <div style={{ marginTop: 8, display: 'flex' }}>
                        {imgList.map((it, i) => {
                            return (
                                <HUploadThumbnail
                                    key={i}
                                    type={2}
                                    defaultUrl={it}
                                    className={'h-upload-small'}
                                    uploadButton={<HIcon iconName={'icon-add-photo'} />}
                                    onChange={url => this.imgListChange(i, url)}
                                />
                            );
                        })}
                    </div>
                </HCollapse>
                <HCollapse title={'里程碑'}>
                    {milestoneList.map((it, i) => {
                        return (
                            <MilestoneItem
                                key={i}
                                data={it}
                                isLast={i === milestoneList.length - 1}
                                onAdd={this.onAddMilestone}
                                onChange={data => this.milestoneChange(data, i)}
                                onDelete={() => this.onDeleteMilestone(i, it.id)}
                            />
                        );
                    })}
                </HCollapse>
                <HCollapse title={'参建方'}>
                    <Tabs tabPosition={'top'}>
                        {CooperateBuiltTypeList.map(t => (
                            <TabPane tab={t.text} key={t.value}>
                                {cooperateBuiltList.map((it, i) => {
                                    return (
                                        t.value === it.unitType && (
                                            <CooperateBuiltItem
                                                key={i}
                                                typeName={t.text}
                                                data={it}
                                                onChange={data => this.cooperateBuiltChange(data, i)}
                                                onDelete={() => this.onDeleteCooperateBuilt(i, it.id)}
                                            />
                                        )
                                    );
                                })}
                                <div className={'h-text-center'} style={{ margin: '14px 0' }}>
                                    <PlusOutlined
                                        style={{
                                            fontSize: 18,
                                            color: 'rgba(0, 0, 0, 0.45)'
                                        }}
                                        onClick={() => this.onAddCooperateBuilt(t)}
                                    />
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </HCollapse>
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </div>
        );
    }
}

const mapState = state => ({
    projectList: state.getIn(['project', 'getAllProject'])
});

const mapDispatch = dispatch => ({
    getAllProjectList(key) {
        dispatch(actionCreators.getAllProject(key));
    },
    getProjectList(keywords, current) {
        dispatch(actionCreators.getChild1Data(keywords, current));
    }
});
export default connect(mapState, mapDispatch)(HPlanForm);

const MilestoneItem = ({ data, isLast, onAdd, onDelete, onChange }) => {
    const [newData, setData] = useState({ ...DefaultData.Milestone });
    useEffect(() => {
        setData(data);
    }, [data]);

    function onInputChange(value) {
        setData(old => {
            old.name = value;
            onChange({ ...old });
            return { ...old };
        });
    }

    function onPlanDateChange(dateString) {
        setData(old => {
            old.planDate = dateString;
            onChange({ ...old });
            return { ...old };
        });
    }

    function onActualDateChange(dateString) {
        setData(old => {
            old.actualDate = dateString;
            onChange({ ...old });
            return { ...old };
        });
    }

    return (
        <div style={{ marginBottom: 8 }}>
            <Space style={{ marginBottom: 8 }}>
                <span style={{ color: 'rgba(0,0,0,0.85)', fontWeight: 300, marginRight: 2 }}>标题</span>
                <div>
                    <Input
                        size={'small'}
                        value={newData.name}
                        style={{ width: isLast ? 176 : 204, marginRight: 6 }}
                        onChange={e => onInputChange(e.target.value)}
                    />
                    {
                        <div style={{ display: 'inline-block' }}>
                            {isLast && <HIcon iconName={'icon-plus-square'} onClick={onAdd} />}
                            <HIcon iconName={'icon-minus-square'} onClick={onDelete} />
                        </div>
                    }
                </div>
            </Space>
            <div className={'h-flex-between'}>
                <div style={{ color: 'rgba(0,0,0,0.55)', width: '50%' }}>
                    <span style={{ fontWeight: 300, marginRight: 10 }}>计划</span>
                    <span>
                        <HIcon iconName={'icon-time-circle'} style={{ fontSize: 11 }} />
                    </span>
                    <DatePicker
                        style={{ width: 80, padding: 2 }}
                        bordered={false}
                        value={newData.planDate ? moment(newData.planDate, 'YYYY-MM-DD') : newData.planDate}
                        placeholder={'选择日期'}
                        suffixIcon={''}
                        onChange={(_, dateString) => onPlanDateChange(dateString)}
                    />
                </div>
                <div style={{ color: 'rgba(0,0,0,0.55)', width: '50%' }}>
                    <span style={{ fontWeight: 300, marginRight: 10 }}>实际</span>
                    <span>
                        <HIcon
                            iconName={'icon-time-circle'}
                            style={{
                                fontSize: 11,
                                color: newData.actualDate ? 'rgba(53, 198, 79, 0.65)' : 'unset'
                            }}
                        />
                        <DatePicker
                            style={{ width: 80, padding: 2 }}
                            bordered={false}
                            value={newData.actualDate ? moment(newData.actualDate, 'YYYY-MM-DD') : newData.actualDate}
                            placeholder={'选择日期'}
                            suffixIcon={''}
                            onChange={(_, dateString) => onActualDateChange(dateString)}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

const CooperateBuiltItem = ({ typeName, data, onDelete, onChange }) => {
    const [newData, setData] = useState({ ...DefaultData.CooperateBuilt });
    useEffect(() => {
        setData(data);
    }, [data]);

    function onUnitChange(value) {
        setData(old => {
            old.unitName = value;
            onChange({ ...old });
            return { ...old };
        });
    }

    function onNameChange(value) {
        setData(old => {
            old.name = value;
            onChange({ ...old });
            return { ...old };
        });
    }

    return (
        <div>
            <div
                className={'h-flex-between'}
                style={{
                    marginTop: 8,
                    borderBottom: '1px dashed rgba(151, 151, 151, 0.2)'
                }}
            >
                <div style={{ width: 245 }}>
                    <HLabelInput label={typeName}>
                        <Input value={newData.unitName} size="small" onChange={e => onUnitChange(e.target.value)} />
                    </HLabelInput>
                    <HLabelInput label={'负责人'}>
                        <Input value={newData.name} size="small" onChange={e => onNameChange(e.target.value)} />
                    </HLabelInput>
                </div>
                <HIcon iconName={'icon-minus-square'} onClick={onDelete} />
            </div>
        </div>
    );
};
