/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDescriptions, HIcon, HInfoLine, HSingleBox, TableLine } from '../../../components';
import { InspectionTypeList } from '../../../utils/static-data';
import InfiniteScroll from 'react-infinite-scroller';
import './index.scss';
import { InspectionService } from '../../../services/inspection';
import { Image, Modal } from 'antd';

interface Props { }

interface State {
    dataType: number;
    dataSource: any[];
    activeData: any;
    resourcesUrl: string;
    visible: boolean;
}

class index extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            dataType: 0,
            dataSource: [],
            activeData: null,
            resourcesUrl: "",
            visible: false
        };
    }

    componentDidMount(): void {
        this.getList(0);
    };

    /**
     * @description                 获取列表
     * @param value                 数据类型(0:全部,1:正常,2:异常)
     */

    getList = (value) => {
        const data = value === 0 ? {} : { type: value - 1 };
        InspectionService.getList(data).then(res => {
            this.setState({
                dataSource: res.data
            });
        });
    }

    /**
     * @description                 删除
     * @param id                    要删除内容的id
     */

    lineDelete = (id) => {
        const { dataType } = this.state;
        InspectionService.onDelete(id).then(() => {
            this.getList(dataType);
        });
    }

    /**
     * @description                 类型切换
     * @param value                 类型
     */

    onPublicChange = (value) => {
        this.setState({
            dataType: value,
            activeData: null,
        });
        this.getList(value);
    }

    /**
     * @description             视频/图片信息点击事件
     * @param data              选中的内容
     */

    resourcesClick = (data) => {
        this.setState({
            resourcesUrl: data,
            visible: true
        });
    }

    render() {
        const { dataType, dataSource, activeData, resourcesUrl, visible } = this.state;

        return (
            <>
                <HContentLeft>
                    <div style={{ marginBottom: "8px" }}>
                        <HSingleBox data={InspectionTypeList} value={dataType} onChange={this.onPublicChange} width={219} />
                    </div>
                    <InfiniteScroll initialLoad={false} pageStart={1} >
                        {
                            dataSource.map((r) => {
                                return <TableLine
                                    key={r.id}
                                    data={r}
                                    imgUrl={r.picUrl}
                                    activeTable={activeData ? activeData.id : 0}
                                    showDate={true}
                                    onDelete={() => this.lineDelete(r.id)}
                                    onClick={() => this.setState({ activeData: r })}
                                    isInspection={true}
                                />;
                            })
                        }
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {
                        activeData && <div>
                            <HDescriptions title={activeData['projectName']}>
                                <HInfoLine title={'项目编号'} value={activeData['projectCode']} />
                                <HInfoLine title={'项目名称'} value={activeData['projectName']} />
                                <HInfoLine title={'创建时间'} value={activeData['createDate']} />
                                <HInfoLine title={'经纬度信息'} value={`${activeData['lon']},${activeData['lat']}`} />
                                <HInfoLine title={'上传人'} value={activeData['userName']} />
                                <HInfoLine title={'巡检状态'} value={<span style={{ color: `${Boolean(activeData['type']) ? "#FF4545" : "#35C64F"}` }}>{Boolean(activeData['type']) ? "异常" : "常规"}</span>} />
                            </HDescriptions>
                            <HDescriptions title={'视频/图片信息'}>
                                <div className={'resources__list'}>
                                    {
                                        JSON.parse(activeData['urlList']).length > 0 && JSON.parse(activeData['urlList']).map((item, i) => {
                                            const type = item.substring(item.length - 4);
                                            if (type === ".mp4") {
                                                return <div key={i} onClick={() => this.resourcesClick(item)} >
                                                    <div className='play'>
                                                        <HIcon iconName='icon-bofang' />
                                                    </div>
                                                </div>;
                                            }
                                            else {
                                                return <div key={i}><Image width="100%" height='100%' src={item} /></div>;
                                            }
                                        })
                                    }
                                </div>
                            </HDescriptions>
                            <HDescriptions title={'填报信息'}>
                                <div className={'text__box'}>{activeData['infomation']} </div>
                            </HDescriptions>
                        </div>
                    }
                </HContentRight>
                {
                    visible && <Modal
                        width={800}
                        visible={true}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={() => {
                            this.setState({
                                visible: false,
                                resourcesUrl: ""
                            });
                        }}
                    >
                        <div style={{ paddingTop: "20px" }}>
                            <video width='100%' height='600px' controls autoPlay >
                                <source src={resourcesUrl} type='video/mp4' />
                            </video>
                        </div>
                    </Modal>
                }
            </>
        );
    }
}

export default index;