import * as constants from './constants';
import axios from 'axios';
import { UserService } from '../../../services/user';

/**
 * @description 通用
 */
const setData = value => ({
    type: constants.SET_DATA,
    value
});

const setCurrent = value => ({
    type: constants.SET_CURRENT,
    value
});

const setCount = value => ({
    type: constants.SET_COUNT,
    value
});

const setNormalUserList = value => ({
    type: constants.SET_NORMAL_USER_LIST,
    value
});

const setLeaderList = value => ({
    type: constants.SET_LEADER_LIST,
    value
});

/**
 * @description 删除改变数组
 */

export const changeData = data => {
    return dispatch => {
        dispatch(setData(data));
    };
};

/**
 *
 * @param url
 * @param value
 * @param current
 * @param data
 * @description 请求带分页的列表
 */

export const getPageList = (url, value, current, data) => {
    return dispatch => {
        axios
            .get(url, {
                params: {
                    key: value,
                    page: current,
                    size: 25
                }
            })
            .then(res => {
                if (res.data.list) {
                    const newData = [...data, ...res.data.list];
                    const newCount = Math.ceil(res.data.total / 25);
                    dispatch(setData(newData));
                    dispatch(setCurrent(current));
                    dispatch(setCount(newCount));
                }
            });
    };
};

/**
 * @description 项目设置
 */

/**
 *
 * @param value
 * @param current
 * @description 获取项目设置列表
 */

export const getProjectList = (value, current, data = []) => {
    return dispatch => {
        dispatch(getPageList('/project/query', value, current, data));
    };
};

/**
 * @description 用户列表
 */

export const getUserList = (value, current, data = []) => {
    return dispatch => {
        dispatch(getPageList('/user/query', value, current, data));
    };
};

/**
 * @description 获取一般用户列表
 */
export const getNormalList = () => {
    return dispatch => {
        UserService.getListByType(1).then(res => {
            const data = res.data.map(it => {
                return { value: it.id, text: it.name };
            });
            dispatch(setNormalUserList(data));
        });
    };
};

/**
 * @description 获取领导用户列表
 */
export const getLeaderList = () => {
    return dispatch => {
        UserService.getListByType(2).then(res => {
            const data = res.data.map(it => {
                return { value: it.id, text: it.name };
            });
            dispatch(setLeaderList(data));
        });
    };
};

/**
 * @description 系统设置
 */
