import axios from 'axios';

export default class LogService {
    static getLoginLog(data) {
        return axios.get('/login_log/query', { params: data }).then(res => res.data);
    }

    static getLogDetail(data) {
        return axios.get('/log_detail/query', { params: data }).then(res => res.data);
    }

    static getBehaviorList() {
        return axios.get('/log_detail/query_behavior').then(res => res.data);
    }

    static getSysLocation() {
        return axios.get('/log_detail/query_sys_location').then(res => res.data);
    }

    static getLoginDoc(data) {
        return axios.get('/doc_share_login/query', { params: data }).then(res => res.data);
    }
}
