import axios from 'axios';

export class MonitorService {
    /**
     * @description 获取数据列表
     */

    static getDataList(code) {
        return axios
            .get('/device_latest/query_all', {
                params: {
                    code
                }
            })
            .then(res => res.data);
    }

    /**
     * @description  添加展示数据
     */

    static addDisplayData = data => {
        return axios.post('/device_latest/display', data);
    };

    /**
     * @description 添加监测站
     */

    static addMonitor = data => {
        return axios.put('/monitor_station/edit', data);
    };

    /**
     * @description 删除泵站
     */

    static delPumpSation = id => {
        return axios.delete('/monitor_station/delete/' + id);
    };

    /**
     * @description 获取左侧列表
     */

    static getStationsList = data => {
        return axios.get('/monitor_station/query', { params: data });
    };

    /**
     * @description 获取cad对应图层列表
     */

    static handleGetLayerList = id => {
        return axios.get('/cad/get_layer_name/' + id);
    };

    /**
     * @description 获取某一图层下线段信息
     */

    static handleGetLineList = (cadId, layerName) => {
        return axios.get('/cad/list_cad_data', { params: { type: 1, cadId: cadId, layerName: layerName } });
    };

    /**
     * @description 获取cad文件信息
     */

    static handleGetCadInfo = id => {
        return axios.get('/cad/query_by_id/' + id);
    };

    /**
     * @description 获取管线信息
     */
    static handleGetLineInfo = id => {
        return axios.get('/cad/get_cad_date_by_id/' + id);
    };

    /**
     * @description 修改管线信息
     */
    static handleUpdateLineInfo = data => {
        return axios.put('/cad/editCadData', data);
    };

    /**
     * @description 获取管井信息
     */

    static handleGetPointList = (cadId, layerName) => {
        return axios.get('/cad/list_cad_data', { params: { type: 3, cadId: cadId, layerName: layerName } });
    };

    /**
     *
     * @description 修改管井信息
     */
    static handleUpdatePointInfo = data => {
        return axios.put('/cad/editCadData', data);
    };

    static handleDelLayer = (cadId, layerName) => {
        return axios.delete('/cad/delete_layer', { params: { cadId, layerName } });
    };
}
