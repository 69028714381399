import React, { Component } from 'react';
import { HMenu, HIcon, HSearch, HContentBox } from '../../components';
import Chart1 from './plan/child1';
import './index.scss';
import { HAdd } from '../../components/simple';
import { actionCreators } from './store';
import { connect } from 'react-redux';
import Child2 from './plan/child2';
import Child3 from './plan/child3';

const menuList = [
    {
        title: '我的项目'
    },
    {
        title: '进度填报'
    },
    {
        title: '投资填报'
    },
    {
        title: '我的监测'
    },
    {
        title: '我的关注'
    }
    // {
    //     title: "我的项目1"
    // },
    // {
    //     title: "我的填报2"
    // },
    // {
    //     title: "我的监测3"
    // },
    // {
    //     title: "我的关注4"
    // }, {
    //     title: "我的项目5"
    // },
    // {
    //     title: "我的填报6"
    // },
    // {
    //     title: "我的监测7"
    // },
    // {
    //     title: "我的关注8"
    // }, {
    //     title: "我的监测9"
    // },
    // {
    //     title: "我的关注00"
    // },
];

interface Props {
    changeAddVisible: (visible: boolean) => void;
    addVisible: boolean;
}

interface State {
    menuActive: number;
    keyword: string;
}

class Project extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            menuActive: 0,
            keyword: ''
        };
    }

    componentWillUnmount(): void {
        this.props.changeAddVisible(false);
    }

    renderContent = value => {
        const { keyword } = this.state;
        switch (value) {
            case 0:
                return <Chart1 keyword={keyword} />;
            case 1:
                return <Child2 keyword={keyword} />;
            case 2:
                return <Child3 keyword={keyword} />;
            case 3:
                return <div>我的监测</div>;
            case 4:
                return <div>我的关注</div>;
        }
    };

    onSearch = e => {
        this.setState({
            keyword: e.target.value
        });
    };

    menuChange = key => {
        this.setState({
            menuActive: key,
            keyword: ''
        });
        this.props.changeAddVisible(false);
    };

    onAddChange = () => {
        const { addVisible, changeAddVisible } = this.props;
        changeAddVisible(!addVisible);
    };

    render() {
        const { menuActive } = this.state;
        const { addVisible } = this.props;
        return (
            <div>
                <HMenu
                    data={menuList}
                    activeKey={menuActive}
                    onClick={this.menuChange}
                    suffix={
                        <>
                            <HSearch isUpdate={menuActive} onChange={this.onSearch} />
                            {menuActive === 0 && <HAdd fill={addVisible} onClick={this.onAddChange} />}
                        </>
                    }
                />
                <HContentBox>{this.renderContent(menuActive)}</HContentBox>
            </div>
        );
    }
}

const mapState = state => ({
    addVisible: state.getIn(['project', 'addVisible'])
});

const mapDispatch = dispatch => ({
    changeAddVisible(visible) {
        dispatch(actionCreators.changeAddVisible(visible));
    }
});

export default connect(mapState, mapDispatch)(Project);
