/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { DatePicker, Table, Select, Input, Button } from 'antd';
import LogService from '../../services/log';
import ProjectService from '../../services/project';
import { ProjectModel } from '../setup/project/model';
import { UserService } from '../../services/user';
import { UserModel } from '../setup/user/model';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const { Option } = Select;
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    },
    { title: '时间', dataIndex: 'createDate', key: 'createDate' },
    {
        title: '用户名',
        dataIndex: 'account',
        key: 'account',
        render: (text, record) => <span>{record.name + `（${text}）`}</span>
    },
    { title: '系统位置', dataIndex: 'sysLocation', key: 'location' },
    { title: '详细位置', dataIndex: 'detailLocation', key: 'detailLocation' },
    { title: '行为', dataIndex: 'behavior', key: 'behavior' },
    { title: '行为内容', dataIndex: 'behaviorContent', key: 'content' },
    { title: '技术指标', dataIndex: 'technicalIndex', key: 'index' },
    { title: '项目', dataIndex: 'project', key: 'project' },
    { title: 'IP', dataIndex: 'ip', key: 'ip' }
];

interface Props {}

interface States {
    page: number;
    size: number;
    key: string;
    dataSource: any[];
    total: number;
    beginTime: any;
    endTime: any;
    account: string;
    sysLocation: string;
    behavior: string;
    project: string;
    locationList: string[];
    behaviorList: string[];
    projectList: ProjectModel[];
    userList: UserModel[];
}

class LogDetail extends Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            page: 1,
            size: 12,
            key: '',
            dataSource: [],
            total: 0,
            beginTime: '',
            endTime: '',
            account: '',
            sysLocation: undefined,
            behavior: undefined,
            project: undefined,
            locationList: [],
            behaviorList: [],
            projectList: [],
            userList: []
        };
    }

    componentDidMount(): void {
        const { page, size } = this.state;
        this.getList({ page, size });
        this.getUserList();
        this.getProjectList();
        this.getLocationList();
        this.getBehaviorList();
    }

    getProjectList = () => {
        ProjectService.getAll().then(projectList => {
            this.setState({
                projectList
            });
        });
    };

    getUserList = () => {
        UserService.getAllData().then(userList => {
            this.setState({
                userList
            });
        });
    };
    getLocationList = () => {
        LogService.getSysLocation().then(locationList => {
            this.setState({
                locationList
            });
        });
    };

    getBehaviorList = () => {
        LogService.getBehaviorList().then(behaviorList => {
            this.setState({
                behaviorList
            });
        });
    };

    getList = param => {
        const { page, size } = param;
        LogService.getLogDetail(param).then(data => {
            if (data) {
                this.setState({
                    dataSource: data.list,
                    total: data.total,
                    page,
                    size
                });
            }
        });
    };

    dateChange = (dates, dateString) => {
        this.setState(
            {
                beginTime: dateString[0] || '',
                endTime: dateString[1] || ''
            },
            () => {
                this.onSearch();
            }
        );
    };

    userChange = value => {
        this.setState(
            {
                account: value
            },
            () => {
                this.onSearch();
            }
        );
    };

    locationChange = value => {
        this.setState(
            {
                sysLocation: value
            },
            () => {
                this.onSearch();
            }
        );
    };

    behaviorChange = value => {
        this.setState(
            {
                behavior: value
            },
            () => {
                this.onSearch();
            }
        );
    };

    projectChange = value => {
        this.setState(
            {
                project: value
            },
            () => {
                this.onSearch();
            }
        );
    };

    keyChange = e => {
        const key = e.target.value;
        this.setState(
            {
                key
            },
            () => {
                if (!key) {
                    this.onSearch();
                }
            }
        );
    };

    onSearch = () => {
        const { beginTime, endTime, size, key, behavior, account, sysLocation, project } = this.state;
        this.getList({
            beginTime,
            endTime,
            behavior,
            account,
            sysLocation,
            project,
            page: 1,
            size,
            key
        });
    };

    onPageChange = (page, pageSize) => {
        this.setState(
            {
                page,
                size: pageSize
            },
            () => {
                const { beginTime, endTime, behavior, account, sysLocation, project, size, key } = this.state;
                this.getList({
                    beginTime,
                    endTime,
                    behavior,
                    account,
                    sysLocation,
                    project,
                    page,
                    size,
                    key
                });
            }
        );
    };

    render() {
        const { dataSource, page, size, total, key, behaviorList, locationList, projectList, userList } = this.state;
        const paginationProps = {
            current: page,
            pageSize: size,
            total,
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: this.onPageChange,
            size: 'small' as 'default' | 'small'
        };
        return (
            <div className={'bg'}>
                <div className="search-bar">
                    <RangePicker size="small" format={dateFormat} onChange={this.dateChange} />
                    <RenderSelect
                        dataSource={userList}
                        placeholder={'用户'}
                        onChange={this.userChange}
                        minWidth={76}
                        nameField={'name'}
                        valueField={'account'}
                    />
                    <RenderSelect dataSource={locationList} placeholder={'位置'} onChange={this.locationChange} minWidth={76} />
                    <RenderSelect
                        dataSource={behaviorList}
                        placeholder={'行为'}
                        onChange={this.behaviorChange}
                        minWidth={76}
                        nameField={'name'}
                        valueField={'id'}
                    />
                    <RenderSelect
                        dataSource={projectList}
                        placeholder={'项目'}
                        onChange={this.projectChange}
                        minWidth={76}
                        nameField={'projectName'}
                        valueField={'projectCode'}
                    />

                    <Input
                        size="small"
                        style={{ width: 146, height: 24, paddingLeft: 12 }}
                        allowClear
                        placeholder="关键字"
                        value={key}
                        onChange={this.keyChange}
                        onPressEnter={this.onSearch}
                    />
                    <Button type="primary" size="small" style={{ width: 56, height: 24 }} onClick={this.onSearch}>
                        查询
                    </Button>
                </div>
                <Table
                    className={'table'}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={record => record.id}
                    pagination={paginationProps}
                />
            </div>
        );
    }
}

export default LogDetail;

interface RenderSelectProps {
    dataSource: any[];
    minWidth: number;
    onChange: (value) => void;
    placeholder: string;
    valueField?: string;
    nameField?: string;
}

const RenderSelect = ({ dataSource, minWidth, onChange, placeholder, valueField, nameField }: RenderSelectProps) => {
    return (
        <Select size="small" style={{ minWidth }} allowClear placeholder={placeholder} onChange={onChange}>
            {dataSource.map((it, i) => {
                return (
                    <Option key={i} value={it[valueField] || it}>
                        {it[nameField] || it}
                    </Option>
                );
            })}
        </Select>
    );
};
