/* eslint-disable react/prop-types */
import React, { Component, useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Switch, message } from 'antd';
import { SmileTwoTone, MehOutlined } from '@ant-design/icons';
import { HInfoLine, HIcon, HContentCollapse, HContentLeft } from '../../../components';
import { SysModel } from './model';
import { HUploadFile } from '../../../components/upload';
import { UploadDataType } from '../../../utils/static-data';
import axios from 'axios';
import SystemService from '../../../services/system';

interface Props {
    data: SysModel;
}

interface States {
    sysData: SysModel;
    play: boolean;
    unLock: boolean;
}

const LinkObj = {
    isOpen: 0,
    name: '未命名',
    url: ''
};

class HSysBase extends Component<Props, States> {
    audioRef: any = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            sysData: {
                companyName: '公司名称',
                logoUrl: 'http://dinnovate-dredge.oss-cn-hangzhou.aliyuncs.com/picture/3161614922857658-1623768096593.png',
                backgroundUrl: 'http://dinnovate-dredge.oss-cn-hangzhou.aliyuncs.com/picture/placemark_monitor-1623768103692.png',
                musicUrl: 'http://dinnovate-dredge.oss-cn-hangzhou.aliyuncs.com/music/13978-1623766154999.mp3',
                netSecurityInfo: '',
                recordInfo: '',
                systemName: '',
                version: '',
                linkList: []
            },

            play: false,
            unLock: false
        };
    }

    componentDidMount(): void {
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {
            this.setData();
        }
    }

    setData = () => {
        const { data } = this.props;
        if (data) {
            this.setState({
                sysData: data
            });
        }
    };

    baseLockClick = e => {
        e.stopPropagation();
        this.setState({ unLock: !this.state.unLock }, () => {
            if (!this.state.unLock) {
                const { sysData } = this.state;
                if (sysData.linkList.length === 0) {
                    sysData.linkList.push(LinkObj);
                    this.setState({
                        sysData
                    });
                }
            }
        });
    };

    valueChange = (key, value) => {
        const { sysData } = this.state;
        sysData[key] = value;
        this.setState(
            {
                sysData
            },
            () => {
                if (key === 'musicUrl' || key === 'logoUrl' || key === 'backgroundUrl') {
                    this.save();
                }
            }
        );
    };

    addLink = () => {
        const { sysData } = this.state;
        sysData.linkList.push({ ...LinkObj });
        this.setState({
            sysData
        });
    };

    delLink = (data, index) => {
        const { sysData } = this.state;
        if (sysData.linkList.length === 1 && index === 0) {
            sysData.linkList.splice(index, 1, LinkObj);
        } else {
            sysData.linkList.splice(index, 1);
        }
        this.setState(
            {
                sysData
            },
            () => {
                if (data.id) {
                    SystemService.delLink(data.id).then(r => {
                        message.success(r.data);
                    });
                }
            }
        );
    };

    linkChange = (data, i) => {
        const { sysData } = this.state;
        sysData.linkList[i] = data;
        this.setState(
            {
                sysData
            },
            () => {
                this.save();
            }
        );
    };

    save = () => {
        const { sysData } = this.state;
        SystemService.edit({ ...sysData }).then(r => {
            message.success(r.data);
            this.setState({
                unLock: false
            });
        });
    };

    playMusic = () => {
        const { play } = this.state;
        this.setState({
            play: !play
        });
        if (play) {
            this.audioRef.pause();
        } else {
            this.audioRef.play();
        }
    };

    playEnd = () => {
        this.setState({
            play: false
        });
    };

    render() {
        const {
            sysData: { backgroundUrl, companyName, logoUrl, musicUrl, netSecurityInfo, recordInfo, systemName, version, linkList },
            play,
            unLock
        } = this.state;
        return (
            <HContentCollapse
                title={'站点信息'}
                style={{
                    height: '348px'
                }}
                suffix={<HIcon iconName={unLock ? 'icon-unlock' : 'icon-lock'} onClick={this.baseLockClick} />}
            >
                <Row gutter={48}>
                    <Col span={12}>
                        <LeftItem
                            name="系统名称"
                            value={systemName}
                            unLock={unLock}
                            onValueChange={e => this.valueChange('systemName', e.target.value)}
                            onSave={this.save}
                        />
                        <LeftItem
                            name="企业名称"
                            value={companyName}
                            unLock={unLock}
                            onValueChange={e => this.valueChange('companyName', e.target.value)}
                            onSave={this.save}
                        />
                        <LeftItem
                            name="备案信息"
                            value={recordInfo}
                            unLock={unLock}
                            onValueChange={e => this.valueChange('recordInfo', e.target.value)}
                            onSave={this.save}
                        />
                        <LeftItem
                            name="网安信息"
                            value={netSecurityInfo}
                            unLock={unLock}
                            onValueChange={e => this.valueChange('netSecurityInfo', e.target.value)}
                            onSave={this.save}
                        />
                        <LeftItem
                            name="平台版本"
                            value={version}
                            unLock={unLock}
                            onValueChange={e => this.valueChange('version', e.target.value)}
                            onSave={this.save}
                        />
                        <HInfoLine
                            title="音乐"
                            value={musicUrl}
                            edit={true}
                            suffixBgColor={'rgba(245, 245, 245, 1)'}
                            suffixWidth={unLock ? 46 : 26}
                            suffix={
                                <>
                                    {unLock ? (
                                        <HUploadFile fileType={UploadDataType.music} onChange={url => this.valueChange('musicUrl', url)} />
                                    ) : null}
                                    <HIcon
                                        iconName={play ? 'icon-timeout' : 'icon-play-circle'}
                                        onClick={this.playMusic}
                                        style={{
                                            marginLeft: 5,
                                            color: musicUrl ? 'rgba(118, 174, 255, 1)' : 'unset'
                                        }}
                                    />
                                    <audio style={{ display: 'none' }} ref={ref => (this.audioRef = ref)} onEnded={this.playEnd}>
                                        <source src={musicUrl} />
                                    </audio>
                                </>
                            }
                        />
                        <Row gutter={16}>
                            <Col span={12}>
                                <HInfoLine
                                    title="logo"
                                    value={logoUrl}
                                    image={true}
                                    edit={true}
                                    suffixBgColor={'rgba(245, 245, 245, 1)'}
                                    suffixWidth={unLock ? 30 : 0}
                                    suffix={
                                        unLock ? <HUploadFile fileType={UploadDataType.pic} onChange={url => this.valueChange('logoUrl', url)} /> : ''
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <HInfoLine
                                    title="背景"
                                    value={backgroundUrl}
                                    edit={true}
                                    image={true}
                                    suffixBgColor={'rgba(245, 245, 245, 1)'}
                                    suffixWidth={unLock ? 30 : 0}
                                    suffix={
                                        unLock ? (
                                            <HUploadFile fileType={UploadDataType.pic} onChange={url => this.valueChange('backgroundUrl', url)} />
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        {linkList.map((it, i) => {
                            return (
                                <LinkItem
                                    unLock={unLock}
                                    data={it}
                                    key={i}
                                    isLast={linkList.length == i + 1}
                                    onAdd={this.addLink}
                                    onDelete={() => this.delLink(it, i)}
                                    onChange={data => this.linkChange(data, i)}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </HContentCollapse>
        );
    }
}

export default HSysBase;

const LeftItem = ({ name, value, unLock, onValueChange, onSave }) => {
    const [isEdit, setEdit] = useState(false);
    const [showIcon, setIconVisible] = useState(false);
    const inputRef = useRef<any>(null);
    useEffect(() => {
        setIconVisible(unLock);
    }, [unLock]);

    function onEditClick() {
        setEdit(!isEdit);
        inputRef.current!.focus({
            cursor: 'end'
        });
    }

    function onPressEnter() {
        setEdit(false);
        onSave();
    }

    return (
        <HInfoLine
            title={name}
            value={
                <Input
                    size={'small'}
                    ref={inputRef}
                    readOnly={!isEdit}
                    bordered={isEdit}
                    value={value}
                    onChange={onValueChange}
                    onPressEnter={onPressEnter}
                    onBlur={() => setEdit(false)}
                />
            }
            suffixWidth={showIcon ? 30 : 0}
            suffixBgColor={'rgba(245, 245, 245, 1)'}
            edit={true}
            suffix={showIcon ? <HIcon iconName={'icon-edit'} onClick={onEditClick} /> : ''}
        />
    );
};

export const LinkItem = ({ unLock, data, isLast, onAdd, onDelete, onChange }) => {
    const [isEdit, setEdit] = useState(false);
    const [showIcon, setIconVisible] = useState(false);
    const [newData, setData] = useState({ isOpen: 0, name: '', url: '' });
    const inputRef1 = useRef<any>(null);
    const inputRef2 = useRef<any>(null);
    useEffect(() => {
        setIconVisible(unLock);
        setData(data);
    }, [unLock, data]);

    function onEditClick() {
        setEdit(!isEdit);
        inputRef1.current!.focus({
            cursor: 'end'
        });
    }

    function valueChange(e) {
        setData(old => {
            old.url = e.target.value;
            return { ...old };
        });
    }

    function nameChange(e) {
        setData(old => {
            old.name = e.target.value;
            return { ...old };
        });
    }

    function openChange(checked) {
        setData(old => {
            old.isOpen = Number(checked);
            return { ...old };
        });
        onChange(newData);
    }

    function onPressEnter() {
        setEdit(false);
        onChange(newData);
    }

    function onNamePressEnter() {
        inputRef2.current!.focus({
            cursor: 'end'
        });
    }

    return (
        <HInfoLine
            title={
                <Input
                    size={'small'}
                    ref={inputRef1}
                    readOnly={!isEdit}
                    bordered={isEdit}
                    value={newData.name}
                    onChange={nameChange}
                    onPressEnter={onNamePressEnter}
                />
            }
            value={
                <Input
                    size={'small'}
                    ref={inputRef2}
                    readOnly={!isEdit}
                    bordered={isEdit}
                    value={newData.url}
                    onChange={valueChange}
                    onPressEnter={onPressEnter}
                />
            }
            suffixWidth={showIcon ? 100 : 40}
            suffixBgColor={'rgba(245, 245, 245, 1)'}
            edit={true}
            suffix={
                <>
                    {showIcon && (
                        <>
                            <HIcon iconName={'icon-edit'} onClick={onEditClick} />
                            <HIcon iconName={'icon-minus-square'} onClick={onDelete} />
                            {isLast && <HIcon iconName={'icon-plus-square'} onClick={onAdd} />}
                        </>
                    )}
                    <Switch style={{ marginLeft: 5 }} size={'small'} checked={Boolean(newData.isOpen)} onChange={openChange} />
                </>
            }
        />
    );
};
