import axios from "axios";

/**
 * @description             巡检模块接口
 */

export class InspectionService {

    static getList(params) {
        return axios.get('/data_report_info/query_all', { params });
    }

    static onDelete(id) {
        return axios.delete(`/data_report_info/delete/${id}`);
    }

}