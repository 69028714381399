import React, { Component } from 'react';
import {
    HDrawer,
    TableLine,
    HDescriptions,
    HContentLeft,
    HContentRight,
    HDataOverview,
    HInfoLine,
    HDataTopBar,
    HSingleBox,
    HLabelInput
} from '../../../components';
import { message } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { DataCount, GetListParam } from '../store/model';
import HCadForm from './form';
import axios from 'axios';
import { CadTypeList, CoordinateTypeList, DataTypeList, GroupDataTypeMap } from '../../../utils/static-data';
import { GroupModel } from '../../../components/model';
import GroupService from '../../../services/group';
import { CadService } from '../../../services/cad';

const rightList = [
    {
        key: 'coordinate',
        title: '坐标系',
        value: ''
    },
    {
        key: 'dataFormat',
        title: '数据格式',
        value: ''
    },
    {
        key: 'size',
        title: '数据大小',
        value: ''
    },
    {
        key: 'type',
        title: '数据类型',
        value: ''
    },
    {
        key: 'unit',
        title: '单位',
        value: '',
        suffix: { key: 'principal', title: '负责人', value: '' }
    },
    {
        key: 'createDate',
        title: '上传时间',
        value: ''
    }
];

interface Props {
    dataSource: any[];
    current: number;
    count: number;
    getList?: (data: GetListParam) => void;
    getDataCount?: (url) => void;
    dataCount: DataCount;
    changeList?: (list) => void;
    groupList: GroupModel[];
    getGroupList: (cadType) => void;
}

interface State {
    isPublic: number;
    isAdd: boolean;
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    activeData: any;
    hasMore: boolean;
    editData: any;
    cadType: number;
}

class HCad extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isPublic: 1,
            isAdd: false,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: undefined,
            hasMore: true,
            editData: {},
            cadType: 1
        };
    }

    componentDidMount() {
        const { isPublic, cadType } = this.state;
        this.props.getList({ isPublic, current: 1 });
        this.props.getDataCount('/cad/cad_count');
        this.props.getGroupList(cadType);
    }

    handleInfiniteOnLoad = page => {
        const { dataSource, getList, count } = this.props;
        const { isPublic } = this.state;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getList({ isPublic, current: page, data: dataSource });
        }
    };

    publicChange = value => {
        this.setState({
            isPublic: value,
            hasMore: true,
            isAdd: false,
            isEdit: false
        });
        this.props.getList({ isPublic: value, current: 1 });
    };

    onClickTableLine = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            rightList.map(r => {
                if (r.suffix) r.suffix.value = data[r.suffix.key];
                if (r.key === 'type') {
                    r.value = data[r.key] ? CadTypeList.find(it => it.value === data[r.key]).text : '';
                } else if (r.key === 'coordinate') {
                    r.value = data[r.key] ? CoordinateTypeList.find(it => it.value === data[r.key]).text : '';
                } else {
                    r.value = data[r.key];
                }
            });
            this.setState({
                activeId: data.id === activeId ? 0 : data.id,
                activeData: data.id === activeId ? undefined : { title: data.name, picUrl: data.picUrl, list: rightList }
            });
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        CadService.del(id).then(res => {
            if (res['code'] === 200) {
                message.success('删除成功');
                dataSource.splice(key, 1);
                const newList = [...dataSource];
                this.props.changeList(newList);
                this.setState({
                    activeData: id === activeId ? undefined : activeData,
                    activeId: id === activeId ? 0 : activeId
                });
            }
        });
    };

    onAdd = () => {
        const { isAdd } = this.state;
        this.setState({
            isAdd: !isAdd,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: {}
        });
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        this.setState({
            isEdit: visible,
            isAdd: false,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    closeDrawer = () => {
        const { isAdd } = this.state;
        if (isAdd) {
            this.setState({
                isAdd: false
            });
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        this.closeDrawer();
        const { isPublic, isAdd } = this.state;
        const { current } = this.props;
        this.props.getList({ isPublic, current: 1 });
    };

    cadTypeChange = cadType => {
        this.setState(
            {
                cadType
            },
            () => {
                this.props.getGroupList(cadType);
            }
        );
    };

    addGroup = name => {
        const { cadType } = this.state;
        GroupService.edit({
            isPublic: 1,
            name,
            type: cadType === 1 ? GroupDataTypeMap.cadLine : GroupDataTypeMap.cadPolygon
        }).then(() => {
            this.props.getGroupList(this.state.cadType);
        });
    };

    updateGroupChange = data => {
        GroupService.edit(data).then(() => {
            this.props.getGroupList(this.state.cadType);
        });
    };

    onDeleteGroup = id => {
        GroupService.del(id).then(() => {
            this.props.getGroupList(this.state.cadType);
        });
    };

    groupChange = id => {
        const { isPublic } = this.state;
        this.setState({
            hasMore: true
        });
        const data = id ? { isPublic, groupId: id, current: 1 } : { isPublic, current: 1 };
        this.props.getList(data);
    };

    render() {
        const { dataSource, dataCount, groupList } = this.props;
        const { isPublic, isAdd, isEdit, activeButton, activeId, activeData, hasMore, editData, cadType } = this.state;
        return (
            <>
                <HContentLeft>
                    <HDataTopBar
                        isPublic={isPublic}
                        groupList={groupList}
                        addGroup={this.addGroup}
                        onGroupChange={this.groupChange}
                        onPublicChange={this.publicChange}
                        onAdd={this.onAdd}
                    >
                        {isPublic ? <HSingleBox data={CadTypeList} value={cadType} onChange={value => this.cadTypeChange(value)} width={84} /> : null}
                    </HDataTopBar>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    imgUrl={r.picUrl}
                                    isPublic={isPublic}
                                    showDate={true}
                                    buttonList={[
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (event, key) => {
                                                event.stopPropagation();
                                                this.onEditChange(true, r, key);
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => {
                                        this.onClickTableLine(r);
                                    }}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {activeData && activeData.list ? (
                        <div>
                            <div className="h-preview-img">
                                <img src={activeData.picUrl} />
                            </div>
                            <HDescriptions title={activeData.title}>
                                {activeData.list.map((it, i) => {
                                    return (
                                        <HInfoLine
                                            key={i}
                                            title={it.title}
                                            value={it.value}
                                            suffixWidth={150}
                                            suffixBgColor={it.suffix ? 'rgba(240, 247, 253, 1)' : '#FBFBFB'}
                                            suffix={
                                                it.suffix ? (
                                                    <span>
                                                        {it.suffix.title}：{it.suffix.value}
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    );
                                })}
                            </HDescriptions>
                        </div>
                    ) : (
                        <HDataOverview title={'CAD数据总览'} data={dataCount} />
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-upload'} title={'上传'} shutDown={this.closeDrawer}>
                        <HCadForm
                            isAdd={isAdd}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            cadTypeChange={this.cadTypeChange}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑'} shutDown={this.closeDrawer}>
                        <HCadForm
                            isAdd={false}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            editData={editData}
                            cadTypeChange={this.cadTypeChange}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['upload', 'dataSource']),
    current: state.getIn(['upload', 'current']),
    count: state.getIn(['upload', 'count']),
    dataCount: state.getIn(['upload', 'dataCount']),
    groupList: state.getIn(['upload', 'groupList'])
});

const mapDispatch = dispatch => ({
    getList({ key, isPublic, current, data, groupId }) {
        dispatch(
            actionCreators.getPageList({
                url: '/cad/query',
                key,
                isPublic,
                current,
                data,
                groupId
            })
        );
    },
    getDataCount(url) {
        dispatch(actionCreators.getDataCount(url));
    },
    changeList(data) {
        dispatch(actionCreators.changeList(data));
    },
    getGroupList(cadType) {
        dispatch(
            actionCreators.getGroupList({
                isPublic: 1,
                type: cadType === 1 ? GroupDataTypeMap.cadLine : GroupDataTypeMap.cadPolygon
            })
        );
    }
});

export default connect(mapState, mapDispatch)(HCad);
