/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { Header } from '../../modules/layout';
import './index.scss';

interface Props {}

interface State {}

class Home extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'h-home-box'}>
                <Header />
                <div className={'h-home-content'}>{this.props.children}</div>
            </div>
        );
    }
}

export default Home;
