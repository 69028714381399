import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './page/login';
import Home from './page/home';
import Project from './page/project';
import Upload from './page/upload';
import Setup from './page/setup';
import Log from './page/log';
import IndexPage from './page/test/test';
import LeadershipPage from './page/leadership';
import Monitor from './page/monitor';
import Resource from './page/resource';

class routers extends Component {
    render() {
        return (
            <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={() => <Login />} />
                    <Route path="/leadership" component={LeadershipPage} />
                    <Home>
                        <Route path="/project" component={Project} />
                        <Route path="/upload" component={Upload} />
                        <Route path="/setup" component={Setup} />
                        <Route path="/log" component={Log} />
                        <Route path="/test" component={IndexPage} />
                        <Route path="/monitor" component={Monitor} />
                        {/* <Route path="/resource" component={Resource} /> */}
                    </Home>
                </Switch>
            </Router>
        );
    }
}

export default routers;
