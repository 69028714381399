import axios from 'axios';

interface editModel {
    id?: number;
    isPublic: number;
    name: string;
    // planId: number;
    remark?: string;
    type: number;
}

export default class GroupService {
    static get(data: { isPublic: number; type: number }) {
        return axios.get('/data_group/query_all', { params: { ...data } }).then(res => res.data);
    }

    static edit(data: editModel) {
        return axios.put('/data_group/edit', data).then(res => res.data);
    }

    static del(id) {
        return axios.delete(`/data_group/delete/${id}`);
    }
}
