/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HIcon, HHeaderMenu } from '../../../components';
import './index.scss';
import { message } from 'antd';
import { UserTypeMap } from '../../../utils/static-data';

const list = [
    {
        id: 1,
        title: '项目',
        icon: 'icon-default2',
        link: '/project',
        userType: [UserTypeMap.normal, UserTypeMap.leader, UserTypeMap.administrator]
    },
    {
        id: 2,
        title: '数据',
        icon: 'icon-default',
        link: '/upload',
        userType: [UserTypeMap.normal, UserTypeMap.leader, UserTypeMap.administrator]
    },
    {
        id: 3,
        title: '设置',
        icon: 'icon-default3',
        link: '/setup',
        userType: [UserTypeMap.normal, UserTypeMap.administrator]
    },
    {
        id: 4,
        title: '日志',
        icon: 'icon-default1',
        link: '/log',
        userType: [UserTypeMap.administrator, UserTypeMap.examiner]
    },
    {
        id: 5,
        title: '监测',
        icon: 'icon-default',
        link: '/monitor',
        userType: [UserTypeMap.administrator]
    }
    // ,
    // {
    //     id: 5,
    //     title: '资源',
    //     icon: 'icon-menu-module',
    //     link: '/resource',
    //     userType: [UserTypeMap.administrator]
    // }
];

interface Props {}

interface State {
    menuVisible: boolean;
    logout: boolean;
    name: string;
    account: string;
    type: number;
}

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            menuVisible: false,
            logout: false,
            name: '',
            account: '',
            type: 0
        };
    }

    componentDidMount(): void {
        const sessionData = sessionStorage.getItem('user');
        if (sessionData) {
            const { type, name, account } = JSON.parse(sessionData);
            this.setState({
                name: name,
                account: account,
                type
            });
        } else {
            window.location.href = '/';
        }
    }

    hideLogout = () => {
        this.setState({
            logout: false
        });
    };

    showLogout = e => {
        e.stopPropagation();
        this.hideMenu();
        const { logout } = this.state;
        this.setState({
            logout: !logout
        });
        if (logout) {
            document.removeEventListener('click', this.hideLogout);
        } else {
            document.addEventListener('click', this.hideLogout);
        }
    };

    logout = () => {
        window.location.href = '/';
        sessionStorage.removeItem('user');
        localStorage.removeItem('token');
    };

    showHeaderMenu = e => {
        e.stopPropagation();
        this.hideLogout();
        const { menuVisible } = this.state;
        this.setState({
            menuVisible: !menuVisible
        });
        if (menuVisible) {
            document.removeEventListener('click', this.hideMenu);
        } else {
            document.addEventListener('click', this.hideMenu);
        }
    };

    hideMenu = () => {
        this.setState({
            menuVisible: false
        });
    };

    render() {
        const { menuVisible, logout, name, account, type } = this.state;
        return (
            <div className={'h-header'}>
                <div className={'h-header-left'}>
                    <HIcon iconName="icon-caidan" newClass={'clickable'} onClick={this.showHeaderMenu} />
                    {menuVisible && <HHeaderMenu onChange={() => this.setState({ menuVisible: false })} data={list} />}
                </div>
                <div className={'h-header-right'}>
                    {type === 2 && (
                        <HIcon iconName="icon-default3" onClick={() => (window.location.href = '/leadership')} style={{ cursor: 'pointer' }} />
                    )}
                    <HIcon iconName="icon-smile" onClick={this.showLogout} />
                    {logout ? (
                        <div className={'h-header-popover'} onClick={e => e.stopPropagation()}>
                            <div className={'user-name'}>
                                <div>{name}</div>
                                <div className={'en-name'}>{account}</div>
                            </div>
                            <div className={'logout'} onClick={this.logout}>
                                <HIcon iconName={'icon-logout'} />
                                退出登录
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Header;
