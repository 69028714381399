/* eslint-disable react/prop-types */
import React, { Component } from 'react';
interface Props {
    data: any[];
}

interface State {
    newData: any[];
    activeNum: number;
}

class Milestone extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            newData: [[], []],
            activeNum: 0
        };
    }

    componentDidMount() {
        this.getNewData();
    }

    getNewData = () => {
        const { data } = this.props;
        const newList = data.length > 0 ? data.slice(0, 6) : [];
        let num = 0;
        const a = [[], []];
        // data.sort((a, b) => {
        //     let arr_a = a.planDate.split("-")
        //     let arr_b = b.planDate.split("-")
        //     let startTime = new Date(arr_a[0], arr_a[1], arr_a[2]).getTime();
        //     let endTime = new Date(arr_b[0], arr_b[1], arr_b[2]).getTime();
        //     return startTime - endTime
        // })
        // console.log(data);

        const newData = newList.map(item => {
            const obj = { ...item };
            if (item.planDate && item.actualDate) {
                num = num + 1;
                const arr_a = item.actualDate.split('-');
                const arr_b = item.planDate.split('-');
                const newActualDate = new Date(arr_a[0], arr_a[1], arr_a[2]).getTime();
                const newPlanDate = new Date(arr_b[0], arr_b[1], arr_b[2]).getTime();
                const rangeDateNum = (newPlanDate - newActualDate) / (1000 * 3600 * 24);
                if (rangeDateNum > 0) {
                    obj.color = 'green';
                    obj.rangeDate = rangeDateNum;
                } else {
                    obj.color = 'red';
                    obj.rangeDate = Math.abs(rangeDateNum);
                }
            } else {
                obj.color = '';
                obj.rangeDate = null;
            }
            return obj;
        });

        newData.map((item, i) => {
            if (i <= 2) {
                a[0].push(item);
            } else {
                a[1].push(item);
            }
        });
        this.setState({
            newData: a,
            activeNum: num
        });
    };

    componentDidUpdate = oldProps => {
        if (this.props.data !== oldProps.data) {
            this.getNewData();
        }
    };

    render() {
        const { newData, activeNum } = this.state;

        return (
            <div className={'milestone-box'}>
                <MilestoneChild data={newData[0]} activeNum={activeNum} childKey={0} />
                <div
                    className={`arc-line ${activeNum > 3 && 'active-arc-line'}`}
                    style={{ display: newData[1].length === 0 ? 'none' : 'block' }}
                ></div>
                <MilestoneChild data={newData[1]} activeNum={activeNum} childKey={1} />
            </div>
        );
    }
}

export default Milestone;

const MilestoneChild = ({ data, activeNum, childKey }) => {
    return (
        <div className={`milestone-child ${childKey === 1 && 'mirroring'}`}>
            {data.map((item, i) => {
                return (
                    <div key={i} className={`child-content ${item.color}`}>
                        <div className={`content-name  ${item.actualDate && 'active-name'}`}>{item.name}</div>
                        <div className={'content-time'}>{item.planDate && item.planDate.replace(/\-/g, '/')}</div>
                        {item.rangeDate && item.rangeDate !== 0 ? (
                            <div className={'content-rangeDate'}>
                                {item.color === 'red' ? '+' : '-'}
                                {item.rangeDate}
                            </div>
                        ) : null}
                    </div>
                );
            })}
            <div
                className={'line'}
                style={{
                    width: '100%',
                    display: data.length === 0 ? 'none' : 'inline-block'
                }}
            />
            <div
                className={'green-line'}
                style={{
                    width: `${
                        childKey === 0
                            ? activeNum > 2
                                ? '100%'
                                : `${33.3 * activeNum - 33.3 / 2}%`
                            : activeNum > 3
                            ? `${33.3 * (activeNum - 3) - 33.3 / 2}%`
                            : '0'
                    }`,
                    display: childKey === 0 ? (activeNum === 0 ? 'none' : 'inline-block') : activeNum < 4 ? 'none' : 'inline-block'
                }}
            />
        </div>
    );
};
