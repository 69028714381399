import * as constants from './constants';
import axios from 'axios';
import ProjectService from '../../../services/project';

// action是我们自己命名的一个对象，现在使用actionCreators命名函数替换掉最开始命名的对象。
const setData = value => ({
    type: constants.SET_DATA,
    value
});

const setCurrent = value => ({
    type: constants.SET_CURRENT,
    value
});

const setCount = value => ({
    type: constants.SET_COUNT,
    value
});

const setAddVisible = value => ({
    type: constants.SET_ADDVISIBLE,
    value
});

const setAllProject = value => ({
    type: constants.SET_ALLPROJECT,
    value
});

/**
 * @description 通用分页请求
 * @param url
 * @param keywords
 * @param current
 * @param data
 */
export const getData = (url, keywords, current, data) => {
    return dispatch => {
        axios
            .get(url, {
                params: {
                    key: keywords,
                    page: current,
                    size: 25
                }
            })
            .then(res => {
                const newData = [...data, ...res.data.list];
                const newCount = Math.ceil(res.data.total / 25);
                dispatch(setData(newData)); //就是setData里面type路径和map State一致的那个参数
                dispatch(setCurrent(current));
                dispatch(setCount(newCount));
            });
    };
};

/**
 *
 * @param keywords     关键词
 * @param current  分页
 * @param data    之前获取的data
 * @description  我的项目
 */

export const getChild1Data = (keywords, current, data = []) => {
    return dispatch => {
        dispatch(getData('/plan/query', keywords, current, data));
    };
};

export const changeChild1Data = data => {
    return dispatch => {
        dispatch(setData(data));
    };
};

export const changeAddVisible = visible => {
    return dispatch => {
        dispatch(setAddVisible(visible));
    };
};

export const getAllProject = (key = '') => {
    return dispatch => {
        ProjectService.getAll(key).then(list => {
            const data = list.map(it => {
                return { value: it.projectCode, text: it.projectName };
            });
            dispatch(setAllProject(data));
        });
    };
};
