import React, { Component } from 'react';
import { message } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import { SourceType, UploadDataType } from '../../../utils/static-data';
import { DocumentModel } from '../store/model';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { GroupModel } from '../../../components/model';
import { DocumentService } from '../../../services/document';

interface Props {
    isAdd: boolean;
    groupList: GroupModel[];
    addGroup: (name) => void;
    updateGroupChange: (data) => void;
    onDeleteGroup: (id) => void;
    isStatic: number;
    editData?: any;
    onSuccess: () => void;
    // getAllUserList: () => void;
    // allUserList: SourceType[];
    getAllProjectList: () => void;
    allProjectList: SourceType[];
}

interface States {
    formData: DocumentModel;
    validation: boolean;
}

class HDocumentForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                principal: '',
                fileUrl: '',
                groupId: undefined, //暂时
                isPublic: 1,
                name: '',
                projectCode: undefined,
                remark: '',
                unit: '',
                version: ''
            },
            validation: false
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (this.props.allProjectList !== prevProps.allProjectList || this.props.editData !== prevProps.editData) {
            this.setData();
        }
    }

    setData = () => {
        const { editData, isAdd } = this.props;
        if (!isAdd && editData) {
            const { formData } = this.state;
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
            });
            formData['id'] = editData.id;
            this.setState({
                formData
            });
        }
    };
    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData } = this.state;
                if (formData.projectCode && formData.fileUrl && formData.groupId) {
                    DocumentService.edit(formData).then(res => {
                        if (res['code'] === 200) {
                            message.success(`${formData.id ? '修改' : '添加'}成功`);
                            this.props.onSuccess();
                        }
                    });
                }
            }
        );
    };

    render() {
        const { isAdd, allProjectList, groupList, addGroup, updateGroupChange, onDeleteGroup } = this.props;
        const {
            formData: { fileUrl, name, projectCode, unit, groupId, remark, version, isPublic },
            validation
        } = this.state;
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput
                    label={'文件上传'}
                    rule={{ required: true, validation, message: '请上传文件' }}
                    value={fileUrl}
                    placeholder="文件Url"
                    fileType={UploadDataType.doc}
                    onChange={url => this.onChange('fileUrl', url)}
                />
                <HInput label={'备注'} value={remark} onChange={e => this.onChange('remark', e.target.value)} />
                <HInput label={'版本'} value={version} onChange={e => this.onChange('version', e.target.value)} />
                {isPublic === 1 ? (
                    <HLabelInput label={'分组'} required={true}>
                        <HAddItemSelect
                            dataSource={groupList}
                            add={addGroup}
                            value={groupId}
                            isForm={true}
                            rule={{ required: true, validation, message: '请选择分组' }}
                            onChange={value => this.onChange('groupId', value)}
                            maxWidth={212}
                            minWidth={212}
                            saveChange={updateGroupChange}
                            onDelete={onDeleteGroup}
                        />
                    </HLabelInput>
                ) : null}
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    // allUserList: state.getIn(['upload', "allUserList"]),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    // getAllUserList() {
    //     dispatch(actionCreators.getAllUser())
    // },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(HDocumentForm);
