import React, { useEffect, useState } from "react";
import { Input, DatePicker, Select } from "antd";
import ProjectService from "../../services/project";
import { UserService } from "../../services/user";
import { FillTableHead1, FillTableHead2 } from '../../utils/static-data';
import { HIcon } from "../simple";
import './index.scss';
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

const headList = {
    1: FillTableHead1,
    2: FillTableHead2
};

interface Props {
    data: any[];
    type: number;                   //类型(1:进度填报,2:投资填报)
    getData: () => void;
}

/**
 * @description                 我的填报下展开的表格
 * @param 
 */


const FillTable = ({ data, type, getData }: Props) => {

    const [dataSource, setDataSource] = useState([]);

    const [headData, setHeadData] = useState([]);

    useEffect(() => {
        UserService.getListByType(1).then(res => {
            setHeadData(res.data);
        });
    }, []);

    useEffect(() => {
        setDataSource([...data]);
    }, [data]);


    return <div className="fill__table">
        <div className="table__head">
            {
                headList[type].map((item, i) => {
                    return <LineContent
                        key={i}
                        style={{
                            width: `${item.width}%`
                        }}
                    >
                        {item.title}
                    </LineContent>;
                })
            }
        </div>
        <div className="table__content">
            {
                dataSource.map((item, i) => {
                    return <LineBox
                        key={i}
                        data={item}
                        headData={headData}
                        getData={getData}
                        level={1}
                        type={type}
                    />;
                })
            }
        </div>
    </div>;
};

export default FillTable;

const LineBox = ({ data, headData, getData, level, type }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const levelData = {
        1: 2,
        2: 3,
        3: 4
    };
    return <>
        <TableLine
            key={data.id}
            data={data}
            headData={headData}
            getData={getData}
            isOpen={() => setIsOpen(!isOpen)}
            level={level}
            type={type}
        />
        {
            data.missions && isOpen && <div>
                {
                    data.missions.length > 0 && data.missions.map((item) => {
                        return <LineBox
                            key={item.id}
                            data={item}
                            headData={headData}
                            getData={getData}
                            level={levelData[level]}
                            type={type}
                        />;
                    })
                }
            </div>
        }
    </>;
};

interface LineProps {
    data: any;
    headData: any[];                            //任务负责人列表
    level: number;                              //等级
    type: number;                               //类型(1:进度填报,2:投资填报)
    getData: () => void;
    isOpen?: () => void;
}

const TableLine = ({ data, headData, level, type, getData, isOpen }: LineProps) => {

    const [isChange, setIsChange] = useState(false);

    const [editData, setEditData] = useState({});

    const Identity = JSON.parse(sessionStorage.getItem("user")).type;

    useEffect(() => {
        if (data.workTime) {
            const newData = { ...data };
            newData.workTime = JSON.parse(newData.workTime);
            setEditData(newData);
        }
        else {
            setEditData(data);
        }
    }, [data]);

    /**
     * @description                 新增子任务
     */

    const onAdd = () => {
        const editData = {
            "finishNumber": 0,
            "mainId": data.id,
            "missionNumber": 0,
            "principal": "",
            "projectCode": data.projectCode,
            "remark": "",
            "title": "新增子任务",
            "unit": type === 1 ? "" : "元",
            "workTime": null,
            "type": type
        };
        ProjectService.editMissionData(editData).then(res => {
            getData();
        });
    };

    /**
     * @description                 确认修改
     */

    const onEdit = () => {
        const newData = { ...editData };
        newData['workTime'] = JSON.stringify(newData['workTime']);
        ProjectService.editMissionData(newData).then(res => {
            getData();
            setIsChange(false);
        });
    };

    /**
     * @description                 删除任务
     */

    const onDelete = () => {
        ProjectService.deleteMissionData(data.id).then(res => {
            getData();
        });
    };

    /**
     * @description                 修改editData
     */

    const changeData = (key, value) => {
        const newData = { ...editData };
        newData[key] = value;
        setEditData(newData);
    };

    const editDom = (value) => {
        if (Identity !== 4) {
            switch (value) {
                case "title":
                case "missionNumber":
                case "finishNumber":
                    return <Input
                        size="small"
                        value={editData[value]}
                        onChange={(e) => changeData(value, e.target.value)}
                    />;
                case "unit":
                    return type === 1 ?
                        <Input
                            size="small"
                            value={editData[value]}
                            onChange={(e) => changeData(value, e.target.value)}
                        />
                        :
                        <div className="line__text" title={editData[value]}>{editData[value]}</div>;
                case "workTime":
                    return <RangePicker
                        size="small"
                        value={editData[value] ? [moment(editData[value][0], "YYYY-MM-DD"), moment(editData[value][1], "YYYY-MM-DD")] : null}
                        onChange={(_, dateStrings) => changeData(value, dateStrings)}
                    />;
                case "principal":
                    return <Select
                        style={{ width: "100px" }}
                        size="small"
                        value={editData[value]}
                        onChange={(data) => {
                            changeData(value, data);
                        }}
                    >
                        {
                            headData.map((item) => {
                                return <Option key={item.id} value={item.name} >{item.name}</Option>;
                            })
                        }
                    </Select>;
                default:
                    return <div className="line__text" title={editData[value]}>{editData[value]}</div>;
            }
        } else {
            switch (value) {
                case "finishNumber":
                    return <Input
                        size="small"
                        value={editData[value]}
                        onChange={(e) => changeData(value, e.target.value)}
                    />;
                default:
                    return <div className="line__text" title={editData[value]}>{editData[value]}</div>;
            }
        }

    };

    return <div className="table__line">
        {
            headList[type].map((item, i) => {
                return <LineContent
                    key={i}
                    newClass={`${(i === 0) ? `is__child${level}` : ""}`}
                    style={{ width: `${item.width}%` }}
                    isSubsets={(item.dataIndex === "title" && editData['missions'] && editData['missions'].length > 0) ? () => isOpen() : null}
                >
                    {
                        item.dataIndex === "operation" ?
                            <div className="button__box">
                                {
                                    isChange ?
                                        <>
                                            <HIcon iconName="icon-check" onClick={() => onEdit()} />
                                            <HIcon iconName="icon-close" onClick={() => setIsChange(false)} />
                                        </>
                                        :
                                        <>
                                            {
                                                level !== 4 && Identity !== 4 && <HIcon iconName="icon-plus-circle" onClick={onAdd} />
                                            }
                                            {
                                                type === 2 ?
                                                    data.missions.length === 0 && <HIcon iconName="icon-edit" onClick={() => setIsChange(true)} />
                                                    :
                                                    <HIcon iconName="icon-edit" onClick={() => setIsChange(true)} />
                                            }

                                            {Identity !== 4 && <HIcon iconName="icon-delete" onClick={onDelete} />}
                                        </>
                                }
                            </div>
                            :
                            isChange ?
                                editDom(item.dataIndex)
                                :
                                <div
                                    className="line__text"
                                    title={item.dataIndex === "workTime" ?
                                        editData[item.dataIndex] ? `${editData[item.dataIndex][0]} - ${editData[item.dataIndex][1]}`
                                            :
                                            "-"
                                        : editData[item.dataIndex]
                                    }
                                >
                                    {
                                        item.dataIndex === "workTime" ?
                                            editData[item.dataIndex] ?
                                                `${editData[item.dataIndex][0]} - ${editData[item.dataIndex][1]}`
                                                :
                                                "-"
                                            :
                                            editData[item.dataIndex]
                                    }
                                </div>
                    }
                </LineContent>;
            })
        }
    </div >;
};

interface LineContent {
    newClass?: string;
    style?: any;
    children: any;
    isSubsets?: () => void;
}

const LineContent = ({ newClass = "", style = {}, children, isSubsets }: LineContent) => {
    return <div
        className={`line__content ${newClass} ${isSubsets && "isOpen"}`}
        style={style}
    >
        {isSubsets && <HIcon iconName="icon-down" onClick={isSubsets} />}
        {children}
    </div>;
};