import { message } from 'antd';

export const AMapKey = '04682d0a9207f6e3fd6b76623a212e03';
export const MapWebServiceKey = '4ce3e4c273cb9a380eec011428dcfe6a';
const AMap = window['AMap'];

export const mapCenter = [121.456424, 31.234892]; //[121.474694,31.231314];
export default class HAMap {
    static map: any = {};
    static element: any;
    static satellite;
    //light 白
    //grey 黑
    static init({ id = 'amap-container', center = mapCenter, zoom = 9, style = 'light' }) {
        this.map = new AMap.Map(id, {
            resizeEnable: true,
            center,
            mapStyle: `amap://styles/${style}`,
            zoom
        });
        this.satellite = new AMap.TileLayer.Satellite({
            map: this.map
        });
        this.satellite.hide();
        // this.map.on('zoomend', () => {
        //     console.log(this.map.getZoom())
        // });
        // this.map.on('click', (e) => {
        //     console.log(e.lnglat.getLng() + ',' + e.lnglat.getLat())
        // });
    }

    static setCenter(position) {
        this.map.setCenter(position); //设置地图中心点
    }

    // 添加点标记
    static addMarker({ icon, position, label, offsetL, offsetT }) {
        const marker = new AMap.Marker({
            icon,
            position,
            offset: new AMap.Pixel(offsetL, offsetT),
            label: {
                content: label,
                direction: 'top'
            }
        });
        marker.setMap(this.map);
    }

    static centerMarker = null;

    // 添加中心点标记
    static addCenterMarker(icon, position) {
        const marker = new AMap.Marker({
            icon: icon,
            position,
            offset: new AMap.Pixel(-13, -30)
        });
        marker.setMap(this.map);

        this.centerMarker = marker;
        return marker;
    }

    // 移除点标记
    static clearMarker() {
        if (this.centerMarker) {
            this.centerMarker.setMap(null);
            this.centerMarker = null;
        }
    }

    //根据地址找城市
    static geoCoder() {
        return AMap;
    }
}
