export interface SourceType {
    text: string;
    value: number | string;
}

export const EditorRouter = '/editor';
export const SkinRouter = '/skin';
export const DocRouter = '/doc';
export const AccuracyUnit = 'cm/pix';

export const StateList = [
    { text: '规划阶段', value: '规划阶段' },
    { text: '设计阶段', value: '设计阶段' },
    { text: '施工阶段', value: '施工阶段' },
    { text: '运维阶段', value: '运维阶段' }
];

export const UserTypeMap = {
    normal: 1,
    leader: 2,
    administrator: 3,
    examiner: 4
};

export const UserTypeList = [
    { text: '普通用户', value: UserTypeMap.normal },
    { text: '领导', value: UserTypeMap.leader },
    { text: '管理员', value: UserTypeMap.administrator },
    { text: '审查员', value: UserTypeMap.examiner }
];

export const GenderList = [
    { text: '男', value: 1 },
    { text: '女', value: 0 }
];

export const CooperateBuiltTypeList = [
    { text: '建设单位', value: 1 },
    { text: '设计单位', value: 2 },
    { text: '施工单位', value: 3 },
    { text: '监理单位', value: 4 },
    { text: '运营单位', value: 5 },
    { text: '咨询单位', value: 6 },
    { text: '管理单位', value: 7 },
    { text: '代建单位', value: 8 },
    { text: '项目法人', value: 9 },
    { text: '建管单位', value: 10 },
];

export const DataTypeList = [
    { text: '项目数据', value: 0 },
    { text: '公共数据', value: 1 }
];
export const CadTypeList = [
    { text: '线段', value: 1 },
    { text: '体块', value: 2 }
];

export const StaticTypeList = [
    { text: '静态数据', value: 1 },
    { text: '动态数据', value: 0 }
];

export const CoordinateTypeList = [
    { value: 1, text: 'WGS84' },
    { value: 4, text: 'EPSG3857' },
    { value: 2, text: 'CJC2000' },
    { value: 3, text: 'SHCJ', disabled: true }
];

export const GroupDataTypeMap = {
    terrain: 1,
    model: 2,
    cad: 3,
    label: 4,
    doc: 5,
    panorama: 20,
    // 冲於: 6
    // 7：预测
    // 8：监测
    // 9：监控
    // 10：轨迹
    pipeLine: 11, //配置线段
    geometry: 12, //线体-配置体块
    measurePoint: 13, //测量-点测量
    measureLine: 14, //测量-距离测量
    measurePolygon: 15, //测量-面积测量
    modelAttr: 16, //模型属性
    labelAttr: 17, //标签属性
    cadLine: 18, //cad线
    cadPolygon: 19 //cad体
};

export const UploadDataType = {
    music: 1, //音乐
    pic: 2, // 图片
    cad: 3, //cad
    terrain: 4, //地形
    label: 5, //标签
    model: 6, //模型
    doc: 7 //文档
};

export const AvataColorMap = {
    0: '#ffe58f',
    1: '#fffb8f',
    2: '#eaff8f',
    3: '#ffa39e',
    4: '#ffbb96',
    5: '#ffd591',
    6: '#b7eb8f',
    7: '#87e8de',
    8: '#91d5ff',
    9: '#adc6ff',
    10: '#d3adf7',
    11: '#ffadd2'
};

export const InspectionTypeList = [
    { text: '全部数据', value: 0 },
    { text: '常规数据', value: 1 },
    { text: '异常数据', value: 2 },
];

export const FillTableHead1 = [
    {
        title: "任务标题",
        width: 20,
        dataIndex: "title"
    },
    {
        title: "计划时间",
        width: 16,
        dataIndex: "workTime"
    },
    {
        title: "任务负责人",
        width: 14,
        dataIndex: "principal"
    },
    {
        title: "总工作量",
        width: 10,
        dataIndex: "missionNumber"
    },
    {
        title: "已完成",
        width: 10,
        dataIndex: "finishNumber"
    },
    {
        title: "单位",
        width: 11,
        dataIndex: "unit"
    },
    {
        title: "创建时间",
        width: 11,
        dataIndex: "createDate"
    },
    {
        title: "操作",
        width: 8,
        dataIndex: "operation"
    },
];

export const FillTableHead2 = [
    {
        title: "任务标题",
        width: 20,
        dataIndex: "title"
    },
    {
        title: "计划时间",
        width: 16,
        dataIndex: "workTime"
    },
    {
        title: "任务负责人",
        width: 14,
        dataIndex: "principal"
    },
    {
        title: "总投资",
        width: 10,
        dataIndex: "missionNumber"
    },
    {
        title: "已投资",
        width: 10,
        dataIndex: "finishNumber"
    },
    {
        title: "单位",
        width: 11,
        dataIndex: "unit"
    },
    {
        title: "创建时间",
        width: 11,
        dataIndex: "createDate"
    },
    {
        title: "操作",
        width: 8,
        dataIndex: "operation"
    },
];