/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { HButton, HInput, HLabelInput, HSelect } from '../../../components';
import { getToken } from '../../../utils/common';
import { CoordinateTypeList, SourceType } from '../../../utils/static-data';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { SedimentService } from '../../../services/sediment';

interface Props {
    isAdd: boolean;
    editData?: any;
    allProjectList: SourceType[];
    getAllProjectList: () => void;
    onSave: () => void;
}

interface State {
    formData: any;
    uploadList1: any[];
    uploadList2: any[];
}

class form extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                projectCode: "",
                stage: "",
                dataName: "",
                major: "",
                coordinate: 1,
                files: []
            },
            uploadList1: [],
            uploadList2: [],
        };
    }

    componentDidMount(): void {
        const { isAdd } = this.props;
        this.props.getAllProjectList();
        if (!isAdd) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.editData !== prevProps.editData) {
            if (this.props.editData) {
                this.setFormData();
            }
        }
    }

    setFormData = () => {
        const { editData } = this.props;
        const newData = {
            id: editData.id,
            projectCode: editData.projectCode,
            stage: editData.stage,
            major: editData.major,
            coordinate: editData.coordinate,
            dataName: editData.dataName,
            files: editData.ossList
        };
        const uploadList1 = [];
        const uploadList2 = [];
        editData.ossList.map((item, i) => {
            const type = item.substring(item.length - 4, item.length);
            const url = item.split('/');
            const data = {
                uid: i,
                name: url[url.length - 1].split('-')[0],
                status: 'done',
                response: {
                    code: 200,
                    msg: "success",
                    data: item
                }
            };
            if (type === ".tif") {
                uploadList1.push(data);
            } else {
                uploadList2.push(data);
            }
        });
        this.setState({
            formData: newData,
            uploadList1,
            uploadList2
        });
    }

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    save = () => {
        const { formData } = this.state;
        SedimentService.uploadInfoFile(formData).then((res: unknown) => {
            message.success(res['msg']);
            this.props.onSave();
        });
    }

    render() {
        const { isAdd, allProjectList } = this.props;
        const { formData: { projectCode, stage, major, dataName, coordinate, files }, uploadList1, uploadList2 } = this.state;
        const token = getToken();
        const generalProp = {
            headers: {
                Authorization: `bearer ${token}`
            }
        };
        const tifProp = {
            action: `${process.env.REACT_APP_API}/upload`,
            data: { type: 10 },
            accept: '.tif',
            onChange: (value) => {
                this.setState({
                    uploadList1: value.fileList
                });
                if (value.file.status === "done") {
                    const { code, data, msg } = value.file.response;
                    if (code === 200) {
                        files.push(data);
                        this.onChange("files", files);
                    } else {
                        message.error(msg);
                    }
                }
                if (value.file.status === "removed") {
                    const { data } = value.file.response;
                    const newList = [...files];
                    newList.splice(newList.indexOf(data), 1);
                    this.onChange("files", newList);
                }
            },
            fileList: uploadList1
        };
        const dxfProp = {
            action: `${process.env.REACT_APP_API}/upload`,
            data: { type: 3 },
            accept: '.dxf',
            onChange: (value) => {
                this.setState({
                    uploadList2: [...value.fileList]
                });
                if (value.file.status === "done") {
                    const { code, data, msg } = value.file.response;
                    if (code === 200) {
                        files.push(data);
                        this.onChange("files", files);
                    } else {
                        message.error(msg);
                    }
                }
                if (value.file.status === "removed") {
                    const { data } = value.file.response;
                    const newList = [...files];
                    newList.splice(newList.indexOf(data), 1);
                    this.onChange("files", newList);
                }
            },
            fileList: uploadList2
        };
        return (
            <div>
                <div style={{ padding: '9px 16px' }}>
                    <HSelect
                        label={'项目编号'}
                        value={projectCode}
                        dataSource={allProjectList}
                        onChange={(value) => this.onChange("projectCode", value)}
                    />
                    <HInput label={'阶段'} value={stage} onChange={e => this.onChange('stage', e.target.value)} />
                    <HInput label={'专业'} value={major} onChange={e => this.onChange('major', e.target.value)} />
                    <HInput label={'数据名称'} value={dataName} onChange={e => this.onChange('dataName', e.target.value)} />
                    <HSelect
                        label={'坐标系'}
                        value={coordinate}
                        dataSource={CoordinateTypeList}
                        onChange={(value) => this.onChange("coordinate", value)}
                    />
                    <HLabelInput label='地形上传' style={{ alignItems: 'flex-start' }}>
                        <Upload {...generalProp} {...tifProp}>
                            <div className='upload-button'>
                                <PlusOutlined /><span>添加文件</span>
                            </div>
                        </Upload>
                    </HLabelInput>
                    <HLabelInput label='CAD上传' style={{ alignItems: 'flex-start' }}>
                        <Upload {...generalProp} {...dxfProp}>
                            <div className='upload-button'>
                                <PlusOutlined /><span>添加文件</span>
                            </div>
                        </Upload>
                    </HLabelInput>
                </div>
                <div className={'h-text-center'} style={{ paddingTop: 24, borderTop: '1px solid #DDD' }}>
                    <HButton onClick={this.save}>{isAdd ? '上传' : '保存'}</HButton>
                </div>
            </div>
        );
    }
}

const mapState = state => ({
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(form);