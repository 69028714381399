import React from 'react';
import { Select, Input } from 'antd';

import '../index.scss';

const { Option } = Select;

export default function EditForm({ props }: any) {
    const { type, value, setValue, flag, option } = props;

    const handleInputChange = e => {
        setValue(true, flag, null, e.target.value);
    };

    const handleRadioChange = e => {
        setValue(true, flag, null, e);
    };

    return (
        <>
            {type === 'input' ? (
                <div className="h-line-edit-input">
                    <span>
                        <span style={{ color: 'red' }}>* </span>
                        {flag}
                    </span>
                    <Input value={value} onChange={handleInputChange}></Input>
                </div>
            ) : type === 'radio' ? (
                <div className="h-line-edit-input">
                    <span>
                        <span style={{ color: 'red' }}>* </span>
                        {flag}
                    </span>
                    <Select onChange={handleRadioChange} value={value}>
                        <Option value={0}>{option[0]}</Option>
                        <Option value={1}>{option[1]}</Option>
                    </Select>
                </div>
            ) : null}
        </>
    );
}
