import axios from "axios";

/**
 * @description                 冲淤模块请求
 */

export class SedimentService {

    /**
     * @description                 根据id获取冲淤信息和剖面线信息
     * @param id                    数据id
     */

    static getInfoSectionById(id) {
        return axios.get(`/flush_silt/get_info_section_by_id/${id}`);
    }

    static uploadInfoFile(data) {
        return axios.put('/flush_silt/upload_info_file', data);
    }

    /**
     * @description                 删除冲淤数据
     * @param id                    数据id
     */

    static deleteFlushSilt(id) {
        return axios.delete(`flush_silt/delete/${id}`);
    }

    /**
     * @description                 删除tif文件
     * @param id                    文件id
     */

    static deleteTif(id) {
        return axios.delete(`/flush_tif/delete/${id}`);
    }

    /**
     * @description                 删除dxf文件
     * @param id                    文件id
     */

    static deleteDxf(id) {
        return axios.delete(`/flush_silt_file/delete/${id}`);
    }

}