/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import './index.scss';
import LoginForm from './form';
import * as THREE from 'three';

interface Props {}

interface States {}

export default class Login extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    SEPARATION = 100;
    AMOUNTX = 200;
    AMOUNTY = 200;
    cam;
    particles;
    count = 0;
    renderer;
    scene;
    mouseX = 0;
    mouseY = 0;
    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;
    bg = -1; //Math.random() - 0.5;

    componentDidMount() {
        this.init();
        this.animate();
    }

    // init = () => {
    //     console.log(THREE.SpriteMaterial);
    //     const container = document.getElementsByClassName("h-login-3dbg")[0];
    //     this.cam = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    //     this.cam.position.x -= 1000;
    //     this.cam.position.z = 1000;
    //     this.scene = new THREE.Scene();
    //     const numParticles = Math.pow(this.AMOUNTX, 2);
    //     const positions = new Float32Array(numParticles * 3);
    //     const scales = new Float32Array(numParticles);
    //     const geometry = new THREE.BufferGeometry();
    //     geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    //     geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
    //     let i = 0, j = 0;
    //     for (let ix = 0; ix < this.AMOUNTX; ix++) {
    //         for (let iy = 0; iy < this.AMOUNTY; iy++) {
    //             positions[i] = ix * this.SEPARATION - this.AMOUNTX * this.SEPARATION / 2;
    //             positions[i + 1] = 0;
    //             positions[i + 2] = iy * this.SEPARATION - this.AMOUNTY * this.SEPARATION / 2;
    //             scales[j] = 1;

    //             const vertexShader = `
    //                 attribute float scale;
    //                 void main(){
    //                     vec4 mvPosition = modelViewMatrix * vec4(position,1.0);
    //                     gl_PointSize = scale * (300.0 / - mvPosition.z);
    //                     gl_Position = projectionMatrix * mvPosition;
    //                 }
    //             `;
    //             const fragmentShader = `
    //                 uniform vec3 color;
    //                 void main(){
    //                     if(length(gl_PointCoord - vec2(0.5,0.5))>0.475) discard;
    //                     gl_FragColor = vec4(color,1.0);
    //                 }
    //             `;
    //             const material = new THREE.SpriteMaterial({
    //                 color: new THREE.Color(0x333333)
    //                 ,
    //                 vertexShader,
    //                 fragmentShader,
    //             });
    //             const particle = new THREE.Sprite(material)
    //             this.scene.add(particles);
    //             i += 3;
    //             j++;
    //         }
    //     }

    //     this.renderer = new THREE.WebGLRenderer({
    //         antialias: true
    //     })
    //     this.renderer.setPixelRatio(window.devicePixelRatio);
    //     this.renderer.setSize(window.innerWidth, window.innerHeight);
    //     this.renderer.setClearColor(0xffffff)
    //     container.appendChild(this.renderer.domElement);
    //     container.addEventListener('pointermove', this.onPointerMove);
    //     window.addEventListener('resize', this.onWindowResize);
    // }
    init = () => {
        const container = document.getElementsByClassName('h-login-3dbg')[0];
        this.cam = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
        this.cam.position.x -= -62;
        this.cam.position.y -= -664;
        this.cam.position.z = 1000;
        this.scene = new THREE.Scene();
        const numParticles = Math.pow(this.AMOUNTX, 2);
        const positions = new Float32Array(numParticles * 3);
        const scales = new Float32Array(numParticles);
        const geometry = new THREE.BufferGeometry();
        const vertexShader = `
            attribute float scale;
            void main(){
                vec4 mvPosition = modelViewMatrix * vec4(position,1.0);
                gl_PointSize = scale * (300.0 / - mvPosition.z);
                gl_Position = projectionMatrix * mvPosition;
            }
        `;
        const fragmentShader = `
            uniform vec3 color;
            void main(){
                if(length(gl_PointCoord - vec2(0.5,0.5))>0.475) discard;
                gl_FragColor = vec4(color,1.0);
            }
        `;
        let i = 0,
            j = 0;
        for (let ix = 0; ix < this.AMOUNTX; ix++) {
            for (let iy = 0; iy < this.AMOUNTY; iy++) {
                positions[i] = ix * this.SEPARATION - (this.AMOUNTX * this.SEPARATION) / 2;
                positions[i + 1] = 0;
                positions[i + 2] = iy * this.SEPARATION - (this.AMOUNTY * this.SEPARATION) / 2;
                scales[j] = 1;
                i += 3;
                j++;
            }
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
        const material = new THREE.ShaderMaterial({
            uniforms: {
                color: {
                    value: new THREE.Color(this.bg < 0 ? 0x333333 : 0xffffff)
                }
            },
            vertexShader,
            fragmentShader
        });
        this.particles = new THREE.Points(geometry, material);
        this.scene.add(this.particles);
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(this.bg < 0 ? 0xffffff : 0x000000);
        container.appendChild(this.renderer.domElement);
        container.addEventListener('pointermove', this.onPointerMove);
        window.addEventListener('resize', this.onWindowResize);
    };
    onWindowResize = () => {
        this.windowHalfX = window.innerWidth / 2;
        this.windowHalfY = window.innerHeight / 2;

        this.cam.aspect = window.innerWidth / window.innerHeight;
        this.cam.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    };
    onPointerMove = event => {
        if (event.isPrimary === false) return;
        this.mouseX = event.clientX - this.windowHalfX;
        this.mouseY = event.clientY - this.windowHalfY;
    };
    animate = () => {
        requestAnimationFrame(this.animate);
        this.renderBg();
    };

    renderBg = () => {
        const positions = this.particles.geometry.attributes.position.array;
        const scales = this.particles.geometry.attributes.scale.array;
        let i = 0,
            j = 0;

        for (let ix = 0; ix < this.AMOUNTX; ix++) {
            for (let iy = 0; iy < this.AMOUNTY; iy++) {
                positions[i + 1] = Math.sin((ix + this.count) * 0.3) * 50 + Math.sin((iy + this.count) * 0.5) * 50;

                scales[j] = (Math.sin((ix + this.count) * 0.3) + 1) * 20 + (Math.sin((iy + this.count) * 0.5) + 1) * 20;

                i += 3;
                j++;
            }
        }
        this.particles.geometry.attributes.position.needsUpdate = true;
        this.particles.geometry.attributes.scale.needsUpdate = true;

        this.renderer.render(this.scene, this.cam);

        this.count += 0.03;
    };

    render() {
        return (
            <div className={'h-login'}>
                <div className={'h-login-form'}>
                    <LoginForm />
                </div>
                <a target={'_blank'} className="h-login-text" href="https://beian.miit.gov.cn" rel="noreferrer">
                    浙ICP备2023019680号-1
                </a>
                <div className={'h-login-mask'} />
                <div className={'h-login-3dbg'} />
            </div>
        );
    }
}
