import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDrawer } from '../../../components/simple';
import { TableLine, HInfoLine, HDescriptions } from '../../../components';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import axios from 'axios';
import HProjectForm from './form';
import { SourceType } from '../../../utils/static-data';
import HAMap from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';
import { debounce } from '../../../utils/common';
import ProjectService from '../../../services/project';

const detailList = [
    {
        key: 'projectCode',
        title: '项目编号',
        value: ''
    },
    {
        key: 'projectName',
        title: '项目名称',
        value: ''
    },
    {
        key: 'contractor',
        title: '承担单位',
        value: ''
    },
    {
        key: 'projectRegion',
        title: '项目地区',
        value: ''
    },
    {
        key: 'leader',
        title: '领导',
        value: ''
    },
    {
        key: 'principal',
        title: '负责人',
        value: ''
    },
    {
        key: 'informant',
        title: '填报人',
        value: ''
    },
    {
        key: 'userPool',
        title: '用户群',
        value: ''
    }
];

interface Props {
    keyword: string;
    isAdd: boolean;
    current: number;
    dataSource: any[];
    count: number;
    choseAddDrawer: () => void;
    getProjectList?: (value, current, data?) => void;
    changeProjectData?: (data) => void;
    getNormalList?: (key?) => void;
    getLeaderList?: (key?) => void;
    normalUserList: SourceType[];
    leaderList: SourceType[];
}

interface States {
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    hasMore: boolean;
    activeData: any[];
    editData: any;
}

class ProjectSet extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            hasMore: true,
            activeData: [],
            editData: {}
        };
    }

    initMap = () => {
        HAMap.init({ id: 'project-preview-map' });
    };

    componentDidMount() {
        this.props.getProjectList('', 1);
        this.props.getNormalList();
        this.props.getLeaderList();
        this.initMap();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.isAdd !== this.props.isAdd && this.props.isAdd) {
            if (this.state.isEdit) {
                this.onEditChange(false);
            }
        }
        if (prevProps.keyword !== this.props.keyword) {
            debounce(this.props.getProjectList(this.props.keyword, 1), 300);
        }
    }

    componentWillUnmount(): void {
        HAMap.clearMarker();
    }

    handleInfiniteOnLoad = page => {
        const { dataSource, getProjectList, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getProjectList(keyword, page, dataSource);
        }
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.choseAddDrawer();
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    lineClick = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            detailList.map(r => {
                if ((r.key === 'leader' || r.key === 'principal' || r.key === 'informant' || r.key === 'userPool') && data[r.key]) {
                    r.value = data[r.key].map(u => u.name);
                } else r.value = data[r.key];
            });
            this.setState({
                activeData: data.id === activeId ? [] : detailList,
                activeId: data.id === activeId ? 0 : data.id
            });
            HAMap.clearMarker();
            if (data.position) {
                HAMap.setCenter(data.position);
                HAMap.addCenterMarker(poiMarker, data.position);
            }
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        ProjectService.del(id).then(res => {
            dataSource.splice(key, 1);
            const newData = [...dataSource];
            this.props.changeProjectData(newData);
            this.setState({
                activeData: id === activeId ? [] : activeData,
                activeId: id === activeId ? 0 : activeId
            });
        });
    };

    closeDrawer = () => {
        const { isAdd, choseAddDrawer } = this.props;
        if (isAdd) {
            choseAddDrawer();
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        const { isAdd, keyword, current } = this.props;
        this.closeDrawer();
        this.props.getProjectList(keyword, 1);
    };

    render() {
        const { isEdit, activeId, activeButton, hasMore, activeData, editData } = this.state;
        const { isAdd, dataSource, normalUserList, leaderList } = this.props;
        return (
            <>
                <HContentLeft>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    showDate={true}
                                    buttonList={[
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (event, key) => {
                                                event.stopPropagation();
                                                this.onEditChange(true, r, key);
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => this.lineClick(r)}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    <div className={'h-content-right-map'} style={{ display: activeData.length > 0 ? 'block' : 'none' }} id={'project-preview-map'} />
                    {activeData.length > 0 && (
                        <HDescriptions title={activeData[1].value}>
                            {activeData.map((it, i) => {
                                return <HInfoLine key={i} title={it.title} value={it.value} />;
                            })}
                        </HDescriptions>
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-file-add'} title={'新建项目'} shutDown={this.closeDrawer}>
                        <HProjectForm isAdd={isAdd} normalUserList={normalUserList} leaderList={leaderList} onSuccess={this.onSaveSuccess} />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑项目'} shutDown={this.closeDrawer}>
                        <HProjectForm
                            isAdd={isAdd}
                            editData={editData}
                            normalUserList={normalUserList}
                            leaderList={leaderList}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['setup', 'dataSource']),
    current: state.getIn(['setup', 'current']),
    count: state.getIn(['setup', 'count']),
    normalUserList: state.getIn(['setup', 'normalUserList']),
    leaderList: state.getIn(['setup', 'leaderList'])
});

const mapDispatch = dispatch => ({
    getProjectList(value, current, data) {
        dispatch(actionCreators.getProjectList(value, current, data));
    },
    changeProjectData(data) {
        dispatch(actionCreators.changeData(data));
    },
    getNormalList() {
        dispatch(actionCreators.getNormalList());
    },
    getLeaderList() {
        dispatch(actionCreators.getLeaderList());
    }
});

export default connect(mapState, mapDispatch)(ProjectSet);
