/* eslint-disable react/prop-types */
import React, { Component, ReactNode } from 'react';
import { Input, InputNumber, Radio } from 'antd';
import { HLabelInput } from '../simple';
import { HUploadFile } from '../upload';
import './index.scss';

const { Group } = Input;

interface rule {
    required: boolean;
    validation: boolean;
    message: string;
}

interface Props {
    label: string | ReactNode;
    value: string | number | number[];
    onChange?: (e) => void;
    password?: boolean;
    fileType?: number;
    readOnly?: boolean;
    placeholder?: string;
    suffix?: ReactNode;
    rule?: rule; //验证 必填
    onUploadTypeChange?: (type) => void;
    group?: boolean; //数据大小组
    groupInputWidth?: number;
    disabled?: boolean;
    newClass?: string;
}

interface States {
    error: boolean;
    inputValue: string | number;
    inputRule: rule;
    uploadType: number;
}

class Index extends Component<Props, States> {
    static defaultProps = {
        rule: { required: false, validation: false, message: '' }
    };

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            inputValue: '',
            inputRule: { required: false, validation: false, message: '' },
            uploadType: 0
        };
    }

    componentDidMount(): void {
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        const { value, rule } = this.props;
        if (prevProps.rule.validation !== rule.validation) {
            this.setState({
                inputRule: rule
            });
            this.validator(this.state.inputValue);
        }
        if (prevProps.value !== value) {
            this.setState({
                inputValue: Array.isArray(value) ? value.join(',') : value
            });
            this.validator(value);
        }
    }

    setData = () => {
        const { value, rule } = this.props;
        this.setState({
            inputRule: rule,
            inputValue: Array.isArray(value) ? value.join(',') : value
        });
    };

    validator = value => {
        const { rule } = this.props;
        if (rule && rule.required && !value) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                error: false
            });
        }
    };

    inputChange = e => {
        // console.log(e)
        const { onChange, fileType, group } = this.props;
        this.setState({
            inputValue: group ? e : e.target.value
        });
        this.validator(group ? e : e.target.value);
        onChange && onChange(fileType ? e.target.value : e);
    };

    fileChange = url => {
        const { onChange } = this.props;
        this.setState({
            inputValue: url
        });
        this.validator(url);
        onChange && onChange(url);
    };
    onUploadTypeChange = e => {
        const { onUploadTypeChange } = this.props;
        const type = e.target.value;
        this.setState({
            uploadType: type
        });
        onUploadTypeChange && onUploadTypeChange(type);
    };

    render() {
        const { label, password, readOnly, placeholder, suffix, fileType, groupInputWidth, group, disabled, newClass = '' } = this.props;
        const { error, inputValue, inputRule, uploadType } = this.state;
        // console.log("Boolean(uploadType)", Boolean(uploadType))
        const renderInput = (
            <Input
                size="small"
                readOnly={readOnly || (fileType && !Boolean(uploadType))}
                placeholder={error ? inputRule.message : placeholder}
                value={inputValue}
                onChange={this.inputChange}
                onBlur={() => this.validator(inputValue)}
                disabled={disabled}
            />
        );

        return (
            <HLabelInput label={label} required={inputRule.required} className={error ? 'h-item-has-error' : ''}>
                {password && (
                    <PasswordInput
                        value={inputValue}
                        onChange={this.inputChange}
                        placeholder={error ? inputRule.message : placeholder}
                        readOnly={readOnly}
                    />
                )}
                {fileType && (
                    <UploadInput
                        uploadType={uploadType}
                        fileType={fileType}
                        renderInput={renderInput}
                        fileChange={this.fileChange}
                        onUploadTypeChange={this.onUploadTypeChange}
                    />
                )}
                {group && (
                    <GroupInput
                        width={groupInputWidth}
                        value={inputValue}
                        onChange={this.inputChange}
                        placeholder={error ? inputRule.message : placeholder}
                        suffix={suffix}
                    />
                )}

                {!password && !fileType && !group ? (
                    <div className={`${suffix ? 'h-input-suffix' : 'h-input-input'} ${newClass}`}>
                        {renderInput}
                        {suffix ? suffix : null}
                    </div>
                ) : null}
            </HLabelInput>
        );
    }
}

export default Index;

const PasswordInput = ({ readOnly, placeholder, value, onChange }) => {
    return (
        <Input.Password
            size="small"
            autoFocus={false}
            readOnly={readOnly}
            placeholder={placeholder}
            autoComplete="new-password"
            value={value}
            onChange={onChange}
        />
    );
};

const UploadInput = ({ uploadType, onUploadTypeChange, renderInput, fileType, fileChange }) => {
    return (
        <div>
            <div style={{ marginBottom: 8 }}>
                <Radio.Group name="uploadType" value={uploadType} onChange={onUploadTypeChange}>
                    <Radio value={0}>自动</Radio>
                    <Radio value={1}>手动</Radio>
                </Radio.Group>
            </div>
            <div className={fileType ? 'h-input-suffix' : 'h-input-input'}>
                {renderInput}
                {!Boolean(uploadType) && <HUploadFile fileType={fileType} onChange={url => fileChange(url)} />}
            </div>
        </div>
    );
};

const GroupInput = ({ width, value, onChange, placeholder, suffix }) => {
    return (
        <Group compact>
            <InputNumber style={{ width }} placeholder={placeholder} size="small" value={value} onChange={value => onChange(value)} />
            {suffix}
        </Group>
    );
};
//
// export const HInput = ({
//                            label, value, onChange, password, readOnly = false,
//                            placeholder = "", suffix = null,
//                            rule,
//                        }: HInputProps) => {
//     const [error, setError] = useState(false);
//     const [inputValue, setInputValue] = useState("");
//     const [inputRule, setRule] = useState({required: false, validation: false, message: ""});
//     useEffect(() => {
//         setInputValue(value);
//     }, [value]);
//     useEffect(() => {
//         if (rule) {
//             setRule((old) => {
//                 old.required = rule.required;
//                 old.message = rule.message;
//                 return {...old}
//             });
//             if (rule.validation !== inputRule.validation) {
//                 validator(inputValue);
//                 setRule((old) => {
//                     old.validation = rule.validation;
//                     return {...old}
//                 });
//             }
//         }
//     }, [rule]);
//
//     const validator = (value) => {
//         if (rule && rule.required && !value) {
//             setError(true)
//         } else {
//             setError(false)
//         }
//     };
//
//     const inputChange = (e) => {
//         setInputValue(e.target.value);
//         validator(e.target.value);
//         onChange && onChange(e)
//     };
//
//
//     return <HLabelInput label={label} required={inputRule.required} className={error ? "h-item-has-error" : ""}>
//
//         {password ?
//             <Input.Password size="small" autoFocus={false} readOnly={readOnly}
//                             placeholder={error ? inputRule.message : placeholder} autoComplete="new-password"
//                             value={inputValue}
//                             onChange={inputChange}/>
//             :
//             suffix ? <div className={"h-input-suffix"}>
//                     <Input size="small" readOnly={readOnly} placeholder={error ? inputRule.message : placeholder}
//                            value={inputValue}
//                            onChange={inputChange}
//                            onBlur={() => validator(inputValue)}/>
//                     {suffix}
//                 </div> :
//                 <Input size="small" readOnly={readOnly} placeholder={error ? inputRule.message : placeholder}
//                        value={inputValue}
//                        onChange={inputChange}
//                        onBlur={() => validator(inputValue)}/>
//
//         }
//     </HLabelInput>
// };
