import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Input, message } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import axios from 'axios';
import { UserService } from '../../services/user';

const FormItem = Form.Item;

const LoginForm = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const onFinish = (values: any) => {
        UserService.login(values).then(r => {
            if (r.data) {
                const { userInfo, access_token } = r.data;
                sessionStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem('token', access_token);
                axios.defaults.headers.common['Authorization'] = 'bearer ' + access_token;
                if (userInfo.type === 2) {
                    history.push('/leadership');
                } else if (userInfo.type === 4) {
                    history.push('/log');
                } else {
                    history.push('/project');
                }
            }
        });
    };

    return (
        <Form name="login" initialValues={{ remember: true }} onFinish={onFinish}>
            <FormItem name={'account'} rules={[{ required: true, message: '请输入账号' }]}>
                <Input prefix={<UserOutlined className="h-icon" />} placeholder="账号" />
            </FormItem>
            <FormItem name="password" rules={[{ required: true, message: '请输入密码' }]}>
                <Input prefix={<KeyOutlined className="h-icon" />} type="password" placeholder="密码" />
            </FormItem>
            <Button style={{ display: 'none' }} type="primary" htmlType="submit">
                Submit
            </Button>
        </Form>
    );
};
export default LoginForm;
