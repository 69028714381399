import { combineReducers } from 'redux-immutable';
import { reducer as project } from '../../page/project/store';
import { reducer as setup } from '../../page/setup/store';
import { reducer as upload } from '../../page/upload/store';
import { reducer as monitor } from '../../page/monitor/store';

const reducer = combineReducers({
    project,
    setup,
    upload,
    monitor
});

export default reducer;
