import './ThreeMap.css';
import React from 'react';
import * as THREE from 'three';
import Orbitcontrols from 'three-orbitcontrols';
import WebGL from './WebGL';
/**
 * @description 360全景组件
 * 可对组件进行扩展
 * props包含三个对象，每个对象对应相应函数所需参数
 * 对象和对象内部的属性都是可选，不传参数则调用默认参数
 * default props是传参示例
 * 对象内参数名称与three官方文档基本一致，可以参考
 */
interface Props {
    camera?: {
        fov?: number; //角度
        neer?: number;
        far?: number;
        x?: number; //设置相机的朝向
        y?: number;
        z?: number;
    };
    control?: {
        autoRotate?: boolean; //是否自转
        minDistance?: number; //镜头最近距离
        maxDistance?: number; //最远距离
        dampingFactor?: number;
    };
    model?: {
        radius?: number; //球形半径
        picurl?: any; //背景图路径，详见default props使用
        isfront?: boolean; //球形是否是正面，true表示正面朝外，类似于地球仪，false表示图片向内，可用于室内效果
    };
}

class ThreeMap extends React.Component<Props> {
    private static defaultProps = {
        camera: {
            fov: 45,
            neer: 1,
            far: 10000,
            x: 100,
            y: 0,
            z: 0
        },
        control: {
            autoRotate: false,
            minDistance: 300,
            maxDistance: 700,
            dampingFactor: 0.1
        },
        model: {
            radius: 500,
            picurl: require('./examplepic.jpeg'), //传参案例
            isfront: false
        }
    };
    scene: any;
    camera: any;
    controls: any;
    renderer: any;
    group: any;
    frameId: any;
    constructor(props: Props) {
        super(props);
    }
    componentDidMount() {
        const data = this.initThree();
        //将初始化后的数据绑定到类上
        this.scene = data.scene;
        this.camera = data.camera;
        this.renderer = data.renderer;
        this.controls = data.controls;
        this.group = data.group;

        if (WebGL.isWebGLAvailable()) {
            this.start();
        } else {
            const warning = WebGL.getWebGLErrorMessage();
            document.getElementById('WebGL-output').appendChild(warning);
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.model.picurl !== this.props.model.picurl) {
            this.group.children[0].material.map = new THREE.TextureLoader().load(this.props.model.picurl);
        }
    }

    componentWillUnmount() {
        //清除内存
        this.clearGroup(this.group);
        this.clearScene();
    }

    //初始化方法入口
    initThree = () => {
        let camera, scene, renderer, group, orbitControls, mesh;
        const container = document.getElementById('WebGL-output');
        const props = this.props;
        const width = container.clientWidth,
            height = container.clientHeight;
        //对数据进行更新
        const cameraData = { ...ThreeMap.defaultProps.camera, ...props.camera };
        const modelData = { ...ThreeMap.defaultProps.model, ...props.model };
        const controlData = { ...ThreeMap.defaultProps.control, ...props.control };

        initRender();
        initCamera(cameraData);
        initScene();
        initGroup();
        initModel(modelData);
        initControls(controlData);
        // initLight();

        function initScene() {
            scene = new THREE.Scene(); //创建场景
        }

        function initGroup() {
            group = new THREE.Group();
            scene.add(group);
        }

        function initCamera(data) {
            //创建相机
            camera = new THREE.PerspectiveCamera(data.fov, width / height, data.neer, data.far);
            camera.position.set(data.x, data.y, data.z);
        }

        function initControls(data) {
            //控制地球
            orbitControls = new Orbitcontrols(camera, renderer.domElement); //相机作为Orbitcontrols的参数，支持鼠标交互
            orbitControls.autoRotate = data.autoRotate;
            orbitControls.minDistance = data.minDistance;
            orbitControls.maxDistance = data.maxDistance;
            orbitControls.dampingFactor = data.dampingFactor;
        }

        // function initLight() {
        //     //光源
        //     let ambi = new THREE.AmbientLight(0x686868);//环境光
        //         scene.add(ambi);
        //     let spotLight = new THREE.DirectionalLight(0xffffff);//点光源
        //       	spotLight.position.set(550, 100, 550);
        //       	spotLight.intensity = 0.6;
        //       	scene.add(spotLight);
        // }

        function initModel(data) {
            // 创建模型和材质
            const geometry = new THREE.SphereBufferGeometry(data.radius, 60, 60);
            if (!data.isfront) {
                geometry.scale(-1, 1, 1);
            }
            const material = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(data.picurl)
            });
            mesh = new THREE.Mesh(geometry, material);
            group.add(mesh);
        }

        function initRender() {
            //渲染
            renderer = new THREE.WebGLRenderer();
            renderer.setPixelRatio(window.devicePixelRatio); //设置设备像素比
            renderer.setSize(width, height);
            container.appendChild(renderer.domElement);
        }
        return { camera: camera, scene: scene, controls: orbitControls, renderer: renderer, group: group };
    };

    // 更新状态
    animate = () => {
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
        this.frameId = requestAnimationFrame(this.animate);
    };

    // 渲染
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    };

    //卸载组件
    clearScene = () => {
        cancelAnimationFrame(this.frameId);
        this.scene.traverse(child => {
            if (child.material) {
                child.material.dispose();
            }
            if (child.geometry) {
                child.geometry.dispose();
            }
            child = null;
        });
        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.scene.clear();
        this.scene = null;
        this.camera = null;
        this.controls = null;
        this.renderer.domElement = null;
        this.renderer = null;
    };

    clearGroup = group => {
        const clearCache = item => {
            item.geometry.dispose();
            item.material.dispose();
        };
        const removeObj = obj => {
            let arr = obj.children.filter(x => x);
            arr.forEach(item => {
                if (item.children.length) {
                    removeObj(item);
                } else {
                    clearCache(item);
                    item.clear();
                }
            });
            obj.clear();
            arr = null;
        };
        removeObj(group);
    };
    //要渲染的虚拟DOM设定好
    render() {
        return <div id="WebGL-output" />;
    }
}

export default ThreeMap;
