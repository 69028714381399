/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDescriptions, HDrawer, HIcon, HInfoLine, HSingleBox, TableLine } from '../../../components';
import { DataTypeList } from '../../../utils/static-data';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { GetListParam } from '../store/model';
import FormContent from './form';
import { SedimentService } from '../../../services/sediment';

interface Props {
    dataSource: any[];
    getList: (data: GetListParam) => void;
};

interface State {
    activeButton: number;                       //选中的按钮
    activeId: number;                           //选中内容的id
    activeData: unknown;                        //选中的内容
    isAdd: boolean;                             //上传弹窗
    isEdit: boolean;                            //修改弹窗
    editData: unknown;                          //要修改的数据
};

class index extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: 0,
            activeId: 0,
            activeData: undefined,
            isAdd: false,
            isEdit: false,
            editData: undefined
        };
    }

    componentDidMount(): void {
        //获取列表
        this.props.getList({ current: 1 });
    }

    /**
     * @description             新增
     */

    onAdd = () => {
        this.setState({
            isAdd: true,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
        });
    }

    /**
     * @description             列表选中
     * @param value             选中的数据    
     */

    lineActive = (value) => {
        SedimentService.getInfoSectionById(value.id).then(res => {
            this.setState({
                activeId: value.id,
                activeData: res.data
            });
        });
    }

    /**
     * @description             列表删除
     * @param id                列表id
     */

    lineDelete = (id) => {
        SedimentService.deleteFlushSilt(id).then(res => {
            this.props.getList({ current: 1 });
        });
    }

    /**
     * @description             数据列表删除
     */

    dataDelete = (value) => {
        const { activeId } = this.state;
        if (value.type === "tif") {
            SedimentService.deleteTif(value.id).then(res => {
                this.lineActive({ id: activeId });
                this.props.getList({ current: 1 });
            });
        } else {
            SedimentService.deleteDxf(value.id).then(res => {
                this.lineActive({ id: activeId });
                this.props.getList({ current: 1 });
            });
        }

    }



    /**
     * @description             弹窗关闭             
     */

    closeDrawer = () => {
        this.setState({
            isAdd: false,
            isEdit: false,
            activeButton: 0,
            editData: undefined
        });
    }

    render() {
        const { dataSource } = this.props;
        const { activeData, activeButton, activeId, isAdd, isEdit, editData } = this.state;
        return (
            <>
                <HContentLeft>
                    <div className='sediment__left__head'>
                        <HIcon iconName='icon-upload' title={'上传'} onClick={this.onAdd} />
                    </div>
                    <InfiniteScroll initialLoad={false} pageStart={1} >
                        {
                            dataSource.map((r, i) => {
                                return <TableLine
                                    key={r.id}
                                    data={r}
                                    imgUrl={r.picUrl}
                                    showDate={true}
                                    buttonList={[
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (e, key) => {
                                                e.stopPropagation();
                                                this.setState({
                                                    isEdit: true,
                                                    editData: r,
                                                    activeId: r.id,
                                                    activeButton: key,
                                                });
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.lineDelete(r.id)}
                                    onClick={() => this.lineActive(r)}
                                />;
                            })
                        }
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {
                        activeData && <div>
                            <HDescriptions title={activeData['projectName']}>
                                <HInfoLine title={'项目编号'} value={activeData['projectCode']} />
                                <HInfoLine title={'项目名称'} value={activeData['projectName']} />
                                <HInfoLine title={'项目阶段'} value={activeData['stage']} />
                                <HInfoLine title={'专业'} value={activeData['major']} />
                                <HInfoLine title={'坐标系'} value={activeData['coordinate']} />
                                <HInfoLine title={'上传人'} value={activeData['uploader']} />
                            </HDescriptions>
                            <HDescriptions title={'数据列表'}>
                                {
                                    activeData['fileInfo'].length > 0 && activeData['fileInfo'].map((item, i) => {
                                        return <HInfoLine
                                            key={i}
                                            title={item.name}
                                            value={`类型:${item.type}`}
                                            titleStyle={{ minWidth: "200px" }}
                                            suffixWidth={69}
                                            suffixBgColor={'#F3604A'}
                                            suffix={<div style={{ width: '60px', color: "#fff", textAlign: "center" }} onClick={() => this.dataDelete(item)}>删除</div>}
                                        />;
                                    })
                                }
                            </HDescriptions>
                        </div>
                    }
                </HContentRight>
                {
                    isAdd && <HDrawer
                        iconName={'icon-upload'}
                        title={'上传'}
                        shutDown={this.closeDrawer}
                    >
                        <FormContent
                            isAdd={true}
                            onSave={() => {
                                this.closeDrawer();
                                this.props.getList({ current: 1 });
                            }}
                        />
                    </HDrawer>
                }
                {
                    isEdit && <HDrawer
                        iconName={'icon-edit'}
                        title={'编辑'}
                        shutDown={this.closeDrawer}
                    >
                        <FormContent
                            isAdd={false}
                            editData={editData}
                            onSave={() => {
                                this.closeDrawer();
                                this.props.getList({ current: 1 });
                            }}
                        />
                    </HDrawer>
                }
            </>
        );
    }
}

const mapState = (state) => ({
    dataSource: state.getIn(['upload', 'dataSource']),
});

const mapDispatch = (dispatch) => ({
    getList({ key, current, data }) {
        dispatch(
            actionCreators.getPageList({
                url: '/flush_silt/query',
                key,
                current,
                data,
            })
        );
    },
});

export default connect(mapState, mapDispatch)(index);