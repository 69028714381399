/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';
import HAMap, { mapCenter } from '../../utils/amap';
import poiMarker from '../../assets/poi-marker-default.png';
import { message } from 'antd';

interface Props {
    onChange: (data) => void;
}

class Index extends Component<Props, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        this.init();
    }

    static marker = null;
    init = (position?) => {
        const havePosition = position && position.length > 0;
        HAMap.init({
            id: 'get-plan-center-map',
            center: havePosition ? position : mapCenter
        });
        if (havePosition) {
            Index.marker = HAMap.addCenterMarker(poiMarker, position);
        }
        HAMap.map.on('click', e => {
            if (Index.marker) {
                Index.marker.setMap(null);
                Index.marker = null;
            }
            Index.marker = HAMap.addCenterMarker(poiMarker, [e.lnglat.getLng(), e.lnglat.getLat()]);
            this.regeoCode(e.lnglat.getLng(), e.lnglat.getLat());
        });
    };

    regeoCode = (lng, lat) => {
        const geocoder = new window['AMap'].Geocoder({
            radius: 1000 //范围，默认：500
        });
        const pos = new window['AMap'].LngLat(lng, lat);
        geocoder.getAddress(pos, (status, result) => {
            if (status === 'complete' && result.regeocode) {
                const address = result.regeocode.formattedAddress;
                const data = { position: [lng, lat], address };
                this.props.onChange(data);
            } else {
                message.error('根据经纬度查询地址失败');
                const data = { position: [lng, lat], address: '' };
                this.props.onChange(data);
            }
        });
    };

    render() {
        return <div className={'h-plan-map'} id={'get-plan-center-map'} />;
    }
}

export default Index;
