/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: 描述页面内容
 * @Date: 2022-05-13 11:53:17
 * @LastEditTime: 2022-05-15 11:05:46
 */
import { message } from 'antd';
import React, { useState, useEffect } from 'react';

import { MonitorService } from '../../services/monitor';
import LineListItem from './LineListItem';
import to84 from '../../utils/3857to84';

const LineListBox = ({ props }: any) => {
    const { cadId, layName, setValue, setLineInfo, setEditInfo, uploadFlag, setAddLineData, type } = props;

    const [lineListState, setLineState] = useState([]);
    const [activeLineItem, setActiveLineItem] = useState();

    const handleClickLineItem = data => {
        setActiveLineItem(data);
        MonitorService.handleGetLineInfo(data.id)
            .then((res: any) => {
                if (res.code !== 200) {
                    message.error('获取管线信息失败');
                } else {
                    const { data } = res;

                    const corrds = to84(data.geom.coordinates[0], data.geom.coordinates[1]);

                    const infoData =
                        type === 'line'
                            ? [
                                { title: '名称', value: data.name ? data.name : '' },
                                { title: '流向', value: data.flow ? '负向' : '正向' },
                                { title: '线宽', value: data.lineWidth ? data.lineWidth : '' },
                                { title: '流速', value: data.speed ? data.speed : '' },
                                { title: '是否开启动效', value: data.isDynamic ? '开启' : '关闭' },
                                { title: '静态颜色', value: data.staticColor ? data.staticColor : '' },
                                { title: '动态颜色', value: data.flowColor ? data.flowColor : '' }
                            ]
                            : [
                                { title: '名称', value: data.name ? data.name : '' },
                                { title: '经度', value: corrds.lon ? corrds.lon : '' },
                                { title: '纬度', value: corrds.lat ? corrds.lat : '' }
                            ];

                    const editData =
                        type === 'line'
                            ? [
                                { title: '名称', type: 'input', value: data.name ? data.name : '' },
                                { title: '流向', type: 'radio', value: data.flow ? data.flow : '', option: ['正向', '负向'] },
                                { title: '线宽', type: 'input', value: data.lineWidth ? data.lineWidth : '' },
                                { title: '流速', type: 'input', value: data.speed ? data.speed : '' },
                                { title: '是否开启动效', type: 'radio', value: data.isDynamic ? data.isDynamic : '', option: ['否', '是'] },
                                { title: '静态颜色', type: 'input', value: data.staticColor ? data.staticColor : '' },
                                { title: '动态颜色', type: 'input', value: data.flowColor ? data.flowColor : '' }
                            ]
                            : [
                                { title: '名称', type: 'input', value: data.name ? data.name : '' },
                                { title: '经度', type: 'input', value: corrds.lon ? corrds.lon : '' },
                                { title: '纬度', type: 'input', value: corrds.lat ? corrds.lat : '' }
                            ];

                    setLineInfo({
                        id: data.id,
                        data: infoData
                    });
                    setEditInfo(false, 'init', data.id, editData);
                }
            })
            .catch(err => {
                message.error('获取管线信息失败');
            });
    };

    const handleEidtLineItem = data => {
        // console.log('编辑');
    };

    const handleDelLineItem = id => {
        // console.log(id);
    };

    useEffect(() => {
        MonitorService.handleGetLineList(cadId, layName)
            .then((res: any) => {
                if (res.code !== 200) {
                    message.error('获取管线数据失败');
                    setValue('', '');
                } else {
                    // console.log(res.data);
                    setValue('layer', layName);
                    setLineState(res.data);
                    setAddLineData(res.data);
                }
            })
            .catch(e => {
                message.error('获取管线数据失败');
                setValue('', '');
            });
        type === 'line'
            ? MonitorService.handleGetLineList(cadId, layName)
                .then((res: any) => {
                    if (res.code !== 200) {
                        message.error('获取管线数据失败');
                        setValue('', '');
                    } else {
                        // console.log(res.data);
                        setValue('layer', layName);
                        setValue('layer', layName);
                        setLineState(res.data);
                    }
                })
                .catch(e => {
                    message.error('获取管线数据失败');
                    setValue('', '');
                })
            : MonitorService.handleGetPointList(cadId, layName)
                .then((res: any) => {
                    if (res.code !== 200) {
                        message.error('获取管井数据失败');
                    } else {
                        //   console.log(res);
                        setValue('layer', layName);
                        setLineState(res.data);
                    }
                })
                .catch(err => {
                    message.error('获取管井信息失败');
                });
    }, [uploadFlag]);

    return (
        <>
            {lineListState.map(r => (
                <LineListItem
                    key={r.id}
                    props={{
                        data: r,
                        activeLineItem,
                        handleClickLineItem,
                        handleEidtLineItem: setEditInfo,
                        handleDelLineItem,
                        type
                    }}
                />
            ))}
        </>
    );
};

export default LineListBox;
