import axios from 'axios';

export class UserService {
    static getAllData() {
        return axios.get('/user/query_all').then(res => res.data);
    }

    static login(data) {
        return axios.post('/user/login', data);
    }

    static getListByType(type) {
        return axios.get('/user/list_by_type', {
            params: {
                type
            }
        });
    }

    static del(id) {
        return axios.delete('/user/delete/' + id);
    }

    static edit(data) {
        return axios.put('/user/edit', data);
    }

    static getFunctionList() {
        return axios.get('/user/list_function').then(r => r.data);
    }
}
