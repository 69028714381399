/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: cesium 工具
 * @Date: 2022-05-05 15:33:03
 * @LastEditTime: 2022-05-14 12:51:00
 */


//84坐标转墨卡托投影坐标
export function wgs84mercatorto(L, B, degree) {
    const xy = [0, 0];
    const a = 6378137.0;
    const b = 6356752.314245179;
    const e = 0.081819190842621;
    const eC = 0.0820944379496957;
    let L0 = 0.0;

    let n;
    if (degree == 6.0) {
        n = Math.round((L + degree / 2.0) / degree);
        L0 = degree * n - degree / 2.0;
    } else {
        n = Math.round(L / degree);
        L0 = degree * n;
    }

    const radB = B * 3.141592653589793 / 180;
    const radL = L * 3.141592653589793 / 180;
    const deltaL = (L - L0) * 3.141592653589793 / 180;
    const N = a * a / b / Math.sqrt(1 + eC * eC * Math.cos(radB) * Math.cos(radB));
    const C1 = 1.0 + 0.75 * e * e + 0.703125 * Math.pow(e, 4.0) + 0.68359375 * Math.pow(e, 6.0) + 0.67291259765625 * Math.pow(e, 8.0);
    const C2 = 0.75 * e * e + 0.9375 * Math.pow(e, 4.0) + 1.025390625 * Math.pow(e, 6.0) + 1.07666015625 * Math.pow(e, 8.0);
    const C3 = 0.234375 * Math.pow(e, 4.0) + 0.41015625 * Math.pow(e, 6.0) + 0.538330078125 * Math.pow(e, 8.0);
    const C4 = 0.068359375 * Math.pow(e, 6.0) + 0.15380859375 * Math.pow(e, 8.0);
    const C5 = 0.00240325927734375 * Math.pow(e, 8.0);
    const t = Math.tan(radB);
    const eta = eC * Math.cos(radB);
    const X = a * (1 - e * e) * (C1 * radB - C2 * Math.sin(2 * radB) / 2 + C3 * Math.sin(4 * radB) / 4 - C4 * Math.sin(6 * radB) / 6 + C5 * Math.sin(8 * radB));
    xy[0] = X + N * Math.sin(radB) * Math.cos(radB) * Math.pow(deltaL, 2.0) * (1 + Math.pow(deltaL * Math.cos(radB), 2.0) * (5 - t * t + 9 * eta * eta + 4 * Math.pow(eta, 4.0)) / 12 + Math.pow(deltaL * Math.cos(radB), 4.0) * (61 - 58 * t * t + Math.pow(t, 4.0)) / 360) / 2;
    xy[1] = N * deltaL * Math.cos(radB) * (1 + Math.pow(deltaL * Math.cos(radB), 2.0) * (1 - t * t + eta * eta) / 6 + Math.pow(deltaL * Math.cos(radB), 4.0) * (5 - 18 * t * t + Math.pow(t, 4.0) - 14 * eta * eta - 58 * eta * eta * t * t) / 120) + 500000;

    return xy;

}

//墨卡托投影转84坐标
export function mercator2towgs84(e) {
    const X: any = e[0];
    const Y: any = e[1];
    const L0: any = 1;
    const lon = X / 20037508.34 * 180;
    let lat = Y / 20037508.34 * 180;
    lat = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180)) - Math.PI / 2);

    return [lon, lat];
}

