import React, { Component } from 'react';
import { Button, Cascader, Input, message, Select, Tooltip } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import axios from 'axios';
import { SourceType, UploadDataType } from '../../../utils/static-data';
import { PanoramaModel } from '../store/model';
import HUploadThumbnail from '../../../components/upload';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { GroupModel } from '../../../components/model';
import { sizeChange } from '../../../utils/common';
import HAMap, { mapCenter } from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';
import { EnvironmentFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

interface Props {
    isAdd?: boolean;
    isEdit?: boolean;
    editData?: any;
    groupList: GroupModel[];
    normalUserList: SourceType[];
    allProjectList: SourceType[];
    addGroup: (name) => void; //新增分组
    updateGroupChange: (data) => void; //更新分组
    onDeleteGroup: (id) => void; //删除分组
    getNormalUserList: () => void;
    getAllProjectList: () => void;
    onSuccess: () => void;
    onCancel: () => void;
}

interface States {
    id?: number;
    formData: PanoramaModel;
    validation: boolean;
    sizeUnit: string;
    isCustomUpload: boolean;
    position: any;
    inputAddress: string; //输入的地址
}

class HPanoramaForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                dataFormat: 'a3x',
                fileUrl: '',
                groupId: undefined, //暂时
                isPublic: 1,
                name: '',
                principal: '',
                projectCode: undefined,
                unit: '',
                size: 0
            },
            validation: false,
            sizeUnit: 'MB',
            isCustomUpload: true, //大小是否自动
            position: '',
            inputAddress: ''
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList(); //获取所有项目
        this.props.getNormalUserList(); //获取所有负责人
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (
            this.props.normalUserList !== prevProps.normalUserList ||
            this.props.allProjectList !== prevProps.allProjectList ||
            this.props.editData !== prevProps.editData
        ) {
            this.setData();
        }
    }

    /**
     * @description 初始化表单地图
     */
    static marker = null;
    init = (position?) => {
        const havePosition = position && position.length > 0;
        HAMap.init({
            id: 'get-plan-center-map',
            center: havePosition ? position : mapCenter
        });
        if (havePosition) {
            HPanoramaForm.marker = HAMap.addCenterMarker(poiMarker, position);
        }
        HAMap.map.on('click', e => {
            if (HPanoramaForm.marker) {
                HPanoramaForm.marker.setMap(null);
                HPanoramaForm.marker = null;
            }
            HPanoramaForm.marker = HAMap.addCenterMarker(poiMarker, [e.lnglat.getLng(), e.lnglat.getLat()]);

            this.setState({
                position: e.lnglat.getLng() + ',' + e.lnglat.getLat() //这个函数不知道啥
            });
        });
    };

    setData = () => {
        //这里要读取经纬度
        const { editData, isAdd } = this.props;
        if (!isAdd && editData) {
            const { formData } = this.state;
            const { latitude, longitude } = this.props.editData;
            const pos = [longitude, latitude];
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
            });
            formData['id'] = editData.id;
            this.setState({
                formData,
                position: pos ? pos.join(',') : '121.456424, 31.234892'
            });
            this.init(pos); //输入经纬度
        } else {
            this.init();
        }
    };
    //数据改变
    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };
    //改变大小
    sizeUnitChange = unit => {
        const { formData } = this.state;
        formData.size = 0;
        this.setState({
            sizeUnit: unit,
            formData
        });
    };
    //不清楚
    onUploadTypeChange = type => {
        const { formData } = this.state;
        if (!formData.id) {
            formData.fileUrl = '';
        }
        this.setState({
            isCustomUpload: !Boolean(type),
            formData,
            validation: false
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData, isCustomUpload, sizeUnit, position } = this.state;
                const pos = position.split(',').map(i => Number(i));
                formData.longitude = pos[0];
                formData.latitude = pos[1];

                if (formData.projectCode && formData.fileUrl && formData.groupId) {
                    if (isCustomUpload) {
                        delete formData.size;
                    } else if (!isCustomUpload && !formData.id) {
                        formData.size = sizeChange(formData.size, sizeUnit);
                    }

                    axios.put('/panorama/edit', formData).then(res => {
                        if (res['code'] === 200) {
                            message.success(`${formData.id ? '修改' : '添加'}成功`);
                            this.props.onSuccess();
                        }
                    });
                }
            }
        );
    };

    render() {
        const { isAdd, allProjectList, normalUserList, addGroup, groupList, updateGroupChange, onDeleteGroup } = this.props;
        const {
            formData: { fileUrl, name, projectCode, principal, unit, groupId, isPublic },
            validation,
            position,
            inputAddress
        } = this.state;
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput label={'单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                <HSelect label={'负责人'} value={principal} dataSource={normalUserList} onChange={selected => this.onChange('principal', selected)} />
                <HLabelInput label={'文件'}>
                    <HUploadThumbnail type={UploadDataType.pic} defaultUrl={fileUrl} onChange={url => this.onChange('fileUrl', url)} />
                </HLabelInput>
                <HLabelInput label={'坐标信息'}>
                    <Input
                        size="small"
                        value={position}
                        onChange={e => {
                            this.setState({
                                position: e.target.value
                            });
                        }}
                    ></Input>
                    <Tooltip title="定位">
                        <Button
                            onClick={() => {
                                const pos = position.split(',').map(Number);
                                this.init(pos); //init 里面的position应该是数组
                            }}
                            icon={<EnvironmentFilled />}
                        />
                    </Tooltip>
                </HLabelInput>
                <HLabelInput label={'地图搜索'}>
                    <Input
                        size="small"
                        value={inputAddress}
                        onChange={e => {
                            this.setState({ inputAddress: e.target.value });
                        }}
                    ></Input>
                    <Tooltip title="搜索">
                        <Button
                            onClick={() => {
                                const getGeoCode = HAMap.geoCoder();
                                const geocoder = new getGeoCode.Geocoder({
                                    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                                    // city: '010'
                                });
                                // eslint-disable-next-line @typescript-eslint/no-this-alias
                                const self = this;

                                geocoder.getLocation(inputAddress, function (status, result) {
                                    if (status === 'complete' && result.info === 'OK') {
                                        const lnglat = result.geocodes[0].location; //这个就是经纬度
                                        //好像这里失败了,可能是this指针丢失
                                        self.setState({
                                            position: lnglat.lng + ',' + lnglat.lat
                                        });
                                        // console.log(position);
                                        const pos = position.split(',').map(Number);
                                        self.init([lnglat.lng, lnglat.lat]);
                                    } else {
                                        message.error('根据地址查询位置失败');
                                    }
                                });
                            }}
                            icon={<SearchOutlined />}
                        />
                    </Tooltip>
                </HLabelInput>
                <div className={'h-plan-map'} id={'get-plan-center-map'} />
                {isPublic === 1 ? (
                    <HLabelInput label={'分组'} required={true}>
                        <HAddItemSelect
                            dataSource={groupList}
                            add={addGroup}
                            value={groupId}
                            isForm={true}
                            rule={{ required: true, validation, message: '请选择分组' }}
                            onChange={value => this.onChange('groupId', value)}
                            maxWidth={212}
                            minWidth={212}
                            saveChange={updateGroupChange}
                            onDelete={onDeleteGroup}
                        />
                    </HLabelInput>
                ) : null}
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                    <HButton onClick={this.props.onCancel}>取消</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    normalUserList: state.getIn(['upload', 'normalUserList']),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getNormalUserList() {
        dispatch(actionCreators.getNormalUserList());
    },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(HPanoramaForm);
