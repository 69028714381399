import axios from 'axios';
import { message } from 'antd';

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
}
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.interceptors.response.use(
    res => {
        if (res.data.code && res.data.code !== 200) {
            message.error(res.data.msg);
        }
        return res.data;
    },
    err => {
        window.location.href = '/';
        return Promise.reject(err);
    }
);
