import React, { Component, ReactNode } from 'react';
import './index.scss';
import { HIcon } from '../simple';

interface Props {
    title: string | number | ReactNode;
    titleStyle?: any;
    value: string | number | ReactNode;
    suffix?: string | number | ReactNode; //展开的内容
    suffixWidth?: number; //展开时文本的宽度
    fontWhite?: boolean;
    // select?: boolean;
    share?: boolean; //点击切换不同权分享有效时长
    edit?: boolean; //是否可编辑
    image?: boolean;
    suffixBgColor?: string;
    onChange?: (value: number) => void;
}

interface States {
    showSuffix: boolean;
}

class HInfoLine extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            showSuffix: false
        };
    }

    changeWidth = () => {
        const { value, onChange } = this.props;
        let newValue = 1;
        switch (value) {
            case 1:
                newValue = 7;
                break;
            case 7:
                newValue = 0;
                break;
            case 0:
                newValue = 1;
                break;
        }
        onChange(newValue);
    };

    getText = () => {
        const { value } = this.props;
        switch (value) {
            case 1:
                return '1日';
            case 7:
                return '7日';
            case 0:
                return '永久';
        }
    };

    getWidth = () => {
        const { value } = this.props;
        switch (value) {
            case 1:
                return 1;
            case 7:
                return 2;
            case 0:
                return 3;
        }
    };

    showSuffix = () => {
        const { showSuffix } = this.state;
        this.setState({
            showSuffix: !showSuffix
        });
    };

    render() {
        const { showSuffix } = this.state;
        const { title, titleStyle, value, suffix, suffixWidth, suffixBgColor, fontWhite, share, edit, image } = this.props;
        return (
            <div style={{ backgroundColor: suffixBgColor || 'unset' }} className={'h-info-line'}>
                <div className={'h-info-line-title'} style={titleStyle}>
                    {title}
                </div>
                <div className={'h-info-line-content' + (fontWhite ? ' h-white' : '')}>
                    {share ? (
                        <div className={'h-info-line-click'} style={{ width: `${this.getWidth() * 33.33}%` }}>
                            <div className={'h-info-line-click-area'} onClick={this.changeWidth} />
                            {this.getText()}
                        </div>
                    ) : edit ? (
                        <div>
                            <div className={'h-info-line-value'} style={{ width: `calc(100% - ${suffixWidth}px)` }}>
                                {image ? <HIcon iconName={'icon-image'} /> : value}
                            </div>
                            <div className={'h-info-line-suffix'}>{suffix}</div>
                        </div>
                    ) : suffix ? (
                        <div>
                            <div
                                className={'h-info-line-value'}
                                onClick={this.showSuffix}
                                style={{
                                    width: showSuffix && suffixWidth ? `calc(100% - ${suffixWidth}px)` : '100%'
                                }}
                            >
                                {value}
                            </div>
                            <div className={'h-info-line-suffix'}>{suffix}</div>
                        </div>
                    ) : (
                        <div className={'h-info-line-default'}>{value}</div>
                    )}
                </div>
                {image && (
                    <div className={'h-info-line-img'}>
                        <img src={value as string} />
                    </div>
                )}
            </div>
        );
    }
}

export default HInfoLine;
