/* eslint-disable react/prop-types */
import React, { ReactNode, useState, useEffect } from 'react';
import './index.scss';
import { Input, Button, Cascader } from 'antd';
import axios from 'axios';
import { DataTypeList, StaticTypeList } from '../../utils/static-data';
import { MapWebServiceKey } from '../../utils/amap';
import { Link } from 'react-router-dom';
import { GroupModel } from '../model';
import { HAddItemSelect, HSelect } from '../index';

/**
 * @description 图标
 */

interface HIconProps {
    iconName: string;
    newClass?: string;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    style?: React.CSSProperties;

    [r: string]: any;
}

export const HIcon = ({ iconName, newClass, ...rest }: HIconProps) => {
    return <i className={`h-icon iconfont ${iconName} ${newClass}`} {...rest} />;
};

/**
 * @description 头部导航菜单
 */

interface HHeaderMenuProps {
    data: any[];
    onChange: () => void;
}

export const HHeaderMenu = ({ data, onChange, ...rest }: HHeaderMenuProps) => {
    const pathname = window.location.pathname;
    const { type } = JSON.parse(sessionStorage.getItem('user'));
    return (
        <div className={'h-header-menu'}>
            {data.map((r, i) => {
                return r.userType.indexOf(type) >= 0 ? (
                    <div key={i} className={r.link === pathname ? 'menu-active' : ''}>
                        <Link to={r.link} onClick={onChange}>
                            <HIcon iconName={r.icon} />
                            <span className={'h-header-menu-name'}>{r.title}</span>
                        </Link>
                    </div>
                ) : null;
            })}
        </div>
    );
};

/**
 * @description 导航
 */

interface HMenuProps {
    data: any[];
    activeKey: number;
    suffix?: any;
    onClick: (value) => void;
}

export const HMenu = ({ data, activeKey, suffix, ...rest }: HMenuProps) => {
    return (
        <div className={'h-menu'}>
            {/*                {*/}
            {/*                    <Tabs size={"small"} tabPosition={"top"} tabBarExtraContent={suffix} onChange={(v) => rest.onClick(Number(v))}>*/}
            {/*                        {data.map((item, index) => {*/}
            {/*                            return <TabPane key={index} tab={item.title}/>*/}
            {/*                        })}*/}
            {/*                    </Tabs>*/}
            {/*}*/}
            <div className={'h-menu-list'}>
                {data.map((r, i) => {
                    return (
                        <div key={i} className={i === activeKey ? 'h-menu-active' : ''} onClick={() => rest.onClick(i)}>
                            {r.title}
                        </div>
                    );
                })}
            </div>
            <div className={'h-menu-suffix'}>{suffix}</div>
        </div>
    );
};

/**
 * @description 右侧栏
 */

interface HDrawerProps {
    iconName: string;
    title: string;
    children: any;
    visible?: boolean;
    shutDown: (value) => void;
}

export const HDrawer = ({ iconName, title, children, visible = true, shutDown }: HDrawerProps) => {
    return (
        <div className={`h-drawer ${visible ? 'h-drawer-active' : 'h-drawer-unActive'}`}>
            <div className={'h-drawer-title'}>
                <div className={'h-drawer-title-left'}>
                    <HIcon iconName={iconName} />
                    <span>{title}</span>
                </div>
                <HIcon iconName="icon-close-circle" newClass="clickable" onClick={() => shutDown(visible)} />
            </div>
            <div className={'h-drawer-content'}>{visible ? children : null}</div>
        </div>
    );
};

/**
 * @description 搜索框
 */

export const HSearch = ({ isUpdate, onChange }) => {
    const [searchVisible, setSearchVisible] = useState(false);
    const inputRef = React.useRef<any>(null);
    useEffect(() => {
        setSearchVisible(false);
    }, [isUpdate]);

    function setVisible() {
        setSearchVisible(!searchVisible);
        if (!searchVisible) {
            inputRef.current.focus();
        }
    }

    return (
        <div className={'h-search'}>
            <div className={'h-search-input'}>
                <Input
                    bordered={false}
                    ref={inputRef}
                    className={`h-input ${searchVisible ? 'h-show' : 'h-hide'}`}
                    size={'small'}
                    onPressEnter={onChange}
                />
            </div>
            <HIcon iconName="icon-search" onClick={setVisible} />
        </div>
    );
};

/**
 * @description 新建按钮
 */
export const HAdd = ({ fill, onClick }) => {
    return (
        <HIcon iconName={fill ? 'icon-file-add-fill' : 'icon-file-add'} newClass={`h-clickable ${fill ? 'h-active-icon' : ''}`} onClick={onClick} />
    );
};

/**
 * @description ContentLayout
 */
export const HContentLayout = ({ left, right }) => {
    return (
        <div className={'h-content-box'}>
            <div className={'h-content-left'}>{left}</div>
            <div className={'h-content-right'}>{right}</div>
        </div>
    );
};

/**
 * @description 折叠面板
 */

interface HCollapseProps {
    title: string;
    children: ReactNode;
}

export const HCollapse = ({ title, children, ...rest }: HCollapseProps) => {
    const [open, setOpen] = useState(true);
    return (
        <div className={'h-collapse'}>
            <div className={'h-collapse-header' + (open ? '' : ' border')} onClick={() => setOpen(!open)}>
                <div className={'h-collapse-header-left'}>
                    <HIcon iconName={'icon-down'} newClass={!open && 'icon-spin'} />
                    <div className="h-collapse-header-title">{title}</div>
                </div>
            </div>
            <div className={`h-collapse-content ${!open && 'h-collapse-content-show'}`}>{children}</div>
        </div>
    );
};

/**
 * @description 描述
 */

interface HDescriptionsProps {
    title: string;
    children: ReactNode;
}

export const HDescriptions = ({ title, children }: HDescriptionsProps) => {
    return (
        <div className={'h-descriptions'}>
            <div className={'h-descriptions-title'}>{title}</div>
            {children}
        </div>
    );
};

/**
 * @description 页面内容样式组件
 */

interface HContentBoxProps {
    children: ReactNode;
}

export const HContentBox = ({ children }: HContentBoxProps) => {
    return <div className={'h-content-box'}>{children}</div>;
};

export const HContentLeft = ({ children }: HContentBoxProps) => {
    return <div className={'h-content-left'}>{children}</div>;
};

export const HContentRight = ({ children }: HContentBoxProps) => {
    return <div className={'h-content-right'}>{children}</div>;
};

/**
 * @description 文本内容
 */

interface HTextBoxProps {
    text: string;
}

export const HTextBox = ({ text }: HTextBoxProps) => {
    return <div className={'h-text-box'}>{text}</div>;
};

export const HCascader = ({ label, value, onChange, placeholder = '请选择', rule }) => {
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(false);
    const [cValue, setValue] = useState([]);
    const [inputRule, setRule] = useState({
        required: false,
        validation: false,
        message: ''
    });
    const [firstClick, setFirstClick] = useState(false);

    useEffect(() => {
        setValue(value);
    }, [value]);

    useEffect(() => {
        if (rule && rule.validation !== inputRule.validation) {
            validator(cValue);
        }
    }, [rule]);

    useEffect(() => {
        if (rule) {
            setRule(old => {
                old.required = rule.required;
                old.message = rule.message;
                return { ...old };
            });
            if (rule.validation !== inputRule.validation) {
                validator(cValue);
                setRule(old => {
                    old.validation = rule.validation;
                    return { ...old };
                });
            }
        }
    }, [rule]);

    function getData(subdistrict, value) {
        //高德web服务api
        const url = `https://restapi.amap.com/v3/config/district?subdistrict=${subdistrict}&key=${MapWebServiceKey}&extensions=base&keywords=${value}`;
        return fetch(url)
            .then(response => response.json())
            .then(r => r.districts[0].districts);
    }

    function onCascaderChange(value, selectedOptions) {
        if (value.length === 4 || value.length === 0) {
            setValue(value);
            validator(value);
            onChange && onChange(value, selectedOptions);
        }
    }

    const validator = value => {
        if (rule.required && value.length === 0) {
            setError(true);
        } else {
            setError(false);
        }
    };

    function loadData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const { subdistrict, name } = targetOption;
        getData(subdistrict, name).then(data => {
            targetOption.loading = false;
            targetOption.children = data.map(it => {
                return {
                    ...it,
                    isLeaf: subdistrict === 4,
                    subdistrict: subdistrict + 1
                };
            });
            setOptions([...options]);
        });
    }

    const onPopupVisibleChange = value => {
        if (value && !firstClick) {
            setFirstClick(value);
            const subdistrict = 1;
            getData(subdistrict, '中国').then(data => {
                const parentList = data.map(it => {
                    return { ...it, isLeaf: false, subdistrict: subdistrict + 1 };
                });
                setOptions(parentList);
            });
        }
    };

    return (
        <HLabelInput label={label} required={inputRule.required} className={error ? 'h-item-has-error' : ''}>
            <Cascader
                size="small"
                style={{ width: '100%' }}
                value={cValue}
                options={options}
                loadData={loadData}
                onChange={onCascaderChange}
                // changeOnSelect
                onPopupVisibleChange={onPopupVisibleChange}
                placeholder={error ? inputRule.message : placeholder}
                fieldNames={{ label: 'name', value: 'name', children: 'children' }}
            />
        </HLabelInput>
    );
};

interface HCheckProps {
    checked: boolean;
    onChange?: (checked) => void;
    className?: string;
    children?: any;
    inner?: boolean;
}

export const HCheck = ({ checked, onChange, className, children, inner = false, ...rest }: HCheckProps) => {
    const [value, setValue] = useState(false);

    useEffect(() => {
        setValue(checked);
    });

    function onClick() {
        setValue(!value);
        onChange && onChange(!value);
    }

    return children ? (
        <div className={className} onClick={onClick}>
            <div {...rest} className={`h-checkbox ${inner ? 'h-checkbox-inner' : value ? 'h-checkbox-checked' : ''}`} />
            {children}
        </div>
    ) : (
        <div {...rest} className={`h-checkbox ${inner ? 'h-checkbox-inner' : value ? 'h-checkbox-checked' : ''}`} onClick={onClick} />
    );
};

/**
 * @description   系统设置下拉框
 */

interface HContentCollapseProps {
    title: string;
    children: ReactNode;
    style: React.CSSProperties;
    suffix?: ReactNode;
}

export const HContentCollapse = ({ title, children, style, suffix }: HContentCollapseProps) => {
    const [open, setOpen] = useState(true);
    return (
        <div className={'h-content-collapse'}>
            <div className={'h-content-collapse-header'} onClick={() => setOpen(!open)}>
                <div className={'header-left'}>
                    <HIcon iconName={'icon-down'} newClass={!open && 'icon-spin'} />
                    <span>{title}</span>
                </div>
                {suffix && <div className={'header-right'}>{suffix}</div>}
            </div>
            <div className={`h-content-collapse-content ${!open && 'h-content-collapse-content-show'} `} style={style}>
                {children}
            </div>
        </div>
    );
};

/**
 * @description 各类数据总览
 */

export const HDataOverview = ({ title, data }) => {
    const { filesCount, filesSize, projectCount, publicCount } = data;
    return (
        <div className={'h-data-overview'}>
            <div className={'h-data-overview-title'}>{title}</div>
            <div>
                <div className={'h-data-overview-count'}>
                    <span>文件{filesCount}份，</span>
                    <span>占用{filesSize}</span>
                </div>

                <div className={'h-flex-between h-data-overview-item'}>
                    <div className={'h-data-overview-label'}>项目</div>
                    <div className={'h-data-overview-value'}>{projectCount}</div>
                </div>
                <div className={'h-flex-between h-data-overview-item'}>
                    <div className={'h-data-overview-label'}>公共</div>
                    <div className={'h-data-overview-value'}>{publicCount}</div>
                </div>
            </div>
        </div>
    );
};

/**
 * @description 单选
 */

export const HSingleBox = ({ data, value, onChange, width, disabledValues = [] }) => {
    function handleChange(value) {
        const isDisabled = disabledValues.findIndex(it => it === value) >= 0;
        if (!isDisabled) {
            onChange(value);
        }
    }

    return (
        <div className={'h-single-box'} style={{ width: `${width}px` }}>
            {data.map((it, i) => {
                return (
                    <div
                        key={i}
                        className={`${it.value === value ? 'h-single-active' : ''}`}
                        style={{ width: `${width / data.length}px` }}
                        onClick={() => handleChange(it.value)}
                    >
                        {it.iconName && <HIcon iconName={it.iconName} newClass={`${it.value === value ? 'active' : ''}`} />}
                        <span>{it.text}</span>
                    </div>
                );
            })}
        </div>
    );
};

export const HButton = ({ children, onClick }) => {
    return (
        <Button type="primary" ghost onClick={onClick}>
            {children}
        </Button>
    );
};

// 带label的输入性组件壳
export const HLabelInput = ({ label, children, required = false, className = '', style = {} }) => {
    return (
        <div className={'h-input' + ' ' + className} style={style}>
            <div className={'h-input-text' + (required ? ' required' : '')}>{label}</div>
            {children}
        </div>
    );
};

interface HDataTopBarProps {
    isPublic: number;
    isStatic?: number;
    onPublicChange: (value) => void;
    onStaticChange?: (value) => void;
    onAdd: () => void;
    children?: any;
    group?: number;
    groupList?: GroupModel[];
    onGroupChange?: (id) => void;
    addGroup?: (name) => void;
    updateGroupChange?: ({ name, id }) => void;
}

export const HDataTopBar = ({
    isPublic,
    isStatic,
    onPublicChange,
    onStaticChange,
    onAdd,
    groupList,
    group,
    onGroupChange,
    addGroup,
    updateGroupChange,
    children
}: HDataTopBarProps) => {
    return (
        <div className={'h-data-top-bar'}>
            <div className={'h-bar-width'}>
                <HSingleBox data={DataTypeList} value={isPublic} onChange={onPublicChange} width={146} />
                {typeof isStatic === 'number' && <HSingleBox data={StaticTypeList} value={isStatic} onChange={onStaticChange} width={146} />}

                {children}
                {/*{isPublic ? <HGroupList dataSource={groupList || []} value={group} onChange={onGroupChange}/> : null}*/}
                {isPublic ? (
                    <HAddItemSelect
                        dataSource={groupList}
                        placeholder={'根据分组筛选'}
                        add={addGroup}
                        onChange={onGroupChange}
                        saveChange={updateGroupChange}
                    />
                ) : null}
            </div>

            {isPublic ? <HIcon iconName={'icon-upload'} title={'上传'} onClick={onAdd} /> : null}
        </div>
    );
};

export const HAvatar = ({ color = '#ffadd2', img = '', text = 'A', onClick }) => {
    return (
        <div className={'h-avatar'} style={{ backgroundColor: color }}>
            {text}
        </div>
    );
};

interface HGroupListProps {
    dataSource: GroupModel[];
    value?: number;
    onChange?: (id) => void;
}

export const HGroupList = ({ dataSource, value = 0, onChange }: HGroupListProps) => {
    const [group, setGroup] = useState(0);
    useEffect(() => {
        setGroup(value);
    }, [value]);

    function onClick(id) {
        const gId = id === group ? 0 : id;
        setGroup(gId);
        onChange && onChange(gId);
    }

    return (
        <div className={'h-group'}>
            {dataSource.map((it, i) => {
                return (
                    <div key={i} className={'h-group-item' + (group === it.id ? ' active' : '')} onClick={() => onClick(it.id)}>
                        {it.name}
                    </div>
                );
            })}
        </div>
    );
};

interface HTabsProps {
    data: any;
    activeKey: number;
    onClick: (value) => void;
}

/**
 * @description                     tabs
 * @param data                      数据
 * @param activeKey                 选中的内容
 * @param onClick                   点击事件
 */

export const HTabs = ({ data, activeKey, onClick }: HTabsProps) => {
    return (
        <div className={'h-tabs'}>
            {data.map((it, i) => {
                return (
                    <div
                        key={i}
                        style={{ width: `${100 / data.length}%` }}
                        className={`${i === activeKey && 'active_tab'}`}
                        onClick={() => onClick(i)}
                    >
                        {it.title}
                    </div>
                );
            })}
        </div>
    );
};
