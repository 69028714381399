/* eslint-disable react/prop-types */
import React, { Component, ReactNode, useEffect, useState } from 'react';
import { HIcon } from '../simple';
import { Select, Divider, Input, Popconfirm } from 'antd';
import './index.scss';

const { Option } = Select;

interface HSelectProps {
    dataSource: any[];
    multiple?: boolean;
    label: string | ReactNode;
    value: number | string | number[];
    onChange?: (selected, options?) => void;
    readOnly?: boolean;
    placeholder?: string;
    rule?: { required: boolean; validation: boolean; message: string };
    validation?: boolean;
}

interface HSelectStates {
    error: boolean;
    sValue: number | string | number[];
}

export default class HSelect extends Component<HSelectProps, HSelectStates> {
    static defaultProps = {
        placeholder: '请选择',
        multiple: false,
        rule: { required: false, validation: false, message: '' }
    };

    // static validation: () => void;

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            sValue: undefined
        };
        // HSelect.validation = () => {
        //     console.log(this.props.rule)
        //     console.log(this.state.sValue)
        // }
    }

    componentDidMount(): void {
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<HSelectProps>, prevState: Readonly<HSelectStates>, snapshot?: any): void {
        if (prevProps.value !== this.props.value) {
            this.setData();
        }
        if (prevProps.rule.validation !== this.props.rule.validation) {
            this.validator();
        }
    }

    setData = () => {
        const { value } = this.props;
        this.setState({
            sValue: value
        });
    };

    validator = () => {
        const { rule } = this.props;
        const { sValue } = this.state;
        if (rule.required && (!sValue || (Array.isArray(sValue) && sValue.length === 0))) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                error: false
            });
        }
    };

    selectChange = (selected, options) => {
        const { onChange } = this.props;
        this.setState(
            {
                sValue: selected
            },
            () => {
                this.validator();
            }
        );

        onChange && onChange(selected, options);
    };

    tagRender = props => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <div className={'h-select-tag'} onMouseDown={onPreventMouseDown} onClick={onClose} style={{ marginRight: 3 }}>
                {label}
                <HIcon iconName={'icon-close'} onClick={onClose} />
            </div>
        );
    };

    render() {
        const { error, sValue } = this.state;
        const { dataSource, label, multiple, placeholder, rule } = this.props;
        return (
            <div className={'h-select' + (error ? ' h-item-has-error' : '')}>
                <span className={'h-select-text' + (rule.required ? ' required' : '')}>{label}</span>
                {multiple ? (
                    <Select
                        size={'small'}
                        mode={'multiple'}
                        style={{ width: 212 }}
                        allowClear
                        value={sValue}
                        placeholder={error ? rule.message : placeholder}
                        onBlur={this.validator}
                        onChange={this.selectChange}
                        tagRender={this.tagRender}
                    >
                        {dataSource.map((it, index) => {
                            return (
                                <Option key={index} value={it.value}>
                                    {it.text}
                                </Option>
                            );
                        })}
                    </Select>
                ) : (
                    <Select
                        size={'small'}
                        style={{ width: 212 }}
                        allowClear
                        value={sValue}
                        placeholder={error ? rule.message : placeholder}
                        onChange={this.selectChange}
                        onBlur={this.validator}
                    >
                        {dataSource.map((it, index) => {
                            return (
                                <Option key={index} disabled={it.disabled} value={it.value}>
                                    {it.text}
                                </Option>
                            );
                        })}
                    </Select>
                )}
            </div>
        );
    }
}

interface HAddItemSelectProps {
    dataSource: any[];
    value?: number | string | number[];
    multiple?: boolean;
    hasLabel?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    maxWidth?: number;
    minWidth?: number;
    dropdownMatchSelectWidth?: boolean;
    onChange?: (selected, options?) => void;
    add: (name) => void;
    rule?: { required: boolean; validation: boolean; message: string };
    validation?: boolean;
    isForm?: boolean;
    saveChange?: (data) => void;
    onDelete?: (id) => void;
}

interface HAddItemSelectStates {
    name: string;
    options: any[];
    error: boolean;
    value: number | string | number[];
}

export class HAddItemSelect extends Component<HAddItemSelectProps, HAddItemSelectStates> {
    static defaultProps = {
        multiple: false,
        hasLabel: false,
        readOnly: false,
        dropdownMatchSelectWidth: false,
        isForm: false,
        maxWidth: 200,
        minWidth: 100,
        placeholder: '请选择',
        rule: { required: false, validation: false, message: '' }
    };

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            name: '',
            value: undefined,
            error: false
        };
    }

    componentDidMount(): void {
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<HAddItemSelectProps>, prevState: Readonly<HAddItemSelectStates>, snapshot?: any): void {
        if (prevProps.dataSource !== this.props.dataSource || prevProps.value !== this.props.value) {
            this.setData();
        }
        if (prevProps.rule.validation !== this.props.rule.validation) {
            this.validator();
        }
    }

    setData = () => {
        const { dataSource, value } = this.props;
        this.setState({
            options: dataSource,
            value
        });
    };

    validator = () => {
        const { rule } = this.props;
        const { value } = this.state;
        if (rule.required && !value) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                error: false
            });
        }
    };

    onNameChange = e => {
        this.setState({
            name: e.target.value
        });
    };

    onChange = (value, option) => {
        this.setState({
            value
        });
        const { onChange } = this.props;
        onChange && onChange(value, option);
    };

    addItem = () => {
        const { add } = this.props;
        const { name } = this.state;
        add && add(name);
        this.setState({
            name: ''
        });
    };

    render() {
        const { placeholder, maxWidth, minWidth, dropdownMatchSelectWidth, isForm, rule, saveChange, onDelete } = this.props;
        const { options, name, value, error } = this.state;
        return (
            <Select
                size="small"
                className={isForm ? (error ? ' h-item-has-error' : '') : 'h-add-select' + (value ? ' active' : '')}
                bordered={isForm}
                allowClear
                optionLabelProp="label"
                style={{ maxWidth: maxWidth, minWidth: minWidth }}
                placeholder={error ? rule.message : placeholder}
                value={value}
                onChange={this.onChange}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                // getPopupContainer={(triggerNode) => triggerNode}
                onBlur={this.validator}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input size={'small'} style={{ flex: 'auto', height: 20, marginRight: 8 }} value={name} onChange={this.onNameChange} />
                            <a style={{ flex: 'none', display: 'block', cursor: 'pointer' }} onClick={this.addItem}>
                                添加
                            </a>
                        </div>
                    </div>
                )}
            >
                {options.map((it, i) => {
                    return (
                        <Option key={i} value={it.id} label={it.name} className={'h-add-select-option'}>
                            {saveChange ? <InputOption data={it} saveChange={saveChange} onDelete={onDelete} /> : it.name}
                        </Option>
                    );
                })}
            </Select>
        );
    }
}

const InputOption = ({ data, saveChange, onDelete }) => {
    const [isEdit, setEdit] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        setName(data.name);
    }, [data]);

    const nameChange = e => {
        e.stopPropagation();
        setName(e.target.value);
    };

    const editToggle = (e, isEdit) => {
        e.stopPropagation();
        setEdit(isEdit);
    };

    const saveNameChange = e => {
        e.stopPropagation();
        saveChange({ ...data, name });
        setEdit(false);
    };

    return (
        <span>
            {isEdit ? (
                <Input
                    size={'small'}
                    style={{ width: 100 }}
                    value={name}
                    autoFocus
                    onClick={e => e.stopPropagation()}
                    onChange={nameChange}
                    onBlur={e => editToggle(e, !isEdit)}
                    onPressEnter={saveNameChange}
                />
            ) : (
                name
            )}
            <HIcon newClass={'action-edit'} iconName={`icon-${isEdit ? 'close' : 'edit'}`} onClick={e => editToggle(e, !isEdit)} />
            <Popconfirm title="确定删除分组吗？" onConfirm={() => onDelete(data.id)} okText="确定" cancelText="取消">
                <span onClick={e => e.stopPropagation()}>
                    <HIcon newClass={'action-edit'} iconName={'icon-delete'} />
                </span>
            </Popconfirm>
        </span>
    );
};
// interface CustomProps {
//     label: string;
//     dataSource: SourceType[];
//     value: (number | string)[] | number | string;
//     multiple?: boolean;
//     onChange: (selected: (number | string)[], text?: string[]) => void;
// }
//
// interface CustomStates {
//     showList: boolean;
//     all: boolean;
//     selected: (number | string)[];
//     selectedText: string[];
// }

// export class HCustomMultipleSelect extends Component<CustomProps, CustomStates> {
//     optionCount = 0;
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             showList: false,
//             all: false,
//             selected: [],
//             selectedText: []
//         }
//     }
//
//     componentDidMount(): void {
//         // document.addEventListener('click', (e) => {
//         //     this.showChange(false)
//         // });
//         // this.optionCount = this.props.dataSource.length;
//         // this.setData();
//     }
//
//     setData = () => {
//         const {value, dataSource, multiple} = this.props;
//
//         let items = [];
//         if (Array.isArray(value)) {
//             value.forEach((v) => {
//                 const it = dataSource.filter((it) => it.value === v)
//                 items.push(it[0])
//             })
//         } else {
//             items = dataSource.filter((it) => it.value === value)
//         }
//         this.setState({
//             selected: multiple ? value as (number | string)[] : [value] as (number | string)[],
//             selectedText: items.map(it => it.text) || [""]
//         })
//     };
//
//     // 显示、隐藏下拉选项
//     showChange = (isShow) => {
//         this.setState({
//             showList: isShow
//         })
//     };
//
//     // input点击事件
//     clickInputBox = (e) => {
//         e.nativeEvent.stopImmediatePropagation();
//         this.showChange(!this.state.showList)
//     };
//
//     // 下拉框元素点击事件
//     clickSelectBox = (e) => {
//         e.nativeEvent.stopImmediatePropagation();
//         const {multiple} = this.props;
//         this.setState({
//             showList: multiple
//         })
//     };
//
//     // 全选
//     selectAllChange = () => {
//         const {dataSource, onChange} = this.props;
//         const {all} = this.state;
//         const ids: (number | string)[] = all ? [] : dataSource.map((it) => it.value);
//         const texts: string[] = all ? [] : dataSource.map((it) => it.text);
//         this.setState({
//             selected: ids,
//             all: !all,
//             selectedText: texts
//         });
//         onChange(ids)
//     };
//
//
//     selectChange = (v, option) => {
//         const {selected, selectedText} = this.state;
//         // 单选
//         if (v) {
//             const {children, value} = option;
//             selected.splice(0, 1, value);
//             selectedText.splice(0, 1, children);
//             this.setState({
//                 showList: false
//             })
//         } else {
//             selected.splice(0, 1);
//             selectedText.splice(0, 1)
//         }
//         this.setState({selected, selectedText, all: selected.length === this.optionCount});
//         this.props.onChange(selected, selectedText)
//     }
//
//     // 多选选中
//     checkChange = (checked, option) => {
//         const {selected, selectedText} = this.state;
//         const {text, value} = option;
//         if (checked) {
//             selected.push(value);
//             selectedText.push(text);
//         } else {
//             const index = selected.findIndex(v => v === value);
//             selected.splice(index, 1);
//             selectedText.splice(index, 1)
//         }
//
//         this.setState({selected, selectedText, all: selected.length === this.optionCount});
//         this.props.onChange(selected, selectedText)
//     };
//
//     // 清空选中
//     clear = (e) => {
//         e.stopPropagation();
//         this.setState({
//             selected: [],
//             selectedText: []
//         })
//     };
//
//
//     render() {
//         const {all, selected, showList, selectedText} = this.state;
//         const {label, dataSource, multiple} = this.props;
//         return (
//             <div className={"h-select"}>
//                 <span className={"h-select-text"}>{label}</span>
//                 {multiple ? <div className={"h-select-content"}>
//                     <div className={"h-select-input-box"} onClick={this.clickInputBox}>
//                         <input className={"h-select-input"} placeholder={"请选择"} readOnly value={selectedText}/>
//                         <span className={"h-select-icon"}>{selectedText.length > 0 ?
//                             <CloseOutlined className={"icon-close"} onClick={this.clear}/> :
//                             <DownOutlined/>}</span>
//                     </div>
//
//                     {showList && <div className={"h-select-box"} onClick={this.clickSelectBox}>
//                         <div className={"h-select-header"}>
//                             <HCheck checked={all} inner={selected.length > 0 && selected.length < this.optionCount}
//                                     onChange={this.selectAllChange}/>
//                             <div className={"h-select-header-title"}>{selected.length}/{this.optionCount}</div>
//                         </div>
//
//                         <div className={"h-select-list"}>
//                             {dataSource.map((it, i) => {
//                                 return <HCheck key={i} className={"h-select-list-item"}
//                                                checked={selected.findIndex(v => v === it.value) >= 0}
//                                                onChange={(checked) => this.checkChange(checked, it)}>
//                                     <span className={"text"}>{it.text}</span>
//                                 </HCheck>
//                             })}
//                         </div>
//                     </div>
//                     }
//                 </div> : <Select size={"small"} style={{width: 212}} allowClear value={selected}
//                                  placeholder={"请选择"}
//                                  onChange={this.selectChange}>
//                     {
//                         dataSource.map((it, index) => {
//                             return <Select.Option key={index} value={it.value}>{it.text}</Select.Option>
//                         })
//                     }
//                 </Select>}
//             </div>
//         );
//     }
// }
