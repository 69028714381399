/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: 重写Cesium对象
 * @Date: 2022-05-05 10:24:01
 * @LastEditTime: 2022-05-15 11:02:50
 */
//  The URL on your server where CesiumJS's static files are hosted.

/**
 * new Cesium.WebMapTileServiceImageryProvider({
        //影像底图
        url: "http://t{s}.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=262a5096a95bd3b84c7bcbd63fbbb572",
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        layer: "tdtImgLayer",
        style: "default",
        format: "image/jpeg",

        tileMatrixSetID: "GoogleMapsCompatible",//使用谷歌的瓦片切片方式
    })
 */
import * as Cesium from 'cesium';
import { Cartesian3 } from 'cesium';
import "cesium/Build/Cesium/Widgets/widgets.css";
// window['CESIUM_BASE_URL'] = './';

export default class CesiumInit {
    viewer: any;
    Cesium: any;
    constructor() {
        this.Cesium = Cesium;

    }
    /**
     * @description: 重写Viewer对象
     * @param {*} el
     * @return {* Viewer}
     */
    Viewers = (el) => {
        const imageryProvider = new Cesium.ArcGisMapServerImageryProvider({
            // url: "http://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer",
            url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer"
        });
        this.viewer = new Cesium.Viewer(el, {
            infoBox: false,
            selectionIndicator: false,
            // navigation: false,
            animation: false,
            timeline: false,
            baseLayerPicker: false,
            geocoder: false,
            homeButton: false,
            sceneModePicker: false,
            navigationHelpButton: false,
            shouldAnimate: true,
            imageryProvider: new Cesium.UrlTemplateImageryProvider({
                url: 'https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&tk=726529ec02bbc5a6e7ec9da73c0e4481&TileCol={x}&TileRow={y}&TileMatrix={z}',
                subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
                maximumLevel: 18
            }),
            contextOptions: {
                //cesium状态下允许canvas转图片convertToImage
                webgl: {
                    alpha: true,
                    depth: false,
                    stencil: true,
                    antialias: true,
                    premultipliedAlpha: true,
                    preserveDrawingBuffer: true,
                    failIfMajorPerformanceCaveat: true
                },
                allowTextureFilterAnisotropic: true,
                // requestWebgl2: true
            },
            // msaaSamples: 8
            // skyAtmosphere: false
        });
        // const imagery = this.viewer.imageryLayers.addImageryProvider(imageryProvider);
        // imagery.brightness = 0.4;
        // imagery.contrast = 1;
        // imagery.saturation = -0.009;
        this.setInitSence();
        /**
         * 叠加影像
         */
        // this.addLayer();

        return this.viewer;

    }
    /**
     * @description: 叠加地图影响
     * @param {*}
     * @return {*}
     */
    addLayer = () => {
        // Access the CartoDB Positron basemap, which uses an OpenStreetMap-like tiling scheme.
        const positron = new Cesium.UrlTemplateImageryProvider({
            url: 'http://dredge-3.oss-cn-shanghai.aliyuncs.com/terrain/pt_weixing/{z}/{x}/{y}.png'

        });
        this.viewer.imageryLayers.addImageryProvider(positron, 1);
        this.viewer.imageryLayers.get(1).show = false;
    }
    /**
     * @description: 创建颜色，返回Cesium里面的颜色
     * @param {*} a 红
     * @param {*} b 黄
     * @param {*} c 蓝
     * @param {*} d 透明通道
     * @return {*} Color
     */
    Color = (a, b, c, d) => { return new Cesium.Color(a / 255, b / 255, c / 255, d); };
    /**
     * @description: 创建颜色，返回Cesium里面的颜色
     * @param {* color} css颜色 #2343
     * @return {*} Color
     */
    CssColor = (color) => { return Cesium.Color.fromCssColorString(color); };
    /**
     * @description: 初始化界面部分参数比如地图背景颜色、缩放层级限制、隐藏logo、关闭光源等等
     * @param {*} viewer
     * @return {*}
     */
    setInitSence = () => {

        // 是否开启抗锯齿
        this.viewer.scene.fxaa = true;
        this.viewer.scene.postProcessStages.fxaa.enabled = true;

        this.viewer.scene.globe.baseColor = new Cesium.Color(0.53725, 0.5843, 0.55686);
        /**
         * 放大缩小层级设置
         */
        this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 10;
        // this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 150000;

        // 隐藏logo
        this.viewer.cesiumWidget.creditContainer.style.display = 'none';

        this.viewer.scene.globe.enableLighting = false;
        this.viewer.shadows = false;
        this.viewer.scene.screenSpaceCameraController.enableCollisionDetection = true;

        this.viewer.scene.skyBox.show = false;
        this.viewer.scene.backgroundColor = new Cesium.Color(91, 117, 136);
        // 场景变为2d
        this.viewer.scene.mode = Cesium.SceneMode.SCENE2D;
        // this.viewer.scene.mode = Cesium.SceneMode.SCENE3D;


        // this.viewer.resolutionScale = 1
        // console.log(this.viewer.resolutionScale);

        this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    };

};

/**
 * @description: 创建cesium坐标系
 * @param {*} p
 * @return {*}
 */
export const c3 = (p) => {
    return Cartesian3.fromDegrees(p[0], p[1]);
};