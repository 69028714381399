/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { Button, DatePicker, Input, Popover, Select, Table } from 'antd';
import LogService from '../../services/log';
import { PlanService } from '../../services/plan';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const { Option } = Select;
const reason = {
    0: '登录成功',
    1: '当前分享链接不存在',
    2: '当前分享链接已过期',
    3: '当前分享链接下的该访问用户已过期',
    4: '访问用户登录密码错误',
    5: '访问用户手机号码不在白名单内',
    6: '验证码 Token 不存在',
    7: '访问用户手机验证码已过期',
    8: '访问用户手机验证码错误'
};
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    },
    {
        title: '用户名',
        dataIndex: 'account',
        key: 'account'
    },
    { title: '方案名称', dataIndex: 'planName', key: 'planName' },
    { title: '登录验证手机号', dataIndex: 'phone', key: 'phone' },
    {
        title: '登录状态',
        dataIndex: 'state',
        key: 'state',
        render: (text, record) => (
            <Popover content={<span>{reason[record.reason]}</span>}>
                <span>{Boolean(text) ? '成功' : '失败'}</span>
            </Popover>
        )
    },
    { title: '登录时间', dataIndex: 'loginTime', key: 'loginTime' },
    { title: 'IP', dataIndex: 'ip', key: 'ip' },
    { title: '地区', dataIndex: 'address', key: 'address' }
];

interface Props {}

interface States {
    page: number;
    size: number;
    key: string;
    dataSource: any[];
    total: number;
    startTime: any;
    endTime: any;
    planName: string;
    planList: any[];
}

class LoginLog extends Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            page: 1,
            size: 12,
            key: '',
            dataSource: [],
            total: 0,
            startTime: '',
            endTime: '',
            planName: undefined,
            planList: []
        };
    }

    componentDidMount(): void {
        const { page, size } = this.state;
        this.getList({ page, size });
        this.getPlanList();
    }

    getList = param => {
        const { page, size } = param;
        LogService.getLoginDoc(param).then(data => {
            if (data) {
                this.setState({
                    dataSource: data.list,
                    total: data.total,
                    page,
                    size
                });
            }
        });
    };

    getPlanList = () => {
        PlanService.getAll().then(data => {
            this.setState({
                planList: data
            });
        });
    };

    dateChange = (dates, dateString) => {
        this.setState(
            {
                startTime: dateString[0] || '',
                endTime: dateString[1] || ''
            },
            () => {
                this.onSearch();
            }
        );
    };

    onPageChange = (page, pageSize) => {
        this.setState(
            {
                page,
                size: pageSize
            },
            () => {
                const { startTime, endTime, size, key } = this.state;
                this.getList({ startTime, endTime, page, size, key });
            }
        );
    };

    keyChange = e => {
        const key = e.target.value;
        this.setState(
            {
                key
            },
            () => {
                if (!key) {
                    this.onSearch();
                }
            }
        );
    };

    planChange = value => {
        this.setState(
            {
                planName: value
            },
            () => {
                this.onSearch();
            }
        );
    };

    onSearch = () => {
        const { startTime, endTime, size, key, planName } = this.state;
        this.getList({ startTime, endTime, page: 1, size, key, planName });
    };

    render() {
        const { dataSource, page, size, total, key, planList } = this.state;
        const paginationProps = {
            current: page,
            pageSize: size,
            total,
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: this.onPageChange,
            size: 'small' as 'default' | 'small'
        };
        return (
            <div className="bg">
                <div className="search-bar">
                    <RangePicker size="small" format={dateFormat} onChange={this.dateChange} />
                    <RenderSelect
                        dataSource={planList}
                        placeholder={'方案'}
                        onChange={this.planChange}
                        minWidth={150}
                        nameField={'mainTitle'}
                        valueField={'mainTitle'}
                    />
                    <Input
                        size="small"
                        value={key}
                        style={{ width: 146, height: 24, paddingLeft: 12 }}
                        allowClear
                        placeholder={'关键词'}
                        onChange={this.keyChange}
                        onPressEnter={this.onSearch}
                    />
                    <Button type="primary" size="small" style={{ width: 56, height: 24 }} onClick={this.onSearch}>
                        查询
                    </Button>
                </div>
                <Table
                    className={'table'}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={record => record.id}
                    pagination={paginationProps}
                />
            </div>
        );
    }
}

export default LoginLog;

interface RenderSelectProps {
    dataSource: any[];
    minWidth: number;
    onChange: (value) => void;
    placeholder: string;
    valueField?: string;
    nameField?: string;
}

const RenderSelect = ({ dataSource, minWidth, onChange, placeholder, valueField, nameField }: RenderSelectProps) => {
    return (
        <Select size="small" style={{ minWidth }} allowClear placeholder={placeholder} onChange={onChange}>
            {dataSource.map((it, i) => {
                return (
                    <Option key={i} value={it[valueField] || it}>
                        {it[nameField] || it}
                    </Option>
                );
            })}
        </Select>
    );
};
