import React, { Component } from 'react';
import { HIcon } from '../../../components';

interface Props {
    imageList: any[];
}

interface State {
    page: number;
    newList: any[];
}

class ImageShowOff extends Component<Props, State> {
    ref;
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            newList: []
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        const { imageList } = this.props;
        const newList = [];
        imageList.map(r => {
            if (r !== '') {
                newList.push(r);
            }
        });
        this.setState({
            newList
        });
    }

    changePage = page => {
        this.ref.current.style.left = page === 1 ? 0 : '-388px';
        this.setState({
            page
        });
    };

    render() {
        const { imageList } = this.props;
        const { page, newList } = this.state;
        return (
            <div className={'image-show-off'}>
                {newList.length > 1 ? (
                    <div className={'style1'}>
                        {newList.length > 2 && (
                            <>
                                <HIcon
                                    iconName="icon-doubleleft"
                                    newClass={`mobile-button left ${page === 1 && 'unActive'}`}
                                    onClick={() => this.changePage(1)}
                                />
                                <HIcon
                                    iconName="icon-doubleright"
                                    newClass={`mobile-button right ${page === 2 && 'unActive'}`}
                                    onClick={() => this.changePage(2)}
                                />
                            </>
                        )}
                        <div ref={this.ref}>
                            {newList.map((r, i) => {
                                return (
                                    <div key={i} className={'image-child'}>
                                        <img src={r} alt="" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : imageList[0] !== '' ? (
                    <div className={'style2'}>
                        <img src={imageList[0]} alt="" />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ImageShowOff;
