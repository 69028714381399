/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDescriptions, HDrawer, HTextBox, HMenu } from '../../../components/simple';
import { HInfoLine, TableLine, TimeLine } from '../../../components';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import FormContent from './form';
import { debounce } from '../../../utils/common';
import poiMarker from '../../../assets/poi-marker-default.png';
import HAMap from '../../../utils/amap';
import { MonitorService } from '../../../services/monitor';
import { message } from 'antd';
import Monitoring from '../components/monitoring';

const tabList = [
    {
        title: '数据信息'
    },
    {
        title: '监测数据'
    }
];

const detailList = [
    {
        key: 'name',
        title: '设备名称',
        value: ''
    },
    {
        key: 'source',
        title: '数据来源',
        value: ''
    },
    {
        key: 'lonlat',
        title: '经纬度',
        value: ''
    },

    {
        key: 'address',
        title: '地址详情',
        value: ''
    },

    {
        key: 'region',
        title: '所属区域',
        value: ''
    },

    {
        key: 'code',
        title: '设备编号',
        value: ''
    },
    {
        key: 'remark',
        title: '规格说明',
        value: ''
    },
    {
        key: 'doc',
        title: '技术文档',
        value: ''
    },
    {
        key: 'photos',
        title: '现场照片',
        value: ''
    },
    {
        key: 'maintenanceUnit',
        title: '维养单位',
        value: ''
    },

    {
        key: 'resp',
        title: '负责人',
        value: ''
    },
    {
        key: 'phone',
        title: '联系方式',
        value: ''
    },
    {
        key: 'constructionUnit',
        title: '建设单位',
        value: ''
    },
    {
        key: 'completionTime',
        title: '竣工时间',
        value: ''
    }
];
interface Props {
    keyword: string;
    isAdd: boolean;
    current: number;
    dataSource: any[];
    count: number;
    areaList: any[];
    getAreaList: () => void;
    choseAddDrawer: () => void;
    getStationList?: (value, current, data?) => void;
    changeProjectData?: (data) => void;
}

interface States {
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    hasMore: boolean;
    activeData: any[];
    activeObj: any;
    editData: any;
    rightKey: number;
}

class StationSet extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            hasMore: true,
            activeData: [],
            activeObj: {},
            editData: {},
            rightKey: 0
        };
    }
    initMap = () => {
        //初始化地图数据
        HAMap.init({ id: 'project-preview-map' });
    };
    componentDidMount() {
        this.props.getStationList('', 1);
        // console.log(this.props.dataSource);
        this.props.getAreaList();
        this.initMap(); //初始化地图，展示的地图
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.isAdd !== this.props.isAdd && this.props.isAdd) {
            if (this.state.isEdit) {
                this.onEditChange(false);
            }
        }
        if (prevProps.keyword !== this.props.keyword) {
            //防抖，重新获取数据
            debounce(this.props.getStationList(this.props.keyword, 1), 300);
        }
    }

    componentWillUnmount(): void {
        HAMap.clearMarker();
    }

    handleInfiniteOnLoad = page => {
        const { dataSource, getStationList, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getStationList(keyword, page, dataSource);
        }
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.choseAddDrawer();
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0, //activeButton是控制tab bar按钮的
            editData: visible ? data : {}
        });
    };

    lineClick = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            //重新定义数据结构
            detailList.map(r => {
                if (r.key === 'lonlat') {
                    data.position = [data.lon, data.lat];
                    r.value = data.position.join(',');
                } else if (r.key === 'region') {
                    if (data.region) {
                        this.props.areaList.map(it => {
                            if (it.areaCode === data.region.slice(0, 9) + '000') {
                                r.value = it.name;
                                it.children.map(it => {
                                    if (it.areaCode === data.region) {
                                        r.value = r.value + '-' + it.name;
                                    }
                                });
                            }
                        });
                    } else {
                        r.value = '';
                    }
                } else if (r.key === 'source') {
                    const newValue = { 1: '繁易', 2: '院内' };
                    r.value = newValue[data[r.key]];
                } else {
                    r.value = data[r.key];
                }
            });
            this.setState({
                activeData: data.id === activeId ? [] : detailList, //这里给选中的数据赋值
                activeObj: data.id === activeId ? {} : data,
                activeId: data.id === activeId ? 0 : data.id
            });
            HAMap.clearMarker();
            if (data.position) {
                HAMap.setCenter(data.position);
                HAMap.addCenterMarker(poiMarker, data.position);
            }
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        this.closeDrawer();
        MonitorService.delPumpSation(id).then(res => {
            message.success('删除成功');
            dataSource.splice(key, 1);
            const newData = [...dataSource];
            this.props.changeProjectData(newData);
            this.setState({
                activeData: id === activeId ? [] : activeData,
                activeId: id === activeId ? 0 : activeId
            });
        });

        this.props.getStationList(this.props.keyword, 1);
        // console.log(this.props.dataSource);
    };

    //编辑新增的关闭
    closeDrawer = () => {
        const { isAdd, choseAddDrawer } = this.props;
        if (isAdd) {
            choseAddDrawer();
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        const { keyword } = this.props;
        // this.lineClick({});
        this.closeDrawer();
        this.props.getStationList(keyword, 1);
    };

    render() {
        const { isEdit, activeId, activeButton, hasMore, editData, activeData, activeObj, rightKey } = this.state;
        const { isAdd, dataSource } = this.props;
        return (
            <>
                <HContentLeft>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    showDate={true}
                                    buttonList={
                                        !r.alarmNum || r.alarmNum === 0
                                            ? [
                                                  {
                                                      iconName: 'icon-kaiguan',
                                                      activeIcon: 'icon-edit-fill',
                                                      newClass: `${Boolean(r.state) ? 'icon-color-open' : 'icon-color-close'}`,
                                                      onClick: (event, key) => {}
                                                  },
                                                  {
                                                      iconName: 'icon-edit',
                                                      activeIcon: 'icon-edit-fill',
                                                      onClick: (event, key) => {
                                                          event.stopPropagation();
                                                          this.onEditChange(true, r, key);
                                                      }
                                                  }
                                              ]
                                            : [
                                                  {
                                                      iconName: 'icon-gaojing',
                                                      activeIcon: 'icon-edit-fill',
                                                      newClass: 'icon-color-red',
                                                      onClick: (event, key) => {}
                                                  },
                                                  {
                                                      iconName: 'icon-edit',
                                                      activeIcon: 'icon-kaiguan',
                                                      newClass: `${Boolean(r.state) ? 'icon-color-open' : 'icon-color-close'}`,
                                                      onClick: (event, key) => {}
                                                  },
                                                  {
                                                      iconName: 'icon-edit',
                                                      activeIcon: 'icon-edit-fill',
                                                      onClick: (event, key) => {
                                                          event.stopPropagation();
                                                          this.onEditChange(true, r, key);
                                                      }
                                                  }
                                              ]
                                    }
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => this.lineClick(r)}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    <div className={'h-content-right-map'} style={{ display: activeData.length > 0 ? 'block' : 'none' }} id={'project-preview-map'} />
                    {activeData.length > 0 && (
                        <>
                            <HMenu data={tabList} activeKey={rightKey} onClick={value => this.setState({ rightKey: value })} />
                            {rightKey === 0 ? (
                                activeData.map((it, i) => {
                                    return <HInfoLine key={i} title={it.title} value={it.value} />;
                                })
                            ) : (
                                <Monitoring data={activeObj} />
                            )}
                        </>
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-select'} title={'新增数据'} visible={isAdd} shutDown={this.closeDrawer}>
                        <FormContent isAdd={true} menuKey={1} onSuccess={this.onSaveSuccess} onCancel={this.closeDrawer} />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑数据'} shutDown={this.closeDrawer}>
                        <FormContent isAdd={isAdd} menuKey={1} editData={editData} onSuccess={this.onSaveSuccess} onCancel={this.closeDrawer} />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['monitor', 'dataSource']),
    current: state.getIn(['monitor', 'current']),
    count: state.getIn(['monitor', 'count']),
    areaList: state.getIn(['monitor', 'areaCode'])
});

const mapDispatch = dispatch => ({
    getStationList(value, current, data) {
        dispatch(actionCreators.getStationList(value, current, data));
    },
    changeProjectData(data) {
        dispatch(actionCreators.changeData(data));
    },
    getAreaList() {
        dispatch(actionCreators.getAreaList());
    }
});

export default connect(mapState, mapDispatch)(StationSet);
