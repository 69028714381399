import axios from 'axios';

export default class ProjectService {
    static getAll(key = '') {
        return axios
            .get('/project/query_all', {
                params: {
                    key
                }
            })
            .then(res => res.data);
    }

    static edit(data) {
        return axios.put('/project/edit', data);
    }

    static del(id) {
        return axios.delete('/project/delete/' + id);
    }

    static getListByLeader(leaderId, stage) {
        return axios.get('/project/list_by_leader', {
            params: {
                leaderId,
                stage
            }
        });
    }

    /**
     * @description                 我的填报 获取任务列表
     * @param id 
     */

    static getMissionQuery(params) {
        return axios.get(`/mission/query/${params.projectCode}/${params.type}`);
    }

    /**
     * @description                 任务列表新增/修改
     * @param data 
     * @returns 
     */

    static editMissionData(data) {
        return axios.put('/mission/edit', data);
    }

    static deleteMissionData(id) {
        return axios.delete(`/mission/delete/${id}`);
    }
}
