import React, { Component } from 'react';
import { HDrawer, TableLine, HCollapse, HDescriptions, HContentLeft, HContentRight, HTextBox, TimeLine, HInfoLine } from '../../../components';
import { Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import HPlanForm from './form';
import { EditorRouter, SkinRouter, DocRouter } from '../../../utils/static-data';
import HAMap from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';
import { debounce } from '../../../utils/common';
import { PlanService } from '../../../services/plan';

interface Props {
    current: number;
    dataSource: any[];
    count: number;
    isAdd: boolean;
    changeAddVisible: (value) => void;
    getChild1Data: (value, current, data?) => void;
    changeChild1Data: (data) => void;
    keyword: string;
}

interface State {
    activeButton: number;
    activeId: number;
    isEdit: boolean;
    shareVisible: boolean;
    shareData: {
        shareHref: string;
        sharePassword: string;
        documentCode: string;
        documentPassword: string;
    };
    activeData: any;
    hasMore: boolean;
}

class Child1 extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeButton: 0,
            activeId: 0,
            isEdit: false,
            shareVisible: false,
            shareData: {
                shareHref: '',
                sharePassword: '',
                documentCode: '',
                documentPassword: ''
            },
            activeData: undefined,
            hasMore: true
        };
    }

    componentDidMount() {
        const { keyword } = this.props;
        this.props.getChild1Data(keyword, 1);
        this.initMap();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.isAdd !== this.props.isAdd && this.props.isAdd) {
            if (this.state.isEdit) {
                this.onEditChange(false);
            }
        }
        if (prevProps.keyword !== this.props.keyword) {
            debounce(this.props.getChild1Data(this.props.keyword, 1), 300);
        }
    }

    componentWillUnmount(): void {
        HAMap.clearMarker();
    }

    initMap = () => {
        HAMap.init({ id: 'plan-preview-map' });
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.changeAddVisible(false);
        this.setState({
            isEdit: visible,
            shareVisible: false,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            activeData: visible ? data : undefined
        });
    };

    changeTableActive = data => {
        const { activeId, isEdit, shareVisible } = this.state;
        if (!isEdit && !shareVisible) {
            this.setState({
                activeId: data.id === activeId ? 0 : data.id,
                activeData: data.id === activeId ? undefined : data
            });
            HAMap.clearMarker();
            if (data.position && data.position.length > 0) {
                HAMap.setCenter(data.position);
                HAMap.addCenterMarker(poiMarker, data.position);
            }
        }
    };

    changeShareVisible = (isShow, data?) => {
        this.setState({
            shareVisible: isShow,
            activeId: isShow ? data.id : 0,
            activeButton: isShow ? 2 : 0,
            isEdit: false,
            shareData: {
                shareHref: isShow ? data.shareUrl : '',
                sharePassword: isShow ? data.password : '',
                documentCode: isShow ? data.code : '',
                documentPassword: isShow ? data.planPwd : ''
            }
        });
    };

    // changeEditVisible = (visible, data?) => {
    //     this.setState({
    //         isEdit: visible,
    //         activeId: visible ? data.id : 0,
    //         activeButton: visible ? 3 : 0,
    //         shareVisible: false,
    //     });
    //     this.props.changeAddVisible(false)
    // };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        PlanService.del(id).then(res => {
            dataSource.splice(key, 1);
            const newData = [...dataSource];
            this.props.changeChild1Data(newData);
            this.setState({
                isEdit: false,
                activeData: id === activeId ? undefined : activeData,
                activeId: id === activeId ? 0 : activeId
            });
        });
    };

    // 滚动加载下一页数据
    handleInfiniteOnLoad = page => {
        const { dataSource, getChild1Data, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getChild1Data(keyword, page, dataSource);
        }
    };

    closeDrawer = () => {
        const { isAdd, changeAddVisible } = this.props;
        if (isAdd) {
            changeAddVisible(false);
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        const { isAdd, keyword, current } = this.props;
        this.closeDrawer();
        this.props.getChild1Data(keyword, 1);
    };

    copyHref = href => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', href);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        document.body.removeChild(input);
    };

    render() {
        const { isAdd, changeAddVisible, dataSource } = this.props;
        const { activeButton, activeId, isEdit, shareVisible, shareData, activeData, hasMore } = this.state;
        return (
            <>
                <HContentLeft>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    imgUrl={r.logoUrl}
                                    showDate={false}
                                    buttonList={[
                                        {
                                            iconName: 'icon-select',
                                            activeIcon: 'icon-select',
                                            onClick: (event, key) => {
                                                event.stopPropagation();
                                                window.open(`${EditorRouter}/${r.id}`);
                                            }
                                        },
                                        {
                                            iconName: 'icon-link',
                                            activeIcon: 'icon-link',
                                            onClick: (event, key) => {
                                                event.stopPropagation();
                                                this.changeShareVisible(true, r);
                                            }
                                        },
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (event, key) => {
                                                event.stopPropagation();
                                                this.onEditChange(true, r, key);
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => {
                                        this.changeTableActive(r);
                                    }}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    <div className={'h-content-right-map'} style={{ display: activeData ? 'block' : 'none' }} id={'plan-preview-map'} />
                    {!isEdit && activeData ? (
                        <>
                            <HDescriptions title={activeData.mainTitle}>
                                <HTextBox text={activeData.overview} />
                            </HDescriptions>
                            <HDescriptions title={'里程碑'}>
                                <TimeLine data={activeData.milestoneList} />
                                {/* <TimelineTest data={activeData.milestoneList} /> */}
                            </HDescriptions>
                            <HDescriptions title={'参建方'}>
                                <div>
                                    {' '}
                                    {activeData.cooperateBuiltList.map((it, i) => {
                                        return (
                                            <HInfoLine
                                                key={i}
                                                title={it.unitTypeName}
                                                value={it.unitName}
                                                suffixWidth={150}
                                                suffixBgColor={'rgba(240, 247, 253, 1)'}
                                                suffix={<span>{it.name}</span>}
                                            />
                                        );
                                    })}
                                </div>
                            </HDescriptions>
                        </>
                    ) : null}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-edit'} title={'新建项目'} visible={isAdd} shutDown={() => changeAddVisible(false)}>
                        <HPlanForm isAdd={isAdd} onSuccess={this.onSaveSuccess} />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑项目'} visible={isEdit} shutDown={() => this.onEditChange(false)}>
                        <HPlanForm isEdit={isEdit} editData={activeData} onSuccess={this.onSaveSuccess} />
                    </HDrawer>
                )}
                {shareVisible && (
                    <HDrawer iconName={'icon-link'} title={'复制分享链接'} visible={shareVisible} shutDown={() => this.changeShareVisible(false)}>
                        <div>
                            <HCollapse title={'分享信息'}>
                                <div className={'share-data'}>
                                    <div>
                                        <div>链接地址:</div>
                                        <a href={SkinRouter + '/' + shareData.shareHref} target={'_blank'} rel="noreferrer">
                                            {window.location.origin + SkinRouter + '/' + shareData.shareHref}
                                        </a>
                                    </div>
                                    <div>
                                        <div>密码:</div>
                                        <div className={'share-data-password'}>{shareData.sharePassword}</div>
                                    </div>
                                </div>
                            </HCollapse>
                            <div className={'collapse-btn'}>
                                <Button onClick={() => this.copyHref(window.location.origin + SkinRouter + '/' + shareData.shareHref)}>
                                    复制链接
                                </Button>
                            </div>
                        </div>
                        <div>
                            <HCollapse title={'文档分享链接'}>
                                <div className={'share-data'}>
                                    <div>
                                        <div>链接地址:</div>
                                        <a href={DocRouter + '/' + shareData.documentCode} target={'_blank'} rel="noreferrer">
                                            {window.location.origin + DocRouter + '/' + shareData.documentCode}
                                        </a>
                                    </div>
                                    <div>
                                        <div>密码:</div>
                                        <div className={'share-data-password'}>{shareData.documentPassword}</div>
                                    </div>
                                </div>
                            </HCollapse>
                            <div className={'collapse-btn'}>
                                <Button onClick={() => this.copyHref(window.location.origin + DocRouter + '/' + shareData.documentCode)}>
                                    复制链接
                                </Button>
                            </div>
                        </div>
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['project', 'dataSource']),
    count: state.getIn(['project', 'count']),
    current: state.getIn(['project', 'current']),
    isAdd: state.getIn(['project', 'addVisible'])
});

const mapDispatch = dispatch => ({
    getChild1Data(keywords, current, data) {
        dispatch(actionCreators.getChild1Data(keywords, current, data));
    },
    changeChild1Data(data) {
        dispatch(actionCreators.changeChild1Data(data));
    },
    changeAddVisible(visible) {
        dispatch(actionCreators.changeAddVisible(visible));
    }
});

export default connect(mapState, mapDispatch)(Child1);
