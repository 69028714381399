/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HAdd, HContentBox, HMenu, HSearch } from '../../components';
import UserSet from './user/user-set';
import ProjectSet from './project/project-set';
import SystemSet from './system/system-set';
import { connect } from 'react-redux';

import './index.scss';
import { UserTypeMap } from '../../utils/static-data';
import { getOnlineUser } from '../../utils/common';

interface Props {}

interface States {
    menuActive: number;
    isAdd: boolean;
    keyword: string;
}

class Setup extends Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            menuActive: 0,
            isAdd: false,
            keyword: ''
        };
    }

    onSearch = e => {
        this.setState({
            keyword: e.target.value
        });
    };

    menuChange = key => {
        this.setState({
            menuActive: key,
            isAdd: false,
            keyword: ''
        });
    };

    renderContent = menuActive => {
        const { isAdd, keyword } = this.state;
        switch (menuActive) {
            case 0:
                return <ProjectSet keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
            case 1:
                return <UserSet keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
            case 2:
                return <SystemSet />;
        }
    };

    render() {
        const { menuActive, isAdd } = this.state;
        const userData = getOnlineUser();
        const menuList =
            userData.type === UserTypeMap.administrator
                ? [
                      {
                          title: '项目设置'
                      },
                      {
                          title: '用户设置'
                      },
                      {
                          title: '系统设置'
                      }
                  ]
                : [
                      {
                          title: '项目设置'
                      }
                  ];

        return (
            <div>
                <HMenu
                    data={menuList}
                    activeKey={menuActive}
                    onClick={this.menuChange}
                    suffix={
                        menuActive !== 2 ? (
                            <>
                                <HSearch isUpdate={menuActive} onChange={this.onSearch} />
                                <HAdd fill={isAdd} onClick={() => this.setState({ isAdd: !isAdd })} />
                            </>
                        ) : null
                    }
                />
                <HContentBox>{this.renderContent(menuActive)}</HContentBox>
            </div>
        );
    }
}

const mapState = state => ({});

const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(Setup);
