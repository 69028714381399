import React, { Component } from 'react';
import { message, Select } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import axios from 'axios';
import { SourceType, UploadDataType } from '../../../utils/static-data';
import { ModelModel } from '../store/model';
import HUploadThumbnail from '../../../components/upload';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { GroupModel } from '../../../components/model';
import { sizeChange } from '../../../utils/common';

const { Option } = Select;

interface Props {
    isAdd: boolean;
    addGroup: (name) => void;
    updateGroupChange: (data) => void;
    onDeleteGroup: (id) => void;
    groupList: GroupModel[];
    editData?: any;
    onSuccess: () => void;
    getNormalUserList: () => void;
    normalUserList: SourceType[];
    getAllProjectList: () => void;
    allProjectList: SourceType[];
}

interface States {
    formData: ModelModel;
    validation: boolean;
    sizeUnit: string;
    isCustomUpload: boolean;
}

class HModelForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                dataFormat: 'a3x',
                fileUrl: '',
                picUrl: '',
                groupId: undefined, //暂时
                isPublic: 1,
                name: '',
                principal: '',
                projectCode: undefined,
                unit: '',
                size: 0
            },
            validation: false,
            sizeUnit: 'MB',
            isCustomUpload: true
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
        this.props.getNormalUserList();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (
            this.props.normalUserList !== prevProps.normalUserList ||
            this.props.allProjectList !== prevProps.allProjectList ||
            this.props.editData !== prevProps.editData
        ) {
            this.setData();
        }
    }

    setData = () => {
        const { editData, isAdd } = this.props;
        if (!isAdd && editData) {
            const { formData } = this.state;
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
            });
            if (editData.size) {
                const sizeUnit = editData.size.replace(/[^a-zA-Z]/g, '');
                const size = editData.size.replace(sizeUnit, '');
                formData['size'] = sizeChange(size, sizeUnit);
            } else {
                formData['size'] = 0;
            }
            formData['id'] = editData.id;
            this.setState({
                formData,
                sizeUnit: 'B'
            });
        }
    };

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    sizeUnitChange = unit => {
        const { formData } = this.state;
        formData.size = 0;
        this.setState({
            sizeUnit: unit,
            formData
        });
    };

    onUploadTypeChange = type => {
        const { formData } = this.state;
        if (!formData.id) {
            formData.fileUrl = '';
        }
        this.setState({
            isCustomUpload: !Boolean(type),
            formData,
            validation: false
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData, isCustomUpload, sizeUnit } = this.state;

                if (formData.projectCode && formData.fileUrl && formData.groupId) {
                    if (isCustomUpload) {
                        delete formData.size;
                    } else if (!isCustomUpload && !formData.id) {
                        formData.size = sizeChange(formData.size, sizeUnit);
                    }
                    axios.put('/model/edit', formData).then(res => {
                        if (res['code'] === 200) {
                            message.success(`${formData.id ? '修改' : '添加'}成功`);
                            this.props.onSuccess();
                        }
                    });
                }
            }
        );
    };

    render() {
        const { isAdd, allProjectList, normalUserList, addGroup, groupList, updateGroupChange, onDeleteGroup } = this.props;
        const {
            formData: { picUrl, fileUrl, name, projectCode, principal, unit, groupId, isPublic, size },
            validation,
            isCustomUpload,
            sizeUnit
        } = this.state;
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput label={'单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                <HSelect label={'负责人'} value={principal} dataSource={normalUserList} onChange={selected => this.onChange('principal', selected)} />
                <HLabelInput label={'缩略图'}>
                    <HUploadThumbnail type={UploadDataType.pic} defaultUrl={picUrl} onChange={url => this.onChange('picUrl', url)} />
                </HLabelInput>
                {/*<HInput label={"文件路径"} rule={{required: true, validation, message: "请上传文件"}} value={fileUrl}*/}
                {/*        placeholder="文件Url"*/}
                {/*        fileType={UploadDataType.model}*/}
                {/*        onChange={(url) => this.onChange("fileUrl", url)}/>*/}
                <HInput
                    label={'文件路径'}
                    rule={{
                        required: true,
                        validation,
                        message: isCustomUpload ? '请上传文件' : '请输入文件url'
                    }}
                    value={fileUrl}
                    placeholder="文件Url"
                    fileType={UploadDataType.model}
                    onUploadTypeChange={this.onUploadTypeChange}
                    onChange={url => this.onChange('fileUrl', url)}
                />
                {!isCustomUpload && (
                    <HInput
                        label={'数据大小'}
                        rule={{ required: true, validation, message: '请输入数据大小' }}
                        value={size}
                        group={true}
                        groupInputWidth={166}
                        suffix={
                            <Select size={'small'} style={{ width: 46 }} value={sizeUnit} onChange={this.sizeUnitChange}>
                                <Option value={'B'}>B</Option>
                                <Option value={'KB'}>KB</Option>
                                <Option value={'MB'}>MB</Option>
                                <Option value={'GB'}>GB</Option>
                            </Select>
                        }
                        onChange={value => this.onChange('size', value)}
                    />
                )}
                {isPublic === 1 ? (
                    <HLabelInput label={'分组'} required={true}>
                        <HAddItemSelect
                            dataSource={groupList}
                            add={addGroup}
                            value={groupId}
                            isForm={true}
                            rule={{ required: true, validation, message: '请选择分组' }}
                            onChange={value => this.onChange('groupId', value)}
                            maxWidth={212}
                            minWidth={212}
                            saveChange={updateGroupChange}
                            onDelete={onDeleteGroup}
                        />
                    </HLabelInput>
                ) : null}
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    normalUserList: state.getIn(['upload', 'normalUserList']),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getNormalUserList() {
        dispatch(actionCreators.getNormalUserList());
    },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(HModelForm);
