/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HContentBox, HMenu, HSearch, HAdd } from '../../components';
import ProjectSet from './pipeline/pipeline-set';
import StationSet from './station/station-set';
import PumpstationSet from './pumpStation/pumpStation-set';
import PipeWell from './pipewell/pipewell-set';

import './index.scss';

const menuList = [
    {
        title: '场站'
    },
    {
        title: '泵站'
    },
    {
        title: '管线'
    },
    {
        title: '管井'
    }
];

interface Props {}

interface State {
    menuActive: number; //菜单选择
    isAdd: boolean; //是否展示新增
    keyword: string; //搜索的内容
}

class Monitor extends Component<Props, State> {
    //监测-组件
    constructor(props: Props) {
        super(props);
        this.state = {
            //设定初始值
            menuActive: 0, //场站
            isAdd: false, //不展示添加页面
            keyword: ''
        };
    }
    menuChange = key => {
        //console.log(key);
        this.setState({
            menuActive: key //菜单改变时，改变state里的数据
            // keyword: ""
        });
        // this.props.changeAddVisible(false)
    };

    renderContent = value => {
        //渲染内容，value应该是menuactive
        const { isAdd, keyword } = this.state; //从state读取数据
        switch (
            value //展示不同的页面
        ) {
            case 0:
                return <StationSet keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
            case 1:
                return <PumpstationSet keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
            case 2:
                return <ProjectSet keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
            case 3:
                return <PipeWell keyword={keyword} isAdd={isAdd} choseAddDrawer={() => this.setState({ isAdd: false })} />;
        }
    };

    onSearch = e => {
        //这个是搜索功能，获取输入的keyword
        this.setState({
            keyword: e.target.value
        });
    };

    render() {
        const { menuActive, isAdd } = this.state; //获取菜单选项，新增状态
        return (
            //HMenu代表tab+搜索框+add
            <div>
                <HMenu
                    data={menuList}
                    activeKey={menuActive}
                    onClick={this.menuChange}
                    suffix={
                        <>
                            <HSearch isUpdate={menuActive} onChange={this.onSearch} />
                            <HAdd fill={isAdd} onClick={() => this.setState({ isAdd: !isAdd })} />
                        </>
                    }
                />
                <HContentBox>{this.renderContent(menuActive)}</HContentBox>
            </div>
        );
    }
}

export default Monitor;
