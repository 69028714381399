/* eslint-disable @typescript-eslint/no-this-alias */
/**
 * 去抖动
 * @param func 要执行的函数
 * @param wait 延迟时间
 */
import { UserModel } from '../page/setup/user/model';

export const debounce = (func, wait: number) => {
    // if (typeof func != "function") throw new TypeError("Expected a function");
    let timer;
    return function (...args) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(func.bind(context, ...args), typeof wait == 'number' ? wait : 0);
    };
};

export const getOnlineUser = () => {
    const user = sessionStorage.getItem('user');
    if (user) {
        const userData: UserModel = JSON.parse(user);
        return userData;
    }
    window.location.href = '/';
};

export const getToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        return token;
    } else {
        window.location.href = '/';
    }
};

export const sizeChange = (size, sizeUnit) => {
    if (sizeUnit === 'KB') {
        return Number(size) * 1000;
    } else if (sizeUnit === 'MB') {
        return Number(size) * 1000 * 1000;
    } else if (sizeUnit === 'GB') {
        return Number(size) * 1000 * 1000 * 1000;
    }
};
