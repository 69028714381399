import React, { Component, CSSProperties, ReactNode } from 'react';
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './indes.scss';
import { HIcon } from '../simple';
import { UploadDataType } from '../../utils/static-data';
import axios from 'axios';
import { getToken } from '../../utils/common';

interface ThumbnailProps {
    defaultUrl?: string;
    className?: string;
    uploadButton?: ReactNode;
    type: number; //文件类型 1：音乐，2：图片，3：cad，4：地形，5：标签，6：模型，7：文档
    onChange: (url: string) => void;
    required?: boolean;
    validation?: boolean;
}

interface ThumbnailStates {
    imageUrl: string;
    loading: boolean;
    error: boolean;
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default class HUploadThumbnail extends Component<ThumbnailProps, ThumbnailStates> {
    static defaultProps = {
        className: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            imageUrl: '',
            loading: false,
            error: false
        };
    }

    componentDidMount(): void {
        this.setImageUrl();
    }

    componentDidUpdate(prevProps: Readonly<ThumbnailProps>, prevState: Readonly<ThumbnailStates>, snapshot?: any): void {
        if (prevProps.defaultUrl !== this.props.defaultUrl) {
            this.setImageUrl();
        }
        if (prevProps.validation !== this.props.validation) {
            this.validator();
        }
    }

    validator = () => {
        const { required } = this.props;
        const { imageUrl } = this.state;
        if (required && !imageUrl) {
            this.setState({
                error: true
            });
        } else {
            this.setState({
                error: false
            });
        }
    };

    setImageUrl = () => {
        const { defaultUrl } = this.props;
        this.setState({
            imageUrl: defaultUrl
        });
    };

    handleChange = info => {
        const { status, response } = info.file;
        if (status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (status === 'done') {
            // Get this url from response in real world.
            const { code, data, msg } = response;
            if (code === 200) {
                this.props.onChange(data);
                getBase64(info.file.originFileObj, imageUrl =>
                    this.setState(
                        {
                            imageUrl,
                            loading: false
                        },
                        () => {
                            this.validator();
                        }
                    )
                );
            } else {
                message.error(msg);
            }
        }
    };

    render() {
        const token = getToken();
        const { loading, imageUrl, error } = this.state;
        const { type, uploadButton, className } = this.props;
        const button = (
            <div className={'h-upload-btn'}>
                {uploadButton ? (
                    uploadButton
                ) : (
                    <>
                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>上传</div>
                    </>
                )}
            </div>
        );
        return (
            <Upload
                className={'h-upload' + (error ? ' h-item-has-error' : '')}
                maxCount={1}
                name="file"
                showUploadList={false}
                action={`${process.env.REACT_APP_API}/upload`}
                data={{ type }}
                headers={{ Authorization: `bearer ${token}` }}
                onChange={this.handleChange}
            >
                <div className={'h-upload-thumbnail' + ' ' + className}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}
                        />
                    ) : uploadButton ? (
                        uploadButton
                    ) : (
                        button
                    )}
                </div>
            </Upload>
        );
    }
}

interface UploadFileProps {
    fileType: number; //文件类型 1：音乐，2：图片，3：cad，4：地形，5：标签，6：模型，7：文档
    onChange: (url: string) => void;
}

interface UploadFileStates {
    fileUrl: string;
    loading: boolean;
}

export class HUploadFile extends Component<UploadFileProps, UploadFileStates> {
    constructor(props) {
        super(props);
        this.state = {
            fileUrl: '',
            loading: false
        };
    }

    /**
     * @description 文件是否合法
     * @param file
     * @returns {boolean}
     */
    fileLegal = file => {
        const { fileType } = this.props;
        const { name, type } = file;
        if (fileType === UploadDataType.model) {
            return name.indexOf('.a3x') >= 0 || name.indexOf('.A3X') >= 0;
            // return name.indexOf('.dae') >= 0 || name.indexOf('.DAE') >= 0;
        } else if (fileType === UploadDataType.cad) {
            return name.indexOf('.dxf') >= 0 || name.indexOf('.DXF') >= 0;
            // return type === 'image/vnd.dxf'
        } else if (fileType === UploadDataType.pic) {
            return type === 'image/jpeg' || type === 'image/png' || type === 'image/svg+xml';
        } else {
            return true;
        }
    };

    beforeUpload = file => {
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //     message.error('Image must smaller than 2MB!');
        // }
        return this.fileLegal(file);
    };

    handleChange = info => {
        const { status, response } = info.file;
        if (status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (status === 'done') {
            // Get this url from response in real world.
            const { code, data, msg } = response;
            if (code === 200) {
                this.props.onChange(data);
            } else {
                message.error(msg);
            }
        }
    };

    render() {
        const token = getToken();
        const { fileType } = this.props;
        const props = {
            name: 'file',
            action: `${process.env.REACT_APP_API}/upload`,
            data: { type: fileType },
            maxCount: 1,
            showUploadList: false,
            headers: {
                Authorization: `bearer ${token}`
                // authorization: 'authorization-text',
            },
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange
        };
        return (
            <Upload {...props}>
                <HIcon iconName={'icon-upload'} title={'上传文件'} />
            </Upload>
        );
    }
}
