import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDataOverview, HDataTopBar, HDescriptions, HDrawer } from '../../../components';
import { TableLine, HInfoLine } from '../../../components';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import { DataCount, GetListParam } from '../store/model';
import { Button, message } from 'antd';
import HPanoramaForm from './form';
import { GroupDataTypeMap } from '../../../utils/static-data';
import { GroupModel } from '../../../components/model';
import GroupService from '../../../services/group';
import ThreeMap from '../../../components/ThreeMap/ThreeMap';
import { EnvironmentOutlined, FullscreenOutlined } from '@ant-design/icons';
import './index.css';
import HAMap from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';

const rightList = [
    {
        key: 'unit',
        title: '单位',
        value: '',
        suffix: { key: 'principal', title: '负责人', value: '' }
    },
    {
        key: 'createDate',
        title: '上传时间',
        value: ''
    },
    {
        key: 'dataFormat',
        title: '数据格式',
        value: ''
    },
    {
        key: 'size',
        title: '数据大小',
        value: ''
    },
    {
        key: 'groupId',
        title: '分组',
        value: ''
    }
];

interface Props {
    dataSource: any[];
    current: number;
    count: number;
    getList?: (data: GetListParam) => void;
    changeList?: (list) => void;
    getDataCount?: (url) => void;
    dataCount: DataCount;
    groupList: GroupModel[];
    getGroupList: () => void;
}

interface States {
    isPublic: number;
    isAdd: boolean;
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    activeData: any;
    hasMore: boolean;
    editData: any;
    isHMap: boolean;
}

class HPanorama extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            isPublic: 1,
            isAdd: false,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: undefined,
            hasMore: true,
            editData: {},
            isHMap: false
        };
    }

    initMap = () => {
        //初始化地图数据
        HAMap.init({ id: 'panorama-map' });
    };

    componentDidMount() {
        const { isPublic } = this.state; //默认展示公共数据
        this.props.getList({ isPublic, current: 1 }); //整体数据
        this.props.getDataCount('/panorama/model_count'); //数据总览的接口
        this.props.getGroupList(); //分组数据
    }

    handleInfiniteOnLoad = page => {
        //分页
        const { dataSource, getList, count } = this.props;
        const { isPublic } = this.state;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getList({ isPublic, current: page, data: dataSource });
        }
    };

    publicChange = value => {
        //项目数据和公共数据切换
        this.setState({
            isPublic: value,
            hasMore: true,
            isAdd: false,
            isEdit: false
        });
        this.props.getList({ isPublic: value, current: 1 });
    };

    lineClick = data => {
        //点击展示详细数据
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            rightList.map(r => {
                r.value = data[r.key];
                if (r.suffix) r.suffix.value = data[r.suffix.key];
            });
            this.setState({
                activeData:
                    data.id === activeId
                        ? undefined
                        : { title: data.name, fileUrl: data.fileUrl, list: rightList, lat: data.latitude, lon: data.longitude },
                activeId: data.id === activeId ? 0 : data.id,
                isHMap: false //点击列表不展示定位地图
            });
            HAMap.clearMarker();
        }
    };

    onDelete = (id, key) => {
        //删除
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        axios.delete('/panorama/delete/' + id).then(res => {
            //删除的接口
            if (res['code'] === 200) {
                message.success('删除成功');
                dataSource.splice(key, 1);
                const newList = [...dataSource];
                this.props.changeList(newList);
                this.setState({
                    activeData: id === activeId ? undefined : activeData,
                    activeId: id === activeId ? 0 : activeId
                });
            }
        });
    };

    onAdd = () => {
        //新增
        const { isAdd } = this.state;
        this.setState({
            isAdd: !isAdd,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: {}
        });
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        this.setState({
            isEdit: visible,
            isAdd: false,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    //关闭编辑（可以用泵站的逻辑）
    closeDrawer = () => {
        const { isAdd } = this.state;
        if (isAdd) {
            this.setState({
                isAdd: false
            });
        } else {
            this.onEditChange(false);
        }
    };

    //成功时关闭编辑界面
    onSaveSuccess = () => {
        this.closeDrawer();
        const { isPublic } = this.state;
        this.props.getList({ isPublic, current: 1 });
    };

    //添加分组****
    addGroup = name => {
        GroupService.edit({
            isPublic: 1,
            name,
            type: GroupDataTypeMap.panorama
        }).then(() => {
            this.props.getGroupList();
        });
    };

    //更新分组
    updateGroupChange = data => {
        GroupService.edit(data).then(() => {
            this.props.getGroupList();
        });
    };

    //删除分组
    onDeleteGroup = id => {
        GroupService.del(id).then(() => {
            this.props.getGroupList();
        });
    };

    //修改分组（这个和上面那个有啥区别）
    groupChange = id => {
        const { isPublic } = this.state;
        this.setState({
            hasMore: true
        });
        const data = id ? { isPublic, groupId: id, current: 1 } : { isPublic, current: 1 };
        this.props.getList(data);
    };

    render() {
        const { isPublic, isAdd, isEdit, activeId, activeButton, hasMore, activeData, editData, isHMap } = this.state;
        const { dataSource, dataCount, groupList } = this.props;
        return (
            <>
                <HContentLeft>
                    <HDataTopBar
                        addGroup={this.addGroup} //这边少一些数据，不完整
                        isPublic={isPublic}
                        groupList={groupList}
                        onGroupChange={this.groupChange}
                        onPublicChange={this.publicChange}
                        onAdd={this.onAdd}
                    />
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    // imgUrl={r.picUrl}
                                    isPublic={isPublic}
                                    showDate={true}
                                    buttonList={[
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (e, key) => {
                                                e.stopPropagation();
                                                this.onEditChange(true, r, key);
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => this.lineClick(r)}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {activeData && activeData.list ? ( //这里应该考虑到刷新
                        <div>
                            <div className="h-preview-img">
                                <div className="img-button">
                                    <Button
                                        onClick={() => {
                                            const position = [activeData.lon, activeData.lat];
                                            HAMap.clearMarker();
                                            this.setState(
                                                {
                                                    isHMap: true
                                                },
                                                () => {
                                                    this.initMap();
                                                    if (position) {
                                                        HAMap.setCenter(position);
                                                        HAMap.addCenterMarker(poiMarker, position);
                                                    }
                                                }
                                            );
                                        }}
                                        icon={<EnvironmentOutlined />}
                                        className="button"
                                    />
                                    <Button
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    isHMap: false
                                                },
                                                () => {
                                                    HAMap.clearMarker();
                                                }
                                            );
                                        }}
                                        icon={<FullscreenOutlined />}
                                        className="button"
                                    />
                                </div>
                                {isHMap ? (
                                    <div id="panorama-map" style={{ width: '100%', height: '100%', overflow: 'visible' }} />
                                ) : (
                                    <ThreeMap
                                        model={{
                                            picurl: activeData.fileUrl
                                        }}
                                    />
                                )}
                            </div>
                            <HDescriptions title={activeData.title}>
                                {activeData.list.map((it, i) => {
                                    return (
                                        <HInfoLine
                                            key={i}
                                            title={it.title}
                                            value={it.value}
                                            suffixWidth={150}
                                            suffixBgColor={it.suffix ? 'rgba(240, 247, 253, 1)' : '#FBFBFB'}
                                            suffix={
                                                it.suffix ? (
                                                    <span>
                                                        {it.suffix.title}:{it.suffix.value}
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    );
                                })}
                            </HDescriptions>
                        </div>
                    ) : (
                        //有缺陷。。。点击编辑时会出现全景数据总览
                        <HDataOverview title={'全景数据总览'} data={dataCount} />
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-upload'} title={'上传'} shutDown={this.closeDrawer}>
                        <HPanoramaForm
                            isAdd={isAdd}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            onSuccess={this.onSaveSuccess}
                            onCancel={this.closeDrawer}
                        />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑'} shutDown={this.closeDrawer}>
                        <HPanoramaForm
                            isAdd={false}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            editData={editData}
                            onSuccess={this.onSaveSuccess}
                            onCancel={this.closeDrawer}
                        />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['upload', 'dataSource']),
    current: state.getIn(['upload', 'current']),
    count: state.getIn(['upload', 'count']),
    dataCount: state.getIn(['upload', 'dataCount']),
    groupList: state.getIn(['upload', 'groupList'])
});

const mapDispatch = dispatch => ({
    getList({ key, isPublic, groupId, current, data }) {
        dispatch(
            actionCreators.getPageList({
                url: '/panorama/query',
                key,
                isPublic,
                groupId,
                current,
                data
            })
        );
    },
    changeList(list) {
        dispatch(actionCreators.changeList(list));
    },
    getDataCount(url) {
        dispatch(actionCreators.getDataCount(url));
    },
    getGroupList() {
        dispatch(actionCreators.getGroupList({ isPublic: 1, type: GroupDataTypeMap.panorama })); //传入不同的type
    }
});

export default connect(mapState, mapDispatch)(HPanorama);
