import React, { Component } from 'react';
import { HContentLeft, HContentRight, HDrawer } from '../../../components/simple';
import { HInfoLine, TabCollapse } from '../../../components';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
// import HProjectForm from './form';
import CesMap from '../../../ces2d/init2d';
import { debounce } from '../../../utils/common';
import ProjectService from '../../../services/project';
import { MonitorService } from '../../../services/monitor';
import EditForm from './EditForm';
import { message } from 'antd';
import AddForm from './AddForm';

import '../index.scss';

const detailList = [
    {
        key: 'name',
        title: '设备名称',
        value: ''
    },
    {
        key: 'lonlat',
        title: '经纬度',
        value: ''
    },

    {
        key: 'address',
        title: '地址详情',
        value: ''
    },

    {
        key: 'region',
        title: '所属区域',
        value: ''
    },

    {
        key: 'doc',
        title: '技术文档',
        value: ''
    },
    {
        key: 'photos',
        title: '现场照片',
        value: ''
    },
    {
        key: 'maintenanceUnit',
        title: '维养单位',
        value: ''
    },

    {
        key: 'resp',
        title: '负责人',
        value: ''
    },

    {
        key: 'phone',
        title: '联系方式',
        value: ''
    },
    {
        key: 'constructionUnit',
        title: '建设单位',
        value: ''
    },
    {
        key: 'completionTime',
        title: '竣工时间',
        value: ''
    }
];

interface Props {
    //这是传给set的参数
    keyword: string;
    isAdd: boolean;
    current: number;
    dataSource: any[];
    count: number;
    choseAddDrawer: () => void; //控制不同的add界面
    getPipelineList?: (value, current, data?) => void;
    changeProjectData?: (data) => void;
}

interface States {
    //状态
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    hasMore: boolean;
    activeData: any[]; //active大概是指选中的数据
    editData: any;
    activeInfo: any;
    cadInfo: any;
    lineInfo: any;
    editInfo: any;
    EntityListId: any[];
    uploadFlag: number;
}

class PipelineSet extends Component<Props, States> {
    Maps_: any;
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            hasMore: true,
            activeData: [],
            editData: {},
            activeInfo: { type: '', key: '' },
            cadInfo: {},
            lineInfo: {},
            EntityListId: [],
            editInfo: {
                state: false,
                id: '',
                data: [
                    { title: '名称', type: 'input', value: '' },
                    { title: '流向', type: 'radio', value: '', option: ['正向', '负向'] },
                    { title: '线宽', type: 'input', value: '' },
                    { title: '流速', type: 'input', value: '' },
                    { title: '是否开启动效', type: 'radio', value: '', option: ['否', '是'] },
                    { title: '静态颜色', type: 'input', value: '' },
                    { title: '动态颜色', type: 'input', value: '' }
                ]
            },
            uploadFlag: 0
        };
    }

    initMap = () => {
        //初始化地图数据
        // HAMap.init({ id: 'project-preview-map' });
        this.Maps_ = new CesMap();
        this.Maps_.Viewers('project-preview-map');
        this.Maps_.setView();
        window['Maps_'] = this.Maps_;
    };

    componentDidMount() {
        this.props.getPipelineList('', 1); //从接口读取数据
        this.initMap(); //初始化地图，展示的地图
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.isAdd !== this.props.isAdd && this.props.isAdd) {
            if (this.state.isEdit) {
                this.onEditChange(false);
            }
        }
        if (prevProps.keyword !== this.props.keyword) {
            //防抖，重新获取数据
            debounce(this.props.getPipelineList(this.props.keyword, 1), 300);
        }
    }

    componentWillUnmount(): void {
        // HAMap.clearMarker();
    }

    //分页
    handleInfiniteOnLoad = page => {
        const { dataSource, getPipelineList, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getPipelineList(keyword, page, dataSource);
        }
    };

    handleSetActiveData = data => {
        this.setState({ activeData: data });
    };

    handleSetCadInfo = data => {
        this.setState({ cadInfo: data }, () => this.handleSetActiveData(data.data));
    };

    handleSetLineInfo = data => {
        this.setState({ lineInfo: data }, () => {
            this.setState({ activeData: data.data });
        });
    };

    handleSetEditInfo = (state, flag?, id?, value?) => {

        if (flag === 'init') {
            this.setState({
                editInfo: {
                    state,
                    id,
                    data: value ? value : [...this.state.editInfo.data]
                }
            }, () => {
                const { viewer, entities } = this.Maps_;
                const en_ = new entities(viewer);
                en_.flyToEntities(en_.getById(id));

            });


        } else if (flag === null) {
            this.setState({
                editInfo: {
                    state,
                    id: this.state.editInfo.id,
                    data: [...this.state.editInfo.data]
                }
            });
            const { viewer, entities } = this.Maps_;
            const en_ = new entities(viewer);
            en_.flyToEntities(en_.getById(id));

        } else {
            const tmp = [...this.state.editInfo.data];
            tmp.find(item => item.title === flag).value = value;
            this.setState({
                editInfo: {
                    state,
                    id: this.state.editInfo.id,
                    data: [...tmp]
                }
            });
            /**
             * 更新当前编辑的线段，同时在地图上将数据动态更新上去
             */
            this.setLineStyle();
        }
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.choseAddDrawer(); //选择了编辑就关闭新增
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    lineClick = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            //非编辑状态
            // detailList.map(r => {
            //     if ((r.key === 'leader' || r.key === 'principal' || r.key === 'informant' || r.key === 'userPool') && data[r.key]) {
            //         r.value = data[r.key].map(u =-> u.name);
            //     } else r.value = data[r.key];
            // });
            this.setState({
                activeData: data.id === activeId ? [] : detailList,
                activeId: data.id === activeId ? 0 : data.id
            });
            // HAMap.clearMarker();
            // if (data.position) {
            //     HAMap.setCenter(data.position);
            //     HAMap.addCenterMarker(poiMarker, data.position);
            // }
        }
    };

    //删除list里的数据
    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        ProjectService.del(id).then(res => {
            dataSource.splice(key, 1);
            const newData = [...dataSource];
            this.props.changeProjectData(newData);
            this.setState({
                activeData: id === activeId ? [] : activeData,
                activeId: id === activeId ? 0 : activeId
            });
        });
    };

    //关闭编辑的界面
    closeDrawer = () => {
        const { isAdd, choseAddDrawer } = this.props;
        if (isAdd) {
            choseAddDrawer();
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        const { isAdd, keyword, current } = this.props;
        //漏了将新增的数据保存
        this.closeDrawer();
        this.props.getPipelineList(keyword, 1);
    };

    handleSelectItem = (type, key) => {
        this.setState({ activeInfo: { type, key } });
        if (type === 'cad') {
            if (key && (!this.state.cadInfo.id || this.state.cadInfo.id !== key)) {
                MonitorService.handleGetCadInfo(key)
                    .then(res => {
                        const { data } = res;
                        this.handleSetCadInfo({
                            id: key,
                            data: [
                                { title: '名称', value: data.name ? data.name : '' },
                                { title: '负责人', value: data.principal ? data.principal : '' },
                                { title: '数据大小', value: data.size ? data.size : '' },
                                { title: '数据格式', value: data.dataFormat ? data.dataFormat : '' },
                                { title: '方案名称', value: data.planName ? data.planName : '' },
                                { title: '项目编号', value: data.projectCode ? data.projectCode : '' },
                                { title: '创建时间', value: data.createDate ? data.createDate : '' },
                                { title: '修改时间', value: data.updateDate ? data.updateDate : '' }
                            ]
                        });
                    })
                    .catch(e => {
                        //
                    });
            } else {
                this.setState({ activeData: this.state.cadInfo.data });
            }
        } else if (type === 'layer') {
            this.setState({ activeData: this.state.cadInfo.data });
        } else {
            this.setState({ activeData: [] });
        }
    };
    /**
     * @description: 绘制管线数据
     * @param {*}
     * @return {*}
     */
    addLine = (data) => {
        const { EntityListId } = this.state;
        const { toLonLat, entities, viewer } = window['Maps_'];
        data && data.forEach((it, i) => {
            MonitorService.handleGetLineInfo(it.id).then((res: any) => {
                if (res.code !== 200) {
                    message.error('获取管线信息失败');
                } else {
                    const { lineWidth, flow, flowColor, isDynamic, name, speed, staticColor } = res.data;
                    const condd = it.points;
                    let path;
                    const pusha = (e) => { e.push(-2); return e; };
                    path = condd.map(toLonLat);
                    path = path.map(pusha);
                    if (path.length === 0 || path[0][1] < 24.97551971) {
                        return;
                    }
                    it.points = [].concat(...path);
                    const entitie_ = new entities(viewer);
                    entitie_.options({
                        id: it.id,
                        data: { ...it },
                        type: "PUTIAN_POLYLINE",
                        name: name ? name : '未命名',
                        polyline: {
                            flow: flow ? flow : 0,
                            positions: it.points,

                            speed: speed ? speed : 1000,
                            zIndex: 4,
                            flowColor: flowColor ? flowColor : '#fff',
                            staticColor: staticColor ? staticColor : '#fff',
                            lineWidth: lineWidth ? lineWidth : 4,
                            isDynamic: isDynamic ? isDynamic : 0,
                        }
                    });
                    entitie_.add();
                    EntityListId.push(it.id);
                }
            });




        });
        this.setState({
            EntityListId
        });

    };
    /**
     * @description: 移除实体事件
     * @param {*}
     * @return {*}
     */
    removeLine = () => {
        const { EntityListId } = this.state;
        const { entities, viewer } = window['Maps_'];
        const entitie = new entities(viewer);
        EntityListId.forEach(element => {
            entitie.accordIdRemove(element);
        });
        this.setState({
            EntityListId: []
        });
    }
    /**
     * @description: 更新当前线段的样式
     * @param {*}
     * @return {*}
     */
    setLineStyle = () => {
        /**
         * 根据ID获取当前实体对象，然后修改实体对象相关参数
         */
        const { entities, viewer } = window['Maps_'];
        const { id, data, state } = this.state.editInfo;
        const entit_ = new entities(viewer);
        const data_: any = {};
        if (state) {
            data_.id = id;
            data.forEach(el => {
                switch (el.title) {
                    case '名称':
                        data_.name = el.value;
                        break;
                    case '流向':
                        data_.isDynamic = el.value;
                        break;
                    case '线宽':
                        data_.lineWidth = el.value;
                        break;
                    case '流速':
                        data_.speed = el.value;
                        break;
                    case '是否开启动效':
                        data_.flow = el.value;
                        break;
                    case '静态颜色':
                        data_.staticColor = el.value;
                        break;
                    case '动态颜色':
                        data_.flowColor = el.value;
                        break;

                    default:
                        break;
                }
            });
            /**
             * 根据线段参数更新央视
             */
            entit_.setLineStyle(data_);
        }

    };
    /**
     * @description: 更新线段
     * @param {*}
     * @return {*}
     */
    setAddLineData = (data) => {

        /**
         * 清楚管线
         */
        this.removeLine();
        /**
         * 添加管线
         */
        this.addLine(data);

    }
    handleSaveLineData = () => {
        // console.log(this.state.editInfo);
        const { data } = this.state.editInfo;
        MonitorService.handleUpdateLineInfo({
            flow: data[1].value,
            flowColor: data[6].value,
            id: this.state.editInfo.id,
            isDynamic: data[4].value,
            lineWidth: data[2].value,
            name: data[0].value,
            speed: data[3].value,
            staticColor: data[5].value
        })
            .then((res: any) => {
                // console.log(res);
                if (res.code !== 200) {
                    message.error('修改失败');
                } else {
                    message.success('修改成功');
                    this.handleSetEditInfo(false, null);
                    this.handleSetLineInfo({
                        id: this.state.editInfo.id,
                        data: [
                            { title: '名称', value: data[0].value },
                            { title: '流向', value: data[1].value },
                            { title: '线宽', value: data[2].value },
                            { title: '流速', value: data[3].value },
                            { title: '是否开启动效', value: data[4].value },
                            { title: '静态颜色', value: data[5].value },
                            { title: '动态颜色', value: data[6].value }
                        ]
                    });
                    this.setState({ uploadFlag: this.state.uploadFlag + 1 });
                }
            })
            .catch(err => {
                // console.log(err);
            });
    };

    render() {
        const { isEdit, activeId, activeButton, hasMore, activeData, editData, activeInfo } = this.state;
        const { isAdd, dataSource } = this.props;

        return (
            <>
                <HContentLeft>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                // <TableLine
                                //     key={r.id}
                                //     data={r}
                                //     imgUrl={r.logoUrl}
                                //     showDate={false}
                                //     buttonList={[
                                //         {
                                //             iconName: 'icon-edit',
                                //             activeIcon: 'icon-edit-fill',
                                //             onClick: (event, key) => {
                                //                 event.stopPropagation();
                                //                 this.onEditChange(true, r, key);
                                //             }
                                //         }
                                //     ]}
                                //     activeButton={activeButton}
                                //     isButtonActive={r.id === activeId}
                                //     activeTable={activeId}
                                //     onDelete={() => this.onDelete(r.id, i)}
                                //     onClick={() => this.lineClick(r)}
                                // />
                                <TabCollapse
                                    key={i}
                                    props={{
                                        data: r,
                                        setValue: this.handleSelectItem,
                                        setLineInfo: this.handleSetLineInfo,
                                        setAddLineData: this.setAddLineData,
                                        setEditInfo: this.handleSetEditInfo,
                                        uploadFlag: this.state.uploadFlag,
                                        type: 'line'
                                    }}
                                    onClick={this.onEditChange}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {this.state.editInfo.state ? (
                        <>
                            <div className={'h-content-right-map'} id={'project-preview-map'} />
                            {this.state.editInfo.data.map(item => (
                                <EditForm
                                    key={item.title}
                                    props={{
                                        type: item.type,
                                        value: item.value,
                                        setValue: this.handleSetEditInfo,
                                        flag: item.title,
                                        option: item.option
                                    }}
                                />
                            ))}
                            <div className="h-line-edit-btn-box">
                                <button onClick={this.handleSaveLineData}>保存数据</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={'h-content-right-map'}
                                style={{ display: activeInfo.key ? 'block' : 'none' }}
                                id={'project-preview-map'}
                            />
                            {activeData.length > 0 && (
                                <>
                                    {activeData.map((it, i) => {
                                        return <HInfoLine key={i} title={it.title} value={it.value} />;
                                    })}
                                </>
                            )}
                        </>
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-file-add'} title={'上传数据'} shutDown={this.closeDrawer}>
                        {/* <HProjectForm isAdd={isAdd} onSuccess={this.onSaveSuccess} /> */}
                        <AddForm onSuccess={this.closeDrawer} />
                    </HDrawer>
                )}
                {
                    isEdit && null
                    // <HDrawer iconName={'icon-edit'} title={'编辑数据'} shutDown={this.closeDrawer}>
                    //     {/* <HProjectForm isAdd={isAdd} editData={editData} onSuccess={this.onSaveSuccess} /> */}
                    //     <EditForm />
                    // </HDrawer>
                }
            </>
        );
    }
}

const mapState = state => ({
    //这里要改
    dataSource: state.getIn(['monitor', 'dataSource']),
    current: state.getIn(['monitor', 'current']),
    count: state.getIn(['monitor', 'count'])
});

const mapDispatch = dispatch => ({
    getPipelineList(value, current, data) {
        dispatch(actionCreators.getPipelineList(value, current, data));
    },
    changeProjectData(data) {
        dispatch(actionCreators.changeData(data));
    }
});

export default connect(mapState, mapDispatch)(PipelineSet);
