import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    current: 1,
    count: 0,
    dataSource: [],
    dataCount: {
        filesCount: 0,
        filesSize: '',
        projectCount: 0,
        publicCount: 0
    },
    allProjectList: [],
    normalUserList: [],
    groupList: []
});

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_DATA:
            return state['set']('dataSource', action.value);
        case constants.SET_CURRENT:
            return state['set']('current', action.value);
        case constants.SET_COUNT:
            return state['set']('count', action.value);
        case constants.SET_DATACOUNT:
            return state['set']('dataCount', action.value);
        case constants.SET_ALLPROJECT:
            return state['set']('allProjectList', action.value);
        case constants.SET_NORMAL_USER_LIST:
            return state['set']('normalUserList', action.value);
        case constants.SET_GROUP_LIST:
            return state['set']('groupList', action.value);
        default:
            return state;
    }
};

export default reducer;
