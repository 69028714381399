import axios from 'axios';

export class TerrainService {
    static edit(data) {
        return axios.put('/terrain/edit', data);
    }

    static del(id) {
        return axios.delete('/terrain/delete/' + id);
    }
}
