/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';

interface Props {
    title: string;
    engtitle: string;
    children: any;
    suffix?: any;
    className?: string;
}

interface State {}

class ContentCard extends Component<Props, State> {
    static defaultProps = {
        className: ''
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, engtitle, suffix, children, className } = this.props;
        return (
            <div className={'content-card' + ' ' + className}>
                <div className={'content-card-title'}>
                    <div>
                        <div className={'cn-title'}>{title}</div>
                        <div className={'eng-title'}>{engtitle}</div>
                    </div>
                    {suffix}
                </div>
                {children}
            </div>
        );
    }
}

export default ContentCard;
