import * as constants from './constants';
import axios from 'axios';
import { GetListParam } from './model';
import GroupService from '../../../services/group';
import ProjectService from '../../../services/project';
import { UserService } from '../../../services/user';

/**
 * @description 通用
 */
const setData = value => ({
    type: constants.SET_DATA,
    value
});

const setCurrent = value => ({
    type: constants.SET_CURRENT,
    value
});

const setCount = value => ({
    type: constants.SET_COUNT,
    value
});

// 数据总览
const setDataCount = value => ({
    type: constants.SET_DATACOUNT,
    value
});

// 所有项目
const setAllProject = value => ({
    type: constants.SET_ALLPROJECT,
    value
});

// 获取除领导人外的用户
const setNormalUserList = value => ({
    type: constants.SET_NORMAL_USER_LIST,
    value
});

const setGroupList = value => ({
    type: constants.SET_GROUP_LIST,
    value
});

/**
 * @description 获取数据总览
 */
export const getDataCount = url => {
    return dispatch => {
        axios.get(url).then(res => {
            dispatch(setDataCount(res.data));
        });
    };
};

/**
 * @description 获取所有项目
 */
export const getAllProject = (key = '') => {
    return dispatch => {
        ProjectService.getAll(key).then(list => {
            const data = list.map(it => {
                return { value: it.projectCode, text: it.projectName };
            });
            dispatch(setAllProject(data));
        });
    };
};

/**
 * @description 获取一般用户列表
 */
export const getNormalUserList = () => {
    return dispatch => {
        UserService.getListByType(1).then(res => {
            const data = res.data.map(it => {
                return { value: it.id, text: it.name };
            });
            dispatch(setNormalUserList(data));
        });
    };
};

/**
 * @description 请求标签带分页的列表
 */
export const getLabelList = ({ url, key = '', isPublic = 0, isStatic = 0, groupId, current, data = [] }) => {
    return dispatch => {
        axios
            .get(url, {
                params: {
                    key,
                    isPublic,
                    isStatic,
                    groupId,
                    page: current,
                    size: 25
                }
            })
            .then(res => {
                const newData = [...data, ...res.data.list];
                const newCount = Math.ceil(res.data.total / 25);
                dispatch(setData(newData));
                dispatch(setCurrent(current));
                dispatch(setCount(newCount));
            });
    };
};

/**
 * @description 请求带分页的列表
 */
export const getPageList = ({ url, isPublic = 0, current, key = '', data = [], groupId = null }: GetListParam) => {
    return dispatch => {
        axios
            .get(url, {
                params: {
                    key,
                    isPublic,
                    page: current,
                    size: 25,
                    groupId
                }
            })
            .then(res => {
                const newData = [...data, ...res.data.list];
                const newCount = Math.ceil(res.data.total / 25);
                dispatch(setData(newData));
                dispatch(setCurrent(current));
                dispatch(setCount(newCount));
            });
    };
};

/**
 * @description 修改列表
 */
export const changeList = data => {
    return dispatch => {
        dispatch(setData(data));
    };
};

/**
 * @description 获取分组
 */
export const getGroupList = (param: { isPublic; type }) => {
    return dispatch => {
        GroupService.get(param).then(data => {
            dispatch(setGroupList(data.reverse()));
        });
    };
};
