import * as constants from './constants';
import { fromJS } from 'immutable'; //将对象转化为map

const defaultState = fromJS({
    current: 1,
    count: 0,
    dataSource: [],
    getAllProject: [],
    areaCode: []
});

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_DATA:
            return state['set']('dataSource', action.value);
        case constants.SET_CURRENT:
            return state['set']('current', action.value);
        case constants.SET_COUNT:
            return state['set']('count', action.value);
        case constants.GET_ALLPROJECT:
            return state['set']('getAllProject', action.value);
        case constants.GET_ALLAREACODE:
            return state['set']('areaCode', action.value);
        default:
            return state;
    }
};

export default reducer;
