import React, { Component } from 'react';
import { Input, message, DatePicker, InputNumber } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HIcon, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import axios from 'axios';
import { SourceType, CoordinateTypeList, AccuracyUnit } from '../../../utils/static-data';
import { TerrainModel } from '../store/model';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import moment from 'moment';
import { GroupModel } from '../../../components/model';
import { TerrainService } from '../../../services/terrain';

const { Group } = Input;

interface Props {
    isAdd: boolean;
    addGroup: (name) => void;
    editData?: any;
    groupList: GroupModel[];
    onSuccess: () => void;
    updateGroupChange: (data) => void;
    onDeleteGroup: (id) => void;
    getNormalUserList: () => void;
    normalUserList: SourceType[];
    getAllProjectList: () => void;
    allProjectList: SourceType[];
}

interface States {
    formData: TerrainModel;
    validation: boolean;
}

class HTerrainForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                dataFormat: 'a3x',
                accuracyGrade: null,
                coordinate: 1,
                fileUrl: '',
                picUrl: '',
                groupId: undefined, //暂时
                size: null,
                isPublic: 1,
                name: '',
                principal: '',
                projectCode: undefined,
                scout: '',
                scoutDate: undefined,
                unit: '',
                type: 0
            },
            validation: false
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
        this.props.getNormalUserList();
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (
            this.props.normalUserList !== prevProps.normalUserList ||
            this.props.allProjectList !== prevProps.allProjectList ||
            this.props.editData !== prevProps.editData
        ) {
            this.setData();
        }
    }

    setData = () => {
        const { editData, isAdd } = this.props;
        if (!isAdd && editData) {
            const { formData } = this.state;
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
            });
            formData['id'] = editData.id;
            this.setState({
                formData
            });
        }
    };

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData } = this.state;
                const { projectCode, coordinate, fileUrl, groupId } = formData;
                if (projectCode && coordinate && fileUrl && groupId) {
                    TerrainService.edit(formData).then(res => {
                        if (res['code'] === 200) {
                            message.success(`${formData.id ? '修改' : '添加'}成功`);
                            this.props.onSuccess();
                        }
                    });
                }
            }
        );
    };

    render() {
        const { isAdd, allProjectList, normalUserList, groupList, addGroup, updateGroupChange, onDeleteGroup } = this.props;
        const {
            formData: { accuracyGrade, coordinate, groupId, fileUrl, size, name, projectCode, principal, scout, scoutDate, unit, isPublic },
            validation
        } = this.state;
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput label={'勘察单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                <HSelect label={'负责人'} value={principal} dataSource={normalUserList} onChange={selected => this.onChange('principal', selected)} />
                <HInput label={'勘察员'} value={scout} onChange={e => this.onChange('scout', e.target.value)} />
                <HLabelInput label={'勘察时间'}>
                    <div style={{ width: '100%' }}>
                        <HIcon iconName={'icon-time-circle'} style={{ color: 'rgba(53, 198, 79, 1)', fontSize: 12 }} />
                        <DatePicker
                            format={'YYYY/MM/DD HH:mm'}
                            showTime
                            bordered={false}
                            placeholder={'选择日期'}
                            suffixIcon={''}
                            value={scoutDate ? moment(scoutDate, 'YYYY/MM/DD HH:mm') : scoutDate}
                            onChange={(_, dateString) => this.onChange('scoutDate', dateString)}
                        />
                    </div>
                </HLabelInput>
                <HInput
                    label={'文件路径'}
                    rule={{ required: true, validation, message: '请输入文件地址' }}
                    value={fileUrl}
                    placeholder="文件Url"
                    onChange={e => this.onChange('fileUrl', e.target.value)}
                />
                <HLabelInput label={'精度等级'}>
                    <Group compact>
                        <InputNumber
                            style={{ width: 164 }}
                            size="small"
                            value={accuracyGrade}
                            onChange={value => this.onChange('accuracyGrade', value)}
                        />
                        <Input size={'small'} style={{ width: 48 }} readOnly value={AccuracyUnit} />
                    </Group>
                </HLabelInput>
                <HLabelInput label={'数据大小'}>
                    <Group compact>
                        <InputNumber style={{ width: 186 }} size="small" value={size} onChange={value => this.onChange('size', value)} />
                        <Input size={'small'} style={{ width: 26 }} readOnly value={'GB'} />
                    </Group>
                </HLabelInput>
                <HSelect label={'坐标系'} value={coordinate} dataSource={CoordinateTypeList} onChange={value => this.onChange('coordinate', value)} />
                {isPublic === 1 ? (
                    <HLabelInput label={'分组'} required={true}>
                        <HAddItemSelect
                            dataSource={groupList}
                            add={addGroup}
                            value={groupId}
                            isForm={true}
                            rule={{ required: true, validation, message: '请选择分组' }}
                            onChange={value => this.onChange('groupId', value)}
                            maxWidth={212}
                            minWidth={212}
                            saveChange={updateGroupChange}
                            onDelete={onDeleteGroup}
                        />
                    </HLabelInput>
                ) : null}
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    normalUserList: state.getIn(['upload', 'normalUserList']),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getNormalUserList() {
        dispatch(actionCreators.getNormalUserList());
    },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(HTerrainForm);
