/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component, useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Switch, message } from 'antd';
import { ReferenceModel, SysModel } from './model';
import { HInfoLine, HIcon, HContentCollapse } from '../../../components';
import axios from 'axios';
import { LinkItem } from '../../setup/system/base';
import SystemService from '../../../services/system';

interface Props {
    data: SysModel;
}

interface States {
    referenceList: ReferenceModel[];
    unLock: boolean;
}

// todo 现有数据结构不满足功能需求，后端配合修改
const Obj = {
    isOpen: 0,
    name: '',
    type: ''
};

export default class Reference extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            referenceList: [],
            unLock: false
        };
    }

    componentDidMount(): void {
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.data !== this.props.data) {
            this.setData();
        }
    }

    setData = () => {
        const { data } = this.props;
        if (data) {
            this.setState({
                referenceList: data.referenceList || []
            });
        }
    };

    lockClick = e => {
        e.stopPropagation();
        this.setState({
            unLock: !this.state.unLock
        });
    };

    onAdd = () => {
        const { referenceList } = this.state;
        referenceList.push({ ...Obj });
        this.setState({
            referenceList
        });
    };

    onDelete = (data, index) => {};
    onChange = (data, i) => {};

    save() {
        const { data } = this.props;
        const { referenceList } = this.state;
        SystemService.edit({ ...data, referenceList }).then(r => {
            message.success(r.data);
            this.setState({
                unLock: false
            });
        });
    }

    render() {
        const { referenceList, unLock } = this.state;
        const { data } = this.props;
        // console.log(referenceList, data);
        return (
            <HContentCollapse
                title={'引用源'}
                style={{
                    height: '192px'
                }}
                suffix={<HIcon iconName={unLock ? 'icon-unlock' : 'icon-lock'} onClick={this.lockClick} />}
            >
                <Row gutter={48}>
                    <Col span={12}>
                        {referenceList.map((item, i) => {
                            return (
                                <LinkItem
                                    key={i}
                                    data={item}
                                    unLock={unLock}
                                    isLast={referenceList.length == i + 1}
                                    onAdd={this.onAdd}
                                    onDelete={() => this.onDelete(item, i)}
                                    onChange={() => this.onChange(item, i)}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </HContentCollapse>
        );
    }
}
