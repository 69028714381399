/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: 用Cesium实现二维地图，给页面为地图引擎的初始化文件
 * @Date: 2022-05-05 09:54:52
 * @LastEditTime: 2022-05-14 12:03:14
 */

import { CustomMaterialProperty, Color } from 'cesium';
import Cesiums from "./Cesium";
import { fromLonLat, toLonLat } from 'ol/proj';
import { mercator2towgs84 } from './tool';
import entities from './entities';
import Events from './event';
import KmlData from './kmlData';

export default class Ces2d extends Cesiums {

    constructor() {
        super();
    }
    /**
     * @description: 创建Cesium容器对象，之后的标签、模型、地图等数据都会附加在其中
     * @param {* }
     * @return {* Viewer}
     */
    Viewers = this.Viewers;
    /**
     * @description: 初始化地图对象
     * @param {*}
     * @return {*}
     */
    setInitSence = this.setInitSence;

    //设置视角
    setView() {
        this.viewer.camera.setView({
            destination: this.Cesium.Cartesian3.fromDegrees(119.142105796, 25.211389225, 115000.0)
        });
    }
    /**
     * @description: 获取当前视角参数
     * @param {*}
     * @return {*}
     */
    getView = () => {

        const { x, y, z } = this.viewer.camera.position;

        const { heading, pitch, roll, frustum } = this.viewer.camera;

        const ellipsoid = this.viewer.scene.globe.ellipsoid;
        const cartesian3 = new this.Cesium.Cartesian3(x, y, z);
        const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
        const lat = this.Cesium.Math.toDegrees(cartographic.latitude);
        const lng = this.Cesium.Math.toDegrees(cartographic.longitude);
        const alt = cartographic.height;

        // Create a camera looking down the negative z-axis, positioned at the origin,
        // with a field of view of 60 degrees, and 1:1 aspect ratio.
        const camera = new this.Cesium.Camera(this.viewer.scene);
        camera.position = new this.Cesium.Cartesian3();
        camera.direction = this.Cesium.Cartesian3.negate(this.Cesium.Cartesian3.UNIT_Z, new this.Cesium.Cartesian3());
        camera.up = this.Cesium.Cartesian3.clone(this.Cesium.Cartesian3.UNIT_Y);
        camera.frustum.fov = 0;
        camera.frustum.near = 1.0;
        camera.frustum.far = 2.0;



    }
    /**
     * @description: 移动到指定坐标位置相机
     * @param {*} postiton
     * @return {*}
     */
    flyTo = (postiton) => {

        this.viewer.camera.flyTo({
            destination: this.Cesium.Cartesian3.fromDegrees(...postiton),
            duration: 0.5,
        });
    }

    /**
     * @description: 将坐标转换为经纬度
     * @param {*}
     * @return {*}
     */
    toLonLat = mercator2towgs84;
    /**
     * @description: 3857--》84
     * @param {*}
     * @return {*}
     */
    fromLonLat = fromLonLat;
    /**
     * @description: 创建动态流水线材质
     * @param {*}
     * @return {*}
     */
    CustomMaterialProperty = CustomMaterialProperty;
    /**
     * @description: 创建颜色，返回Cesium里面的颜色
     * @param {*} a 红
     * @param {*} b 黄
     * @param {*} c 蓝
     * @param {*} d 透明通道
     * @return {*} Color
     */
    Color = (a, b, c, d) => { return new Color(a / 255, b / 255, c / 255, d); };
    /**
     * @description: 创建实体
     * @param {*}
     * @return {*} 返回实体对象
     */
    entities = entities;


    /**
     * @description: 获取图层更新显示隐藏
     * @param {*}
     * @return {*}
     */
    setLayer = (index, status) => {
        this.viewer.imageryLayers.get(index).show = status;
    }
    /**
     * @description: 所有事件在此引用
     * @param {*}
     * @return {*}
     */
    Events = Events
    /**
     * @description: kml数据加载
     * @param {*}
     * @return {*}
     */
    KmlData = KmlData;
}