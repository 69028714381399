import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FillTable, HContentLeft, TableLine } from '../../../components';
import { actionCreators } from '../store';
import InfiniteScroll from 'react-infinite-scroller';
import ProjectService from '../../../services/project';

interface Props {
    keyword: string;
    dataSource: any[];
    count: number;
    getChild1Data: (value, current, data?) => void;
}

/**
 * @description                     我的填报
 * @param keyword                   搜索内容
 */

const Child2 = ({ keyword, dataSource, count, getChild1Data }: Props) => {

    const [hasMore, setHasMore] = useState(true);

    const [activeId, setActiveId] = useState(0);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getChild1Data(keyword, 1);
    }, []);

    const thandleInfiniteOnLoad = (page) => {
        if (page > count) {
            setHasMore(false);
        } else {
            getChild1Data(keyword, page, dataSource);
        }
    };

    const tableLineClick = (value) => {
        ProjectService.getMissionQuery({
            projectCode: value.projectCode,
            type: 1
        }).then(res => {
            setTableData(res.data);
            setActiveId(value.id);
        });
    };

    const addMain = (value) => {
        const editData = {
            "finishNumber": 0,
            "mainId": -1,
            "missionNumber": 0,
            "principal": "",
            "projectCode": value.projectCode,
            "remark": "",
            "workTime": null,
            "title": "新增任务",
            "unit": "",
            "type": 1
        };

        ProjectService.editMissionData(editData).then(res => {
            tableLineClick(value);
        });
    };

    return <>
        <HContentLeft>
            <InfiniteScroll initialLoad={false} pageStart={1} loadMore={thandleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                {dataSource.map((r) => {
                    return (
                        <TableLine
                            key={r.id}
                            data={r}
                            imgUrl={r.logoUrl}
                            showDate={false}
                            buttonList={[
                                {
                                    iconName: 'icon-plus-circle',
                                    activeIcon: 'icon-edit-fill',
                                    onClick: (event, key) => {
                                        event.stopPropagation();
                                        addMain(r);
                                    }
                                }
                            ]}
                            activeTable={activeId}
                            onClick={() => {
                                if (r.id === activeId) {
                                    setTableData([]);
                                    setActiveId(0);
                                } else {
                                    tableLineClick(r);
                                }
                            }}
                        >
                            {r.id === activeId && <FillTable
                                type={1}
                                data={tableData}
                                getData={() => tableLineClick(r)}
                            />}
                        </TableLine>
                    );
                })}
            </InfiniteScroll>
        </HContentLeft>
    </>;
};

const mapState = state => ({
    dataSource: state.getIn(['project', 'dataSource']),
    count: state.getIn(['project', 'count'])
});

const mapDispatch = dispatch => ({
    getChild1Data(keywords, current, data) {
        dispatch(actionCreators.getChild1Data(keywords, current, data));
    },
});

export default connect(mapState, mapDispatch)(Child2);