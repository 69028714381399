/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Input, message } from 'antd';
import { HButton, HCollapse, HLabelInput } from '../../../components';
import { FunctionModel, UserModel } from './model';
import { HInfoLine, HSelect, HInput } from '../../../components';
import axios from 'axios';
import { AvataColorMap, GenderList, UploadDataType, UserTypeList, UserTypeMap } from '../../../utils/static-data';
import HUploadThumbnail from '../../../components/upload';
import { UserService } from '../../../services/user';

const leaderInfoTypeList = [UserTypeMap.leader, UserTypeMap.administrator];

interface Props {
    isAdd: boolean;
    functionList: FunctionModel[];
    closeDrawer: () => void;
    onSuccess: () => void;
    editData?: UserModel;
}

interface UserFunModel {
    functionCode: number;
    functionName: string;
    validPeriod: number;
}

interface States {
    formData: UserModel;
    uFList: UserFunModel[];
    validation: boolean;
}

// const config={}
class HUserForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                account: '',
                authorityList: [],
                department: '',
                name: '',
                gender: undefined,
                iconColor: AvataColorMap[Math.round(Math.random() * 10)], //0-10随机取
                password: '',
                phone: '',
                remark: '',
                type: undefined, //账户类型（1：普通用户、2：领导、3：管理员、4：审查员）
                typeName: '',
                unit: '',
                leaderMainTitle: '',
                leaderSubTitle: '',
                leaderLogoUrl: ''
            },
            uFList: [],
            validation: false
        };
    }

    componentDidMount(): void {
        this.setAuthorityList(0);
        this.setData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.editData !== this.props.editData) {
            this.setData();
        }
    }

    setData = () => {
        const { isAdd, editData } = this.props;
        if (!isAdd && editData) {
            if (editData.authorityList.length > 0) {
                this.setState(
                    {
                        uFList: []
                    },
                    () => {
                        const list = [];
                        editData['authorityList'].forEach((it, i) => {
                            const item = this.setAuthorityList(it.validPeriod, it.functionCode);
                            list.push(item);
                            if (i === editData['authorityList'].length - 1) {
                                this.setState({
                                    uFList: list
                                });
                            }
                        });
                    }
                );
            } else {
                this.setAuthorityList(0);
            }
            const { formData } = this.state;
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
                if (key === 'iconColor' && !editData[key]) {
                    formData[key] = AvataColorMap[Math.round(Math.random() * 10)];
                }
            });
            formData['id'] = editData.id;
            this.setState({
                formData
            });
        }
    };

    // 设置功能权限列表
    setAuthorityList = (value?, code?) => {
        const { functionList } = this.props;
        let funList;
        let list;
        if (code) {
            funList = functionList.filter(it => it.functionCode === code);
            return { ...funList[0], validPeriod: value };
        } else {
            funList = functionList;
            list = funList.map(it => {
                return { ...it, validPeriod: value || 0 };
            });
            this.setState({
                uFList: list
            });
        }
    };

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({ formData });
    };

    onGenderChange = selected => {
        const { formData } = this.state;
        formData.gender = selected;
        this.setState({ formData });
    };

    onSelectChange = (selected: number, options) => {
        const { formData } = this.state;
        formData.type = selected;
        formData.typeName = options ? options.children : undefined;
        if (!leaderInfoTypeList.includes(selected)) {
            formData.leaderMainTitle = '';
            formData.leaderSubTitle = '';
            formData.leaderLogoUrl = '';
        }
        this.setState({
            formData
        });
    };

    onFunListChange = (code, value) => {
        const { uFList } = this.state;
        uFList.forEach(it => {
            if (it.functionCode === code) {
                it.validPeriod = value;
            }
        });
        this.setState({ uFList });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData, uFList } = this.state;
                formData.authorityList = uFList.map(it => {
                    return { functionCode: it.functionCode, validPeriod: it.validPeriod };
                });
                const { name, account, password, type } = formData;
                if (name && account && password && type) {
                    UserService.edit(formData).then(() => {
                        message.success(`${formData.id ? '修改' : '添加'}成功`);
                        this.props.closeDrawer();
                        this.props.onSuccess();
                    });
                }
            }
        );
    };

    getBgColor(code) {
        switch (code) {
            case 16:
            case 17:
            case 18:
                return 'rgba(239, 240, 252, 1)';
            case 19:
            case 20:
                return 'rgba(253, 240, 240, 1)';
            default:
                return 'rgba(240, 253, 245, 1)';
        }
    }

    render() {
        const { isAdd } = this.props;
        const {
            formData: { account, name, gender, department, password, phone, type, unit, leaderMainTitle, leaderSubTitle, leaderLogoUrl },
            uFList,
            validation
        } = this.state;
        return (
            <div>
                <HCollapse title={'基本信息'}>
                    <HInput
                        label={'姓名'}
                        value={name}
                        rule={{ required: true, validation, message: '请输入姓名' }}
                        onChange={e => this.onChange('name', e.target.value)}
                    />
                    <HSelect label={'性别'} dataSource={GenderList} value={gender} onChange={selected => this.onGenderChange(selected)} />
                    <HInput label={'单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                    <HInput label={'部门'} value={department} onChange={e => this.onChange('department', e.target.value)} />
                    <HInput label={'手机'} value={phone} onChange={e => this.onChange('phone', e.target.value)} />
                    <HSelect
                        label={'账户类型'}
                        rule={{ required: true, validation, message: '请选择账户类型' }}
                        validation={validation}
                        dataSource={UserTypeList}
                        value={type}
                        onChange={this.onSelectChange}
                    />
                    <HInput
                        label={'账户名称'}
                        rule={{ required: true, validation, message: '请输入账户名称' }}
                        value={account}
                        onChange={e => this.onChange('account', e.target.value)}
                    />
                    <HInput
                        label={`${isAdd ? '初始' : ''}密码`}
                        password={true}
                        rule={{
                            required: true,
                            validation,
                            message: `请输入${isAdd ? '初始' : ''}密码`
                        }}
                        value={password}
                        onChange={e => this.onChange('password', e.target.value)}
                    />
                    {leaderInfoTypeList.includes(type) && (
                        <RenderLeaderContent
                            leaderMainTitle={leaderMainTitle}
                            leaderSubTitle={leaderSubTitle}
                            leaderLogoUrl={leaderLogoUrl}
                            onChange={this.onChange}
                        />
                    )}
                    <div className={'h-divider-label'}>功能权限</div>
                    {uFList.map((it, i) => {
                        return (
                            <HInfoLine
                                key={i}
                                title={it.functionName}
                                value={it.validPeriod}
                                share={true}
                                suffixBgColor={this.getBgColor(it.functionCode)}
                                onChange={value => this.onFunListChange(it.functionCode, value)}
                            />
                        );
                    })}
                </HCollapse>
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </div>
        );
    }
}

export default HUserForm;

const RenderLeaderContent = ({ leaderMainTitle, leaderSubTitle, leaderLogoUrl, onChange }) => {
    return (
        <>
            <div className={'h-divider-label'}>领导页设置</div>
            <HInput label={'主标题'} value={leaderMainTitle} onChange={e => onChange('leaderMainTitle', e.target.value)} />
            <HInput label={'副标题'} value={leaderSubTitle} onChange={e => onChange('leaderSubTitle', e.target.value)} />
            <HLabelInput label={'logo'}>
                <HUploadThumbnail type={UploadDataType.pic} defaultUrl={leaderLogoUrl} onChange={url => onChange('leaderLogoUrl', url)} />
            </HLabelInput>
        </>
    );
};
