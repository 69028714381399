import React, { Component } from 'react';
import $ from 'jquery';
import './test.scss';

interface Props {
    toolBarVisible: boolean;
}

interface State {
    dataText: any;
    tabKey: boolean;
}

class IndexPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const ion_url = 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng223eb53cbd936a88db2d789d5965db15acd7dbd614e8446754fd03a05b2cf96a';
        this.state = {
            dataText: {
                dropnum: 0,
                solute_logo_bg: '',
                dropnumis_: ion_url,
                dropnumtwois_: ion_url,
                dropnumfouris_: ion_url,
                solute_top_text: '7.5',
                solute_top_icon:
                    'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4767b2898323ecdf77736a630d88e3a89a3648368ed477571812aae94da9616a',
                solute_top_checkbox_is_: ion_url,
                solute_bottom_name: '紫云路',
                solute_bottom_text: '溶解氧',
                solute_bottom_date: '2021/03/23 23:45',
                dropdowndata: [
                    {
                        id: 1,
                        name: '大楼',
                        btn: ion_url
                    },
                    {
                        id: 2,
                        name: '王大陆',
                        btn: ion_url
                    },
                    {
                        id: 3,
                        name: '王晓天',
                        btn: ion_url
                    },
                    {
                        id: 2,
                        name: '王陆',
                        btn: ion_url
                    },
                    {
                        id: 5,
                        name: '王天',
                        btn: ion_url
                    },
                    {
                        id: 6,
                        name: '来露',
                        btn: ion_url
                    }
                ],
                dropdownoutdata: []
            },
            tabKey: true
        };
    }

    /**
     * @description 改变工具栏显示状态
     * @param key
     */
    changeToolBarVisible = key => {
        const { dataText, tabKey } = this.state;
        this.setState({
            tabKey: !key
        });
        if (tabKey) {
            dataText.solute_top_checkbox_is_ =
                'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf154c0a3af25d39bced7ed82ab2756974d3e2306dab23e023469dc1dedf3b48e';
        } else {
            dataText.solute_top_checkbox_is_ =
                'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng223eb53cbd936a88db2d789d5965db15acd7dbd614e8446754fd03a05b2cf96a';
        }
        this.setState({
            dataText
        });
    };

    /**
     * @description 渲染图表
     * @param key
     */
    chart = () => {
        // console.log('渲染图表');
    };
    /**
     * @description 获取人数选择
     * @param num
     */
    dropDownNumOnClick = num => {
        const drop_bg1 = 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf154c0a3af25d39bced7ed82ab2756974d3e2306dab23e023469dc1dedf3b48e';
        const drop_bg2 = 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng223eb53cbd936a88db2d789d5965db15acd7dbd614e8446754fd03a05b2cf96a';

        const { dataText } = this.state;
        dataText.dropnum = num;
        dataText.dropnum === 2
            ? (dataText.dropnumtwois_ = drop_bg1) && (dataText.dropnumfouris_ = drop_bg2)
            : (dataText.dropnumtwois_ = drop_bg2) && (dataText.dropnumfouris_ = drop_bg1);
        dataText.dropnumis_ = 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng23f432c397d2f00e3d7248c0e516608b772c41552c48606deec52f415c995584';
        for (let i = 0; i < dataText.dropdowndata.length; i++) {
            dataText.dropdowndata[i].btn = drop_bg2;
        }
        dataText.dropnum_state = 0;
        dataText.dropdownoutdata = [];

        this.setState({
            dataText
        });
    };
    /**
     * @description 获取已选择人员信息，并实时更新，数据保存在dataText.drop_down_out_data
     * @param key
     */
    dropdowntext_onClick = value => {
        const { dataText } = this.state;
        for (let i = 0; i < dataText.dropdownoutdata.length; i++) {
            if (dataText.dropdowndata[value].name === dataText.dropdownoutdata[i]) {
                dataText.dropdowndata[value].btn =
                    'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng223eb53cbd936a88db2d789d5965db15acd7dbd614e8446754fd03a05b2cf96a';
                dataText.dropdownoutdata.splice(i, 1);
                this.setState({
                    dataText
                });
                return;
            }
        }

        if (dataText.dropnum > dataText.dropdownoutdata.length) {
            dataText.dropdownoutdata.push(dataText.dropdowndata[value].name);
            dataText.drop_text = dataText.drop_text + dataText.dropdowndata[value].name;
            dataText.dropdowndata[value].btn =
                'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf154c0a3af25d39bced7ed82ab2756974d3e2306dab23e023469dc1dedf3b48e';
            this.setState({
                dataText
            });
        }
    };

    render() {
        const { dataText, tabKey } = this.state;

        return (
            <>
                <HWaterSolute data={dataText} tabKey={tabKey} chart_onClick={this.chart} checkbox_onClick={this.changeToolBarVisible} />
                <br></br>
                <DropDownBox data={dataText} dropnum_onClick={this.dropDownNumOnClick} droptext_onClick={this.dropdowntext_onClick} />
            </>
        );
    }
}

export default IndexPage;

/**
 * @description:  水质检测溶解物模块
 * @param {*} data = {
 *              solute_logo_bg(logo url)，
 *              solute_top_text(体积大小),
 *              solute_top_icon(图标url),
 *              solute_top_checkbox_is_(复选框url),
 *              solute_bottom_name(地名),
 *              solute_bottom_text(溶质),
 *              solute_bottom_date(日期),
 *             }
 */
interface HWaterSoluteProps {
    data: any;
    tabKey: boolean;
    chart_onClick?: () => void;
    checkbox_onClick?: (value) => void;
}

export const HWaterSolute = ({ data, ...rest }: HWaterSoluteProps) => {
    return (
        <div className={'h-water-solute'}>
            <div className={'h-solute-logo'}>
                <img src={data.solute_logo_bg} alt="" />
            </div>
            <div className={'h-solute-top'}>
                <span>{data.solute_top_text}</span> <span>mg/l</span>
                <span>
                    <img
                        onClick={() => {
                            rest.chart_onClick();
                        }}
                        src={data.solute_top_icon}
                        alt=""
                    />
                    <img
                        onClick={() => {
                            rest.checkbox_onClick(rest.tabKey);
                        }}
                        src={data.solute_top_checkbox_is_}
                        alt=""
                    />
                </span>
            </div>
            <div className={'h-solute-bottom'}>
                <span className="span-left">
                    {data.solute_bottom_name} {data.solute_bottom_text}
                </span>
                <span className="span-right">{data.solute_bottom_date}</span>
            </div>
        </div>
    );
};

/**
 * @description:  下拉框选人
 * @param {*} data = {
 *              dropnum(人数2/4)，
 *              dropnum_is_(人数2/4图标url),
 *              dropnum_two_is_(人数2图标url),
 *              dropnum_four_is_(人数4图标url),
 *              drop_down_data(人员信息json{name,btn(图标)}),
 *              drop_down_out_data(返回人员名单[])
 *             }
 */
interface DropDownBoxProps {
    data: any;
    dropnum_onClick?: (value) => void;
    droptext_onClick?: (value) => void;
}

export const DropDownBox = ({ data, ...rest }: DropDownBoxProps) => {
    /**
     * 实现动态下拉框
     */
    let _dropdownbox = true;
    let _dropdownboxnum = true;

    // 显示隐藏下拉框
    function dropDownBoxShow() {
        _dropdownbox
            ? $('.h-dropdown-num').show() && $('.h-prompt-is-').hide()
            : $('.h-dropdown-num').hide() && (data.dropdownoutdata.length == 0 ? $('.h-prompt-is-').show() : $('.h-prompt-is-').hide());
        _dropdownbox = !_dropdownbox;
    }

    function numDropDownBoxShow() {
        _dropdownboxnum ? $('.h-dropdown-num-text').show() : $('.h-dropdown-num-text').hide();
        _dropdownboxnum = !_dropdownboxnum;
    }

    return (
        <div className={'h-dropdown'}>
            <div className={'h-dropdown-btn'} onClick={dropDownBoxShow}>
                <span className={'h-prompt-is-'}>请输入</span>
                <span className={'h-prompt'}>{data.dropdownoutdata}</span>
                <img
                    className={'h-dropdown-ioc'}
                    alt=""
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng86b6854876feeb0ee9bd207c0c5e7ed5a01c5ef2dcaebaf9077270e6f538dc2c"
                />
            </div>
            <div className={'h-dropdown-num'}>
                <div className={'h-dropdown-num-btn'} onClick={numDropDownBoxShow}>
                    <img src={data.dropnumis_} alt="" />
                    <img
                        className={'h-drop-num-ioc'}
                        alt=""
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng86b6854876feeb0ee9bd207c0c5e7ed5a01c5ef2dcaebaf9077270e6f538dc2c"
                    />
                    <span>2/4人</span>
                </div>
                <div className={'h-dropdown-num-text'}>
                    <ul>
                        <li>
                            <img
                                onClick={() => {
                                    rest.dropnum_onClick(2);
                                }}
                                src={data.dropnumtwois_}
                                alt=""
                            />
                            <span>2人</span>
                        </li>
                        <li>
                            <img
                                onClick={() => {
                                    rest.dropnum_onClick(4);
                                }}
                                src={data.dropnumfouris_}
                                alt=""
                            />
                            <span>4人</span>
                        </li>
                    </ul>
                </div>
                <ul className={'h-dropdown-num-ul'}>
                    {data.dropdowndata.map((drop_data, index) => {
                        return (
                            <li key={index} id={drop_data.id}>
                                <img
                                    onClick={() => {
                                        rest.droptext_onClick(index);
                                    }}
                                    src={drop_data.btn}
                                    alt=""
                                />
                                <span>{drop_data.name}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
