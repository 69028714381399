/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HInfoLine } from '../../../components';

const isDev = process.env.NODE_ENV === 'development';

interface Props {
    data: any;
}

interface State {
    dataSource: any[];
}

class Monitoring extends Component<Props, State> {
    ws;
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
    }

    componentDidMount(): void {
        this.setWebSocket();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.data.code !== this.props.data.code) {
            this.ws.close();
            this.setWebSocket();
        }
    }

    componentWillUnmount(): void {
        this.ws.close();
    }

    setWebSocket = () => {
        const { data } = this.props;
        const webSocketUrl = isDev ? `ws://192.168.1.83:8090/api/msg?code=${data.code}` : `ws://47.102.153.51:8090/api/msg?code=${data.code}`;
        const ws = new WebSocket(webSocketUrl);

        ws.onopen = () => {
            // console.log('连接成功');
        };

        ws.onmessage = e => {
            const data = JSON.parse(e.data).data.data;
            this.setState({ dataSource: data });
        };

        this.ws = ws;
    };

    render() {
        const { dataSource } = this.state;
        return (
            <div>
                {dataSource.map((it, i) => {
                    return <HInfoLine key={i} title={it.devName} value={it.devValue} titleStyle={{ width: '160px' }} />;
                })}
            </div>
        );
    }
}

export default Monitoring;
