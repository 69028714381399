import React, { Component } from 'react';
import { Tabs } from 'antd';
import LoginLog from './LoginLog';
import LogDetail from './LogDetail';
import LoginDoc from './LoginDoc';
import './index.scss';

const { TabPane } = Tabs;

class Log extends Component {
    render() {
        return (
            <div>
                <div className="log-table">
                    <div>
                        <Tabs animated={{ inkBar: false, tabPane: false }}>
                            <TabPane tab="登录日志" key="1">
                                <LoginLog />
                            </TabPane>
                            <TabPane tab="日志详情" key="2">
                                <LogDetail />
                            </TabPane>
                            <TabPane tab="文档日志" key="3">
                                <LoginDoc />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default Log;
