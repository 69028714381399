/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: 创建KML对象，实例化KML加载到场景中去
 * @Date: 2022-05-07 09:36:01
 * @LastEditTime: 2022-05-14 12:02:41
 */
import CesiumInit from "./Cesium";



export default class KmlData extends CesiumInit {
    /**
     * @description: 加载kml容器
     * @param {*}
     * @return {*}
     */
    viewer: any;
    /**
     * @description: 全局Cesium
     * @param {*}
     * @return {*}
     */
    Cesium: any = this.Cesium;

    constructor(viewer) {
        super();
        this.viewer = viewer;
    }
    /**
    * @description: 传入相关参数，可以将KML直接添加到场景中去
    * @param {* clampToGround} //开启贴地，默认开启
    * @param {* event} 返回事件
    * @return {*}
    */
    add = (
        {
            url,
            clampToGround = true
        },
        event: (adKml) => void
    ) => {
        // 判断没有url则进行返回
        if (!url) return;
        // 初始化kml参数
        const options = {
            clampToGround: clampToGround
        };
        /**
         * 用KmlDataSource创建KML
         * dataSources.add进行添加
         */
        const adKml = this.Cesium.KmlDataSource.load(url, options);
        this.viewer.dataSources.add(
            adKml
        ).then(event);
    }
}