/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Elon
 * @description: 描述页面内容
 * @Date: 2022-05-09 17:14:25
 * @LastEditTime: 2022-05-14 13:17:26
 */
// {/* @ts-ignore */ }

import Lottie from 'react-lottie';
import React from 'react';
import ReactDOM from 'react-dom';
import { IconAnm } from "./data";


export const Lett = (ulEle, size, src, id, onclick) => {
    const bo = document.getElementById('mapContainer');
    bo.insertBefore(ulEle, bo.lastChild);


    /**
     * @description: 将实例化之后的组件添加到dom树中
     * @param {*}
     * @return {*}
     */

    ReactDOM.render(<Lottie
        options={{
            loop: true,
            autoplay: true,
            animationData: IconAnm[src].utl,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }}
        width={size}
        height={size}
    />, ulEle);


    const ulEle_child = document.createElement('div');
    const style = document.createAttribute("style");
    ulEle.insertBefore(ulEle_child, ulEle.lastChild);

    ulEle_child.setAttributeNode(style);
    ulEle_child.style.width = `${size}px`;
    ulEle_child.style.height = `${size}px`;
    ulEle_child.style.position = 'absolute';
    ulEle_child.style.top = '0';
    ulEle_child.style.zIndex = '10';
    ulEle_child.style.backgroundColor = '#fff0';
    ulEle_child.onclick = onclick;

};