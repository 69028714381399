import React, { Component } from 'react';
import {
    HDrawer,
    TableLine,
    HCollapse,
    HDescriptions,
    HContentLeft,
    HContentRight,
    HDataOverview,
    HIcon,
    HInfoLine,
    HDataTopBar
} from '../../../components';
import { Button, message } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { DataCount, GetListParam } from '../store/model';
import HTerrainForm from './form';
import { GroupModel } from '../../../components/model';
import { AccuracyUnit, GroupDataTypeMap } from '../../../utils/static-data';
import GroupService from '../../../services/group';
import { TerrainService } from '../../../services/terrain';

const rightList = [
    {
        key: 'unit',
        title: '勘察单位',
        value: '',
        suffix: { key: 'principal', title: '负责人', value: '' }
    },
    {
        key: 'scoutDate',
        title: '勘察时间',
        value: '',
        suffix: { key: 'scout', title: '勘察员', value: '' }
    },
    {
        key: 'coordinateName',
        title: '坐标系',
        value: ''
    },
    {
        key: 'dataFormat',
        title: '数据格式',
        value: ''
    },
    {
        key: 'size',
        title: '数据大小',
        value: ''
    },
    {
        key: 'accuracyGrade',
        title: '精度等级',
        value: ''
    }
];

interface Props {
    dataSource: any[];
    current: number;
    count: number;
    getList: (data: GetListParam) => void;
    getDataCount: () => void;
    dataCount: DataCount;
    changeList: (list) => void;
    groupList: GroupModel[];
    getGroupList: () => void;
}

interface State {
    isPublic: number;
    isAdd: boolean;
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    shareVisible: boolean;
    shareData: {
        shareHref: string;
        sharePassword: string;
    };
    activeData: any;
    hasMore: boolean;
    isFull: boolean;
    editData: any;
}

class HTerrain extends Component<Props, State> {
    DTCity;
    maps = null;
    terrain = null;

    constructor(props) {
        super(props);
        this.state = {
            isPublic: 1,
            isAdd: false,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            shareVisible: false,
            shareData: {
                shareHref: '',
                sharePassword: ''
            },
            activeData: undefined,
            hasMore: true,
            isFull: false,
            editData: {}
        };
    }

    componentDidMount() {
        const { isPublic } = this.state;
        this.props.getList({ isPublic, current: 1 });
        this.props.getDataCount();
        this.props.getGroupList();
        window['onMapsReady'] = () => {
            this.DTCity = window['DTCity'];
        };
    }

    handleInfiniteOnLoad = page => {
        const { isPublic } = this.state;
        const { dataSource, getList, count } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getList({ isPublic, current: page, data: dataSource });
        }
    };

    onPublicChange = value => {
        this.setState({
            isPublic: value,
            hasMore: true,
            isAdd: false,
            isEdit: false
        });
        this.props.getList({ isPublic: value, current: 1 });
    };

    // editChange = (key, visible, data) => {
    //     this.setState({
    //         isEdit: visible,
    //         shareVisible: false,
    //         isAdd: false,
    //         activeId: visible ? data.id : 0,
    //         activeButton: visible ? key : 0,
    //         activeData: data
    //     })
    // };

    // 分享按钮事件
    onShareChange = (visible, data?, key?) => {
        this.setState({
            shareVisible: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            isAdd: false,
            isEdit: false,
            shareData: visible
                ? {
                    shareHref: data.shareUrl,
                    sharePassword: data.password
                }
                : {
                    shareHref: '',
                    sharePassword: ''
                }
        });
    };

    changeEditVisible = (visible, data?) => {
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? 3 : 0,
            shareVisible: false,
            isAdd: false
        });
    };

    onClickTableLine = data => {
        const { activeId, isEdit, activeData } = this.state;
        if (!isEdit) {
            rightList.map(r => {
                if (r.key === 'accuracyGrade') {
                    r.value = `${data[r.key]}${AccuracyUnit}`;
                } else {
                    r.value = data[r.key];
                    if (r.suffix) r.suffix.value = data[r.suffix.key];
                }
            });
            this.setState(
                {
                    activeId: data.id === activeId ? 0 : data.id,
                    activeData:
                        data.id === activeId
                            ? undefined
                            : {
                                title: data.name,
                                fileUrl: data.fileUrl,
                                list: rightList
                            }
                },
                () => {
                    if (!this.DTCity) {
                        this.DTCity = window['DTCity'];
                    }
                    if (!activeData) {
                        this.maps = new this.DTCity.Maps('map-container', 'settings.json');
                        this.maps.showCompass(this.DTCity.Maps.COMPASS_NONE);
                        this.maps.setGrid(null);
                    }
                    this.terrain && this.maps.removeLayer(this.terrain);
                    this.terrain = new this.DTCity.Layer.TerrainLayer('terrain', data.fileUrl);
                    // this.terrain.setLodHeight("camera");
                    // this.terrain.setLodMinLevel(18)
                    this.terrain.setLodWindowSize(64);
                    this.maps.addLayer(this.terrain);
                }
            );
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        TerrainService.del(id).then(res => {
            if (res['code'] === 200) {
                message.success('删除成功');
                dataSource.splice(key, 1);
                const newList = [...dataSource];
                this.props.changeList(newList);
                this.setState({
                    activeData: id === activeId ? [] : activeData,
                    activeId: id === activeId ? 0 : activeId
                });
            }
        });
    };

    onAdd = () => {
        const { isAdd } = this.state;
        this.setState({
            isAdd: !isAdd,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            editData: {}
        });
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        this.setState({
            isEdit: visible,
            shareVisible: false,
            isAdd: false,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    closeDrawer = () => {
        const { isAdd, isEdit } = this.state;
        if (isAdd) {
            this.setState({
                isAdd: false
            });
        } else if (isEdit) {
            this.onEditChange(false);
        } else {
            this.onShareChange(false);
        }
    };

    onSaveSuccess = () => {
        this.closeDrawer();
        const { isPublic } = this.state;
        this.props.getList({ isPublic, current: 1 });
    };

    toggleFull = () => {
        const { isFull } = this.state;
        this.setState({
            isFull: !isFull
        });
    };

    // 截图
    screenshots = () => {
        const { maps } = this;
        const [width, height] = maps.getCanvasSize();
        maps.takeScreenshot(2 * width, 2 * height).done(imageData => {
            const canvas = document.createElement('canvas');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            const ctx: any = canvas.getContext('2d');
            ctx.putImageData(imageData, 0, 0);
            const dataUrl = canvas.toDataURL('image/png ');
            // console.log(dataUrl);
        });
    };

    groupChange = id => {
        const { isPublic } = this.state;
        this.setState({
            hasMore: true
        });
        const data = id ? { isPublic, groupId: id, current: 1 } : { isPublic, current: 1 };
        this.props.getList(data);
    };

    addGroup = name => {
        GroupService.edit({
            isPublic: 1,
            name,
            type: GroupDataTypeMap.terrain
        }).then(() => {
            this.props.getGroupList();
        });
    };

    updateGroupChange = data => {
        GroupService.edit(data).then(() => {
            this.props.getGroupList();
        });
    };

    onDeleteGroup = id => {
        GroupService.del(id).then(() => {
            this.props.getGroupList();
        });
    };

    render() {
        const { dataSource, dataCount, groupList } = this.props;
        const { isPublic, isAdd, isEdit, activeButton, activeId, shareVisible, shareData, activeData, hasMore, isFull, editData } = this.state;
        // console.log(editData);
        return (
            <>
                <HContentLeft>
                    <HDataTopBar
                        addGroup={this.addGroup}
                        isPublic={isPublic}
                        onPublicChange={this.onPublicChange}
                        onAdd={this.onAdd}
                        groupList={groupList}
                        onGroupChange={this.groupChange}
                    />
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                        {dataSource.map((r, i) => {
                            return (
                                <TableLine
                                    key={r.id}
                                    data={r}
                                    imgUrl={r.picUrl}
                                    isPublic={isPublic}
                                    showDate={true}
                                    buttonList={[
                                        {
                                            iconName: 'icon-select',
                                            activeIcon: 'icon-select',
                                            onClick: (e, key) => {
                                                // e.stopPropagation();
                                            }
                                        },
                                        {
                                            iconName: 'icon-edit',
                                            activeIcon: 'icon-edit-fill',
                                            onClick: (e, key) => {
                                                // e.stopPropagation();
                                                this.onEditChange(true, r, key);
                                            }
                                        }
                                    ]}
                                    activeButton={activeButton}
                                    isButtonActive={r.id === activeId}
                                    activeTable={activeId}
                                    onDelete={() => this.onDelete(r.id, i)}
                                    onClick={() => {
                                        this.onClickTableLine(r);
                                    }}
                                />
                            );
                        })}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {activeData && activeData.list ? (
                        <div>
                            <div className={'h-content-right-map'}>
                                <div className="h-map-size" id="map-container" />
                                <div className={'h-map-tools'}>
                                    <HIcon iconName={'icon-camera'} onClick={this.screenshots} />
                                    <HIcon iconName={isFull ? 'icon-fullscreen-exit' : 'icon-fullscreen'} onClick={this.toggleFull} />
                                </div>
                            </div>
                            <HDescriptions title={activeData.title}>
                                {activeData.list.map((it, i) => {
                                    return (
                                        <HInfoLine
                                            key={i}
                                            title={it.title}
                                            value={it.value}
                                            suffixWidth={150}
                                            suffixBgColor={it.suffix ? 'rgba(240, 247, 253, 1)' : '#FBFBFB'}
                                            suffix={
                                                it.suffix ? (
                                                    <span>
                                                        {it.suffix.title}：{it.suffix.value}
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    );
                                })}
                            </HDescriptions>
                        </div>
                    ) : (
                        <HDataOverview title={'地形数据总览'} data={dataCount} />
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-upload'} title={'上传'} shutDown={this.closeDrawer}>
                        <HTerrainForm
                            isAdd={isAdd}
                            addGroup={this.addGroup}
                            groupList={groupList}
                            onSuccess={this.onSaveSuccess}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                        />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑'} shutDown={this.closeDrawer}>
                        <HTerrainForm
                            editData={editData}
                            addGroup={this.addGroup}
                            isAdd={false}
                            groupList={groupList}
                            onSuccess={this.onSaveSuccess}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                        />
                    </HDrawer>
                )}

                {shareVisible && (
                    <HDrawer iconName={'icon-link'} title={'复制分享链接'} visible={shareVisible} shutDown={this.closeDrawer}>
                        <HCollapse title={'分享信息'}>
                            <div className={'share-data'}>
                                <div>
                                    <div>链接地址:</div>
                                    <a href={shareData.shareHref} target={'_blank'} rel="noreferrer">
                                        {shareData.shareHref}
                                    </a>
                                </div>
                                <div>
                                    <div>密码:</div>
                                    <div className={'share-data-password'}>{shareData.sharePassword}</div>
                                </div>
                            </div>
                        </HCollapse>
                        <div className={'collapse-btn'}>
                            <Button>复制链接</Button>
                        </div>
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['upload', 'dataSource']),
    current: state.getIn(['upload', 'current']),
    count: state.getIn(['upload', 'count']),
    dataCount: state.getIn(['upload', 'dataCount']),
    groupList: state.getIn(['upload', 'groupList'])
});

const mapDispatch = dispatch => ({
    getList({ key, isPublic, current, data, groupId }) {
        dispatch(
            actionCreators.getPageList({
                url: '/terrain/query',
                key,
                isPublic,
                current,
                data,
                groupId
            })
        );
    },
    getDataCount() {
        dispatch(actionCreators.getDataCount('/terrain/terrain_count'));
    },
    changeList(url) {
        dispatch(actionCreators.changeList(url));
    },
    getGroupList() {
        dispatch(
            actionCreators.getGroupList({
                isPublic: 1,
                type: GroupDataTypeMap.terrain
            })
        );
    }
});

export default connect(mapState, mapDispatch)(HTerrain);
