import axios from 'axios';

export default class SystemService {
    static getData() {
        return axios.get('/system_config/get_config').then(r => r.data);
    }

    static delLink(id) {
        return axios.delete(`/system_config/delete_link/${id}`);
    }

    static edit(data) {
        return axios.put('/system_config/edit', data);
    }
}
