import axios from 'axios';

export class LabelService {
    static edit(data) {
        return axios.put('/label/edit', data);
    }

    static del(id) {
        return axios.delete('/label/delete/' + id);
    }

    /**
     * @description                     获取所有标签
     * @param params                    查询条件
     */

    static getList(params) {
        return axios.get('/label/query', { params });
    }

    /**
     * @description                     删除标签属性
     * @param id                        id
     */

    static deleteAtt(id) {
        return axios.delete(`/label/editor/delete/${id}`);
    }

    /**
     * @description                     根据id查询标签属性
     * @param id                        标签id
     */

    static queryById(id) {
        return axios.get(`/label/editor/query_by_id/${id}`);
    }
}
