import React, { Component, ReactNode } from 'react';
import { Image } from 'antd';
import { HIcon } from '../index';
import Logo from '../../assets/logo100.png';
import './index.scss';

interface Props {
    data: any;
    isPublic?: number;
    buttonList?: any[];
    activeButton?: number;
    activeTable: number;
    isButtonActive?: boolean;
    content?: any;
    userType?: ReactNode;
    customIcon?: ReactNode;
    imgUrl?: string;
    onClick: (e) => void;
    onDelete?: () => void;
    showDate: boolean;
    isInspection?: boolean;
    children?: ReactNode;
}

interface State {
    isDelete: boolean;
}

class TableLine extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isDelete: false
        };
    }

    render() {
        const { data, isPublic, buttonList, activeButton, isButtonActive, onDelete, onClick, activeTable, userType, customIcon, imgUrl, showDate, isInspection, children } =
            this.props;
        const { isDelete } = this.state;

        return (
            <div className={'h-table-line'} >
                <div onClick={onClick}>
                    <div className={'h-table-logo'}>{customIcon ? customIcon : <Image preview={false} src={imgUrl || Logo} alt="" />}</div>
                    <div className={'h-table-top'}>
                        <div
                            className={`h-table-top-content ${isDelete ? 'h-table-top-content-del' : ''}  ${data.id === activeTable ? 'active-table' : ''
                                }`}
                        >
                            <div className={`h-table-top-content-left  ${data.account && 'limit-width'}`}>
                                <span>{data.name ? data.name : data.projectName || data.mainTitle || '未命名'}</span>
                                {data.account && <span>{data.account}</span>}
                            </div>
                            <div className={'h-table-top-content-right'}>
                                {/*{data.id && <span>id:{data.id}</span>}*/}
                                {data.coordinateName ? (
                                    <span>
                                        <HIcon iconName={'icon-earth'} newClass={'h-table-line-icon'} />
                                        {data.coordinateName}
                                    </span>
                                ) : null}
                                {userType ? userType : null}
                                {data.subTitle && <span>{data.subTitle}</span>}
                                {isInspection && <span>
                                    {
                                        Boolean(data.type) ?
                                            <>
                                                <HIcon iconName='icon-gaojing' newClass={'h-table-line-abnormal'} />
                                                <span>异常</span>
                                            </>
                                            :
                                            <>
                                                <HIcon iconName='icon-check-circle' newClass={'h-table-line-icon'} />
                                                <span>常规</span>
                                            </>
                                    }
                                </span>}
                                {showDate && (
                                    <>
                                        {data.updateDate ? (
                                            <span>更新时间：{data.updateDate}</span>
                                        ) : data.createDate ? (
                                            <span>创建时间：{data.createDate}</span>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                        {isDelete && (
                            <div
                                className={'h-table-top-del'}
                                onClick={event => {
                                    event.stopPropagation();
                                    onDelete();
                                }}
                            >
                                删除
                            </div>
                        )}
                    </div>
                    <div className={'h-table-bottom'}>
                        <div className={`h-table-bottom-content ${isDelete ? 'h-table-bottom-content-del' : ''}`}>
                            <div>
                                <span className={'h-table-bottom-content-left'}>
                                    {Boolean(isPublic) ? data.projectCode || data.unit : data.planName || data.unit || data.projectCode}
                                </span>
                                {data.stage && <span className={'h-table-bottom-content-center'}>{data.stage}</span>}
                            </div>
                            <div className={'h-table-button-box'}>
                                {buttonList && buttonList.map((r, i) => {
                                    const key = i + 1;
                                    return (
                                        <HIcon
                                            key={i}
                                            iconName={key === activeButton && isButtonActive ? r.activeIcon : r.iconName}
                                            newClass={`clickable ${key === activeButton && isButtonActive ? 'h-active-icon' : ''} ${r.newClass}`}
                                            onClick={e => r.onClick(e, key)}
                                        />
                                    );
                                })}
                                {onDelete && (
                                    <HIcon
                                        iconName={isDelete ? 'icon-delete-fill' : 'icon-delete'}
                                        newClass={`clickable ${isDelete ? 'h-active-icon' : ''}`}
                                        onClick={event => {
                                            event.stopPropagation();
                                            this.setState({
                                                isDelete: !isDelete
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {isDelete && (
                            <div
                                className={'h-table-bottom-del'}
                                onClick={event => {
                                    event.stopPropagation();
                                    this.setState({
                                        isDelete: false
                                    });
                                }}
                            >
                                取消
                            </div>
                        )}
                    </div>
                </div>
                {children}
            </div>
        );
    }
}

export default TableLine;
