import React, { useState } from 'react';
import { Collapse, message, Popconfirm } from 'antd';
import { MonitorService } from '../../services/monitor';
import { LineListBox } from '../../components';
import { HIcon } from '../../components';

import { propsType, responseType } from './types';

const { Panel } = Collapse;
import './index.scss';

const CollapseHeaderExtra = ({ props }: any) => {
    const { handleDelColl, layName } = props;

    return (
        <>
            <Popconfirm
                title="是否确认删除"
                onConfirm={e => {
                    e.stopPropagation();
                    handleDelColl(layName);
                }}
                onCancel={() => message.info('已取消')}
                okText="是"
                cancelText="否"
            >
                <i className={['h-icon', 'iconfont', 'icon-delete', 'h-clickable'].join(' ')} />
            </Popconfirm>
        </>
    );
};

const TabCollapse = ({ props, onClick }: propsType) => {
    const { data, setValue, setLineInfo, setEditInfo, uploadFlag, type, setAddLineData } = props;

    const [layerListState, setLayerListState] = useState<Array<string>>([]);

    const handleEditColl = () => {
        onClick(true, data, data.id);
    };

    const handleDelColl = layName => {
        MonitorService.handleDelLayer(data.id, layName)
            .then((res: any) => {
                if (res.code !== 200) {
                    message.error('删除失败');
                } else {
                    message.success('删除成功');
                    const tmp = [...layerListState];
                    const i = tmp.findIndex(name => name === layName);
                    tmp.splice(i, 1);
                    setLayerListState(tmp);
                }
            })
            .catch(err => {
                message.error('删除失败');
            });
    };

    const handleCollapseChange = e => {
        if (e) {
            setValue('cad', e);
            !layerListState.length &&
                MonitorService.handleGetLayerList(e)
                    .then((res: responseType) => {
                        if (res.code !== 200) {
                            message.error('获取图层信息失败');
                            setValue('', '');
                        } else {
                            setLayerListState(res.data);

                        }
                    })
                    .catch(e => {
                        setValue('', '');
                    });
        } else {
            setValue('', '');
        }
    };

    return (
        <div>
            <Collapse onChange={handleCollapseChange} accordion={true}>
                <Panel
                    key={data.id}
                    header={data.name ? data.name : '未定义'}
                // extra={<CollapseHeaderExtra props={{ handleEditColl, handleDelColl }} />}
                >
                    <Collapse accordion onChange={e => (e ? setValue('layer', String(e)) : setValue('', ''))}>
                        {layerListState.map(layName => {
                            return (
                                <Panel
                                    key={layName}
                                    header={layName ? layName : '未命名'}
                                    extra={<CollapseHeaderExtra props={{ handleDelColl, layName }} />}
                                >
                                    <LineListBox
                                        props={{
                                            cadId: data.id,
                                            layName,
                                            setValue,
                                            setLineInfo,
                                            setEditInfo,
                                            uploadFlag,
                                            type,
                                            setAddLineData
                                        }}
                                    />
                                </Panel>
                            );
                        })}
                    </Collapse >
                </Panel >
            </Collapse >
        </div >
    );
};

export default TabCollapse;
