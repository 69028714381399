import React, { Component } from 'react';
import { message } from 'antd';
import { HAddItemSelect, HButton, HCollapse, HLabelInput } from '../../../components';
import { HSelect, HInput } from '../../../components';
import axios from 'axios';
import { SourceType } from '../../../utils/static-data';
import { LabelModel } from '../store/model';
import HUploadThumbnail from '../../../components/upload';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import { GroupModel } from '../../../components/model';
import { LabelService } from '../../../services/label';

interface Props {
    isAdd: boolean;
    isStatic: number;
    addGroup: (name) => void;
    updateGroupChange: (data) => void;
    onDeleteGroup: (id) => void;
    groupList: GroupModel[];
    editData?: any;
    onSuccess: () => void;
    getNormalUserList: () => void;
    normalUserList: SourceType[];
    getAllProjectList: () => void;
    allProjectList: SourceType[];
}

interface States {
    formData: LabelModel;
    validation: boolean;
}

class HLabelForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                fileUrl: '',
                groupId: undefined, //暂时
                isPublic: 1,
                isStatic: 1,
                name: '',
                principal: '',
                projectCode: undefined,
                unit: ''
            },
            validation: false
        };
    }

    componentDidMount(): void {
        this.props.getAllProjectList();
        this.props.getNormalUserList();
        const { formData } = this.state;
        const { isStatic } = this.props;
        formData.isStatic = isStatic;
        this.setState({
            formData
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (
            this.props.normalUserList !== prevProps.normalUserList ||
            this.props.allProjectList !== prevProps.allProjectList ||
            this.props.editData !== prevProps.editData
        ) {
            this.setData();
        }
    }

    setData = () => {
        const { editData, isAdd } = this.props;
        if (!isAdd && editData) {
            const { formData } = this.state;
            Object.keys(formData).forEach(key => {
                formData[key] = editData[key];
            });
            formData['id'] = editData.id;
            this.setState({
                formData
            });
        }
    };

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({
            formData
        });
    };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData } = this.state;
                // console.log('save', formData);
                if (formData.projectCode && formData.fileUrl && formData.groupId) {
                    LabelService.edit(formData).then(res => {
                        if (res['code'] === 200) {
                            message.success(`${formData.id ? '修改' : '添加'}成功`);
                            this.props.onSuccess();
                        }
                    });
                }
            }
        );
    };

    render() {
        const { isAdd, allProjectList, normalUserList, addGroup, updateGroupChange, onDeleteGroup, groupList } = this.props;
        const {
            formData: { fileUrl, name, projectCode, principal, unit, groupId },
            validation
        } = this.state;
        // console.log('label-form', this.props);
        return (
            <HCollapse title={'基本信息'}>
                <HSelect
                    label={'项目'}
                    rule={{ required: true, validation, message: '请选择项目' }}
                    value={projectCode}
                    dataSource={allProjectList}
                    onChange={selected => this.onChange('projectCode', selected)}
                />
                <HInput label={'名称'} value={name} onChange={e => this.onChange('name', e.target.value)} />
                <HInput label={'单位'} value={unit} onChange={e => this.onChange('unit', e.target.value)} />
                <HSelect label={'负责人'} value={principal} dataSource={normalUserList} onChange={selected => this.onChange('principal', selected)} />
                <HLabelInput label={'图片文件'} required={true}>
                    <HUploadThumbnail
                        type={5}
                        defaultUrl={fileUrl}
                        required={true}
                        validation={validation}
                        onChange={url => this.onChange('fileUrl', url)}
                    />
                </HLabelInput>
                <HLabelInput label={'分组'} required={true}>
                    <HAddItemSelect
                        dataSource={groupList}
                        add={addGroup}
                        value={groupId}
                        isForm={true}
                        rule={{ required: true, validation, message: '请选择分组' }}
                        onChange={value => this.onChange('groupId', value)}
                        maxWidth={212}
                        minWidth={212}
                        saveChange={updateGroupChange}
                        onDelete={onDeleteGroup}
                    />
                </HLabelInput>
                <div className={'h-text-center'} style={{ marginTop: 20 }}>
                    <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                </div>
            </HCollapse>
        );
    }
}

const mapState = state => ({
    normalUserList: state.getIn(['upload', 'normalUserList']),
    allProjectList: state.getIn(['upload', 'allProjectList'])
});

const mapDispatch = dispatch => ({
    getNormalUserList() {
        dispatch(actionCreators.getNormalUserList());
    },
    getAllProjectList() {
        dispatch(actionCreators.getAllProject());
    }
});

export default connect(mapState, mapDispatch)(HLabelForm);
