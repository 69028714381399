import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { MonitorService } from '../../../services/monitor';
import HAMap from '../../../utils/amap';

import { HContentLeft, HContentRight, HDrawer } from '../../../components/simple';
import { actionCreators } from '../store';
import { HInfoLine, TabCollapse } from '../../../components';
import EditForm from './EditForm';
import AddForm from './AddForm';
import { message } from 'antd';

class PipeWell extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            activeData: [],
            cadInfo: {},
            editInfo: {
                state: false,
                id: '',
                data: [
                    { title: '名称', type: 'input', value: '' },
                    { title: '经度', type: 'input', value: '' },
                    { title: '纬度', type: 'input', value: '' }
                ]
            },
            uploadFlag: 0,
            activeInfo: { type: '', key: '' }
        };
    }

    initMap = () => {
        //初始化地图数据
        HAMap.init({ id: 'project-preview-map' });
    };

    componentDidMount() {
        this.props.getPipelineList('', 1); //从接口读取数据
        this.initMap(); //初始化地图，展示的地图
    }

    handleInfiniteOnLoad = page => {
        const { dataSource, getPipelineList, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getPipelineList(keyword, page, dataSource);
        }
    };

    handleSetActiveData = data => {
        this.setState({ activeData: data });
    };

    handleSetCadInfo = data => {
        this.setState({ cadInfo: data }, () => this.handleSetActiveData(data.data));
    };

    handleSelectItem = (type, key) => {
        this.setState({ activeInfo: { type, key } });
        if (type === 'cad') {
            if (key && (!this.state.cadInfo.id || this.state.cadInfo.id !== key)) {
                MonitorService.handleGetCadInfo(key)
                    .then(res => {
                        const { data } = res;
                        this.handleSetCadInfo({
                            id: key,
                            data: [
                                { title: '名称', value: data.name ? data.name : '' },
                                { title: '负责人', value: data.principal ? data.principal : '' },
                                { title: '数据大小', value: data.size ? data.size : '' },
                                { title: '数据格式', value: data.dataFormat ? data.dataFormat : '' },
                                { title: '方案名称', value: data.planName ? data.planName : '' },
                                { title: '项目编号', value: data.projectCode ? data.projectCode : '' },
                                { title: '创建时间', value: data.createDate ? data.createDate : '' },
                                { title: '修改时间', value: data.updateDate ? data.updateDate : '' }
                            ]
                        });
                    })
                    .catch(e => {
                        // console.log(e);
                    });
            } else {
                this.setState({ activeData: this.state.cadInfo.data });
            }
        } else if (type === 'layer') {
            this.setState({ activeData: this.state.cadInfo.data });
        } else {
            this.setState({ activeData: [] });
        }
    };

    handleSetLineInfo = data => {
        this.setState({ lineInfo: data }, () => {
            this.setState({ activeData: data.data });
        });
    };

    handleSetEditInfo = (state, flag?, id?, value?) => {
        if (flag === 'init') {
            this.setState({
                editInfo: {
                    state,
                    id,
                    data: value ? value : [...this.state.editInfo.data]
                }
            });
        } else if (flag === null) {
            this.setState({
                editInfo: {
                    state,
                    id: this.state.editInfo.id,
                    data: [...this.state.editInfo.data]
                }
            });
        } else {
            const tmp = [...this.state.editInfo.data];
            tmp.find(item => item.title === flag).value = value;
            this.setState({
                editInfo: {
                    state,
                    id: this.state.editInfo.id,
                    data: [...tmp]
                }
            });
        }
    };

    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.choseAddDrawer(); //选择了编辑就关闭新增
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    handleSaveLineData = () => {
        const { data } = this.state.editInfo;
        MonitorService.handleUpdatePointInfo({
            id: this.state.editInfo.id,
            name: data[0].value
        })
            .then((res: any) => {
                if (res.code !== 200) {
                    message.error('修改失败');
                } else {
                    message.success('修改成功');
                    this.handleSetEditInfo(false, null);
                    this.handleSetLineInfo({
                        id: this.state.editInfo.id,
                        data: [
                            { title: '名称', value: data[0].value },
                            { title: '精度', value: data[1].value },
                            { title: '纬度', value: data[2].value }
                        ]
                    });
                    this.setState({ uploadFlag: this.state.uploadFlag + 1 });
                }
            })
            .catch(err => {
                message.error('修改失败');
            });
    };

    //关闭编辑的界面
    closeDrawer = () => {
        const { isAdd, choseAddDrawer } = this.props;
        if (isAdd) {
            choseAddDrawer();
        } else {
            this.onEditChange(false);
        }
    };

    render() {
        const { dataSource, isAdd } = this.props;
        const { activeData, activeInfo } = this.state;

        return (
            <>
                <HContentLeft>
                    <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={true} useWindow={false}>
                        {dataSource.map((r, i) => (
                            <TabCollapse
                                key={i}
                                props={{
                                    data: r,
                                    setValue: this.handleSelectItem,
                                    setLineInfo: this.handleSetLineInfo,
                                    setEditInfo: this.handleSetEditInfo,
                                    setAddLineData: () => {
                                        //
                                    },
                                    uploadFlag: this.state.uploadFlag,
                                    type: 'point'
                                }}
                                onClick={this.onEditChange}
                            />
                        ))}
                    </InfiniteScroll>
                </HContentLeft>
                <HContentRight>
                    {this.state.editInfo.state ? (
                        <>
                            <div className={'h-content-right-map'} id={'project-preview-map'} />
                            {this.state.editInfo.data.map(item => (
                                <EditForm
                                    key={item.title}
                                    props={{
                                        type: item.type,
                                        value: item.value,
                                        setValue: this.handleSetEditInfo,
                                        flag: item.title,
                                        option: item.option
                                    }}
                                />
                            ))}
                            <div className="h-line-edit-btn-box">
                                <button onClick={this.handleSaveLineData}>保存数据</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={'h-content-right-map'}
                                style={{ display: activeInfo.key ? 'block' : 'none' }}
                                id={'project-preview-map'}
                            />
                            {activeData.length > 0 && (
                                <>
                                    {activeData.map((it, i) => {
                                        return <HInfoLine key={i} title={it.title} value={it.value} />;
                                    })}
                                </>
                            )}
                        </>
                    )}
                </HContentRight>
                {isAdd && (
                    <HDrawer iconName={'icon-file-add'} title={'上传数据'} shutDown={this.closeDrawer}>
                        {/* <HProjectForm isAdd={isAdd} onSuccess={this.onSaveSuccess} /> */}
                        <AddForm onSuccess={this.closeDrawer} />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    //这里要改
    dataSource: state.getIn(['monitor', 'dataSource']),
    current: state.getIn(['monitor', 'current']),
    count: state.getIn(['monitor', 'count'])
});

const mapDispatch = dispatch => ({
    getPipelineList(value, current, data) {
        dispatch(actionCreators.getPipelineList(value, current, data));
    },
    changeProjectData(data) {
        dispatch(actionCreators.changeData(data));
    }
});

export default connect(mapState, mapDispatch)(PipeWell);
