import React, { Component } from 'react';
import { HAvatar, HContentLayout, HDrawer } from '../../../components';
import { TableLine, HInfoLine } from '../../../components';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import InfiniteScroll from 'react-infinite-scroller';
import HUserForm from './form';
import axios from 'axios';
import { debounce } from '../../../utils/common';
import { UserService } from '../../../services/user';

const rightList = [
    {
        key: 'name',
        title: '姓名',
        value: ''
    },
    {
        key: 'unit',
        title: '单位',
        value: ''
    },
    {
        key: 'department',
        title: '部门',
        value: ''
    },
    {
        key: 'phone',
        title: '手机',
        value: ''
    },
    {
        key: 'typeName',
        title: '账户类型',
        value: '',
        bgc: ''
    },
    {
        key: 'account',
        title: '账户名称',
        value: ''
    },
    {
        key: 'createDate',
        title: '注册时间',
        value: ''
    }
];

const colorList = {
    普通用户: 'rgba(0,0,0,0.25)',
    管理员: '#F3604A',
    审查员: '#35C64F',
    领导: '#0168FE'
};

interface Props {
    keyword: string;
    isAdd: boolean;
    current: number;
    dataSource: any[];
    count: number;
    choseAddDrawer: () => void;
    getUserList?: (value, current, data?) => void;
    changeUserData?: (data) => void;
}

interface States {
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    hasMore: boolean;
    activeData: any[];
    functionList: [];
    editData: any;
}

class UserSet extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            hasMore: true,
            activeData: [],
            functionList: [],
            editData: {}
        };
    }

    componentDidMount() {
        this.props.getUserList('', 1);
        this.getFunctionList();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.isAdd !== this.props.isAdd && this.props.isAdd) {
            if (this.state.isEdit) {
                this.onEditChange(false);
            }
        }
        if (prevProps.keyword !== this.props.keyword) {
            debounce(this.props.getUserList(this.props.keyword, 1), 300);
        }
    }

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        if (visible) this.props.choseAddDrawer();
        this.setState({
            isEdit: visible,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            activeData: visible ? data : [],
            editData: visible ? data : {}
        });
    };

    onClose = () => {
        const { isAdd, choseAddDrawer } = this.props;
        if (isAdd) {
            choseAddDrawer();
        } else {
            this.setState({
                isEdit: false,
                activeButton: 0,
                activeId: 0
            });
        }
    };

    lineClick = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            rightList.map(r => {
                if (r.key === 'typeName') {
                    r.bgc = colorList[data[r.key]];
                }
                r.value = data[r.key];
            });
            this.setState({
                activeData: data.id === activeId ? [] : rightList,
                activeId: data.id === activeId ? 0 : data.id
            });
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        axios.delete('/user/delete/' + id).then(res => {
            dataSource.splice(key, 1);
            const newData = [...dataSource];
            this.props.changeUserData(newData);
            this.setState({
                activeData: id === activeId ? [] : activeData,
                activeId: id === activeId ? 0 : activeId
            });
        });
    };

    handleInfiniteOnLoad = page => {
        const { dataSource, getUserList, count, keyword } = this.props;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getUserList(keyword, page, dataSource);
        }
    };

    getFunctionList = () => {
        UserService.getFunctionList().then(functionList => {
            this.setState({
                functionList
            });
        });
    };
    onSaveSuccess = () => {
        const { isAdd, keyword, current } = this.props;
        this.onClose();
        this.props.getUserList(keyword, 1);
    };

    render() {
        const { isEdit, activeId, activeButton, hasMore, activeData, functionList, editData } = this.state;
        const { isAdd, dataSource } = this.props;
        return (
            <>
                <HContentLayout
                    left={
                        <InfiniteScroll initialLoad={false} pageStart={1} loadMore={this.handleInfiniteOnLoad} hasMore={hasMore} useWindow={false}>
                            {dataSource.map((r, i) => {
                                return (
                                    <TableLine
                                        key={r.id}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        customIcon={<HAvatar color={r.iconColor} text={r.name && r.name.substr(0, 1)} onClick={() => {}} />}
                                        data={r}
                                        showDate={false}
                                        buttonList={[
                                            {
                                                iconName: 'icon-edit',
                                                activeIcon: 'icon-edit-fill',
                                                onClick: (event, key) => {
                                                    event.stopPropagation();
                                                    this.onEditChange(true, r, key);
                                                }
                                            }
                                        ]}
                                        activeButton={activeButton}
                                        isButtonActive={r.id === activeId}
                                        activeTable={activeId}
                                        onDelete={() => this.onDelete(r.id, i)}
                                        onClick={() => this.lineClick(r)}
                                        userType={
                                            <span
                                                style={{
                                                    color: `${colorList[r.typeName]}`
                                                }}
                                            >
                                                {r.typeName}
                                            </span>
                                        }
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    }
                    right={
                        <div>
                            {!isEdit &&
                                activeData.length > 0 &&
                                activeData.map((it, i) => {
                                    return <HInfoLine key={i} title={it.title} value={it.value} fontWhite={Boolean(it.bgc)} suffixBgColor={it.bgc} />;
                                })}
                            {/*<div>HInfoLine 使用方法</div>*/}
                            {/*<HInfoLine title={"地形"} value={testValue} click={true}*/}
                            {/*           suffixBgColor={"rgba(240, 253, 245, 1)"} onChange={this.onChange}/>*/}
                            {/*<HInfoLine title={"单位"} value={"中交上海航道勘察设计研究院有限公司"}*/}
                            {/*           suffix={<span>负责人：王天宇</span>}*/}
                            {/*           suffixBgColor={"#F0F7FD"} suffixWidth={150}/>*/}
                            {/*<HInfoLine title={"分组1"} value={"类型3"} suffixWidth={150}*/}
                            {/*           suffix={<Dropdown overlay={menu} placement="bottomLeft" arrow>*/}
                            {/*               <DownSquareOutlined/>*/}
                            {/*           </Dropdown>}*/}
                            {/*           suffixBgColor={"#F0F7FD"}/>*/}
                            {/*<HInfoLine title={"分组2"} value={"静态标签"} suffixWidth={150}*/}
                            {/*           suffix={<div><HCheckItem className={"active"} text={"静态标签"}*/}
                            {/*                                    value={1}/>*/}
                            {/*               <HCheckItem text={"动态标签"} value={2}/>*/}
                            {/*           </div>}*/}
                            {/*/>*/}
                            {/*<HInfoLine title={"分组2"} value={Logo} image={true} suffixWidth={28}*/}
                            {/*           suffixBgColor={"#F0F7FD"}*/}
                            {/*           suffix={<UploadOutlined/>}*/}
                            {/*/>*/}
                        </div>
                    }
                />
                {isAdd && (
                    <HDrawer iconName={'icon-file-add'} title={'新建用户'} shutDown={this.onClose}>
                        <HUserForm isAdd={isAdd} functionList={functionList} closeDrawer={this.onClose} onSuccess={this.onSaveSuccess} />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑用户'} shutDown={this.onClose}>
                        <HUserForm
                            isAdd={isAdd}
                            editData={editData}
                            functionList={functionList}
                            closeDrawer={this.onClose}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['setup', 'dataSource']),
    current: state.getIn(['setup', 'current']),
    count: state.getIn(['setup', 'count'])
});

const mapDispatch = dispatch => ({
    getUserList(value, current, data) {
        dispatch(actionCreators.getUserList(value, current, data));
    },
    changeUserData(data) {
        dispatch(actionCreators.changeData(data));
    }
});

export default connect(mapState, mapDispatch)(UserSet);
