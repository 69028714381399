/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/prop-types */
import React, { Component, useEffect, useState } from 'react';
import { Drawer, Divider } from 'antd';
import { HIcon } from '../../components';
import ContentCard from './components/contentCard';
import Milestone from './components/milestone';
import ImageShowOff from './components/imageShowOff';
import moment from 'moment';
import axios from 'axios';
import HAMap from '../../utils/amap';
import MarkerIcon from '../../assets/marker.svg';
import './index.scss';
import Logo from '../../assets/shct.png';
import { getOnlineUser } from '../../utils/common';
import { SkinRouter } from '../../utils/static-data';
import ProjectService from '../../services/project';
import { PlanService } from '../../services/plan';

const bottomButton = [
    {
        title: '规划',
        stage: '规划阶段'
    },
    {
        title: '设计',
        stage: '设计阶段'
    },
    {
        title: '施工',
        stage: '施工阶段'
    },
    {
        title: '运维',
        stage: '运维阶段'
    },
    {
        title: '关注',
        stage: '关注阶段'
    }
];

const isFullscreen = () => window.screen.height == document.body.clientHeight;

const setFullscreen = () => {
    if (!document.fullscreen) {
        document.documentElement.requestFullscreen();
    } else {
        document.exitFullscreen();
    }
};

const handleKeyF11 = e => {
    if (e.keyCode == 122 /* f11 */) {
        e.preventDefault();
        setFullscreen();
    }
};

interface Props {}

interface State {
    stage: string;
    leaderId: number;
    leftVisible: boolean;
    leftList: any[];
    leftActiveIndex: string;
    leftActiveData: any;
    rightVisible: boolean;
    activeText: string;
    full: boolean;
}

class Leadership extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            stage: '',
            leaderId: 0,
            leftVisible: false,
            leftList: [],
            leftActiveIndex: '',
            leftActiveData: {},
            rightVisible: false,
            activeText: '',
            full: false
        };
    }

    componentDidMount() {
        const sessionData = sessionStorage.getItem('user');
        if (sessionData) {
            const data = JSON.parse(sessionData);
            const id = data ? data.id : null;
            if (id) {
                this.setState({
                    leaderId: id
                });
            }
            this.init();
            window.addEventListener('keydown', handleKeyF11);
            window.addEventListener(
                'fullscreenchange',
                e => {
                    this.setState({
                        full: isFullscreen()
                    });
                },
                false
            );
        }
    }

    init = () => {
        // const radialBgDom = document.getElementById("leader-radial-bg");
        HAMap.init({ id: 'aMap', style: 'grey' });
        HAMap.map.on('moveend', () => {
            const zoom = HAMap.map.getZoom();
            if (zoom >= 13) {
                // radialBgDom.style.background = "radial-gradient(rgba(3,23,41,.1),rgba(3,23,41,.15),rgba(3,23,41,.2),rgba(3,23,41,.3),rgba(3,23,41,.4),rgba(3,23,41,.5),rgba(3,23,41,.6),rgba(3,23,41,.7),rgba(3,23,41,.8),rgba(3,23,41,.85))"
                HAMap.satellite.show();
            } else {
                // radialBgDom.style.background = "none";
                HAMap.satellite.hide();
            }
        });
    };

    getData = stage => {
        const { leaderId } = this.state;
        ProjectService.getListByLeader(leaderId, stage).then(res => {
            this.setState({
                leftList: res.data,
                stage
            });
        });
    };

    getActiveData = data => {
        const { leftActiveIndex, stage } = this.state;
        HAMap.map.clearMap();
        PlanService.getListByLeader(data.projectCode, stage).then(res => {
            if (data.projectCode !== leftActiveIndex) {
                if (res.data.position.length > 0) {
                    HAMap.map.panTo(res.data.position);
                    HAMap.addMarker({
                        icon: MarkerIcon,
                        position: res.data.position,
                        label: data.projectName,
                        offsetL: -23,
                        offsetT: -46
                    });
                }
            } else {
                HAMap.map.clearMap();
            }
            const { cooperateBuiltList } = res.data;
            cooperateBuiltList.sort((a, b) => a.unitType - b.unitType);
            this.setState({
                leftActiveData: data.projectCode === leftActiveIndex ? {} : res.data,
                leftActiveIndex: data.projectCode === leftActiveIndex ? '' : data.projectCode,
                rightVisible: data.projectCode === leftActiveIndex ? false : true,
                activeText: data.projectCode === leftActiveIndex ? '' : data.projectName
            });
        });
    };

    render() {
        const { stage, leftVisible, leftList, leftActiveIndex, leftActiveData, rightVisible, activeText, full } = this.state;
        const buttonList = [
            {
                iconName: 'icon-menu-module',
                newClass: '',
                onClick: () => (window.location.href = '/project')
            },
            {
                iconName: 'icon-earth-line',
                newClass: ''
                // onClick: () => console.log('icon-dituqiehuan')
            },
            {
                iconName: 'icon-search-fill',
                newClass: ''
                // onClick: () => console.log('icon--search-fill')
            },
            {
                iconName: 'icon-statistics',
                newClass: leftVisible ? 'active' : '',
                onClick: () => {
                    this.setState({
                        leftVisible: !leftVisible,
                        rightVisible: false,
                        stage: ''
                    });
                    if (!leftVisible) {
                        this.getData('规划阶段');
                    }
                }
            },
            {
                iconName: 'icon-fullscreen-big',
                newClass: full ? 'active' : '',
                onClick: () => {
                    setFullscreen();
                    const newFull = isFullscreen();
                    this.setState({
                        full: !newFull
                    });
                }
            }
        ];
        const userData = getOnlineUser();
        const entitle = {
            规划阶段: 'the planning stage',
            设计阶段: 'the design phase',
            施工阶段: 'the construction phase',
            运维阶段: 'operation phase'
        };
        return (
            <div className={'h-leadership'}>
                <div className={'radial-bg'} id={'leader-radial-bg'} />
                <div id="aMap" style={{ width: '100%', height: '100vh' }} />
                <div className={'h-leadership-top'}>
                    <div className={'top-title'}>
                        <img className={'title-logo'} src={userData.leaderLogoUrl ? userData.leaderLogoUrl : Logo} />
                        <div>
                            <div className={'title'}>{userData.leaderMainTitle ? userData.leaderMainTitle : `${userData.unit}项目总览`}</div>
                            <div className={'entitle'}>{userData.leaderSubTitle ? userData.leaderSubTitle : 'MULTI-PROJECT OVERVIEW PLATFOR'}</div>
                        </div>
                    </div>
                </div>
                <Drawer placement="left" closable={false} maskClosable={false} mask={false} width={420} getContainer={false} visible={leftVisible}>
                    <ContentCard
                        className={'content-block'}
                        title={`${stage}项目列表`}
                        engtitle={`${stage === '关注阶段' ? '' : `List of projects in ${entitle[stage]}`}`}
                        suffix={
                            <div className={'content-card-suffix'}>
                                项目总数：<span>{leftList.length}</span> 个
                            </div>
                        }
                    >
                        <div className={'left-list'}>
                            {leftList.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={`${item.projectCode === leftActiveIndex && 'list-active'}`}
                                        onClick={() => this.getActiveData(item)}
                                    >
                                        <div className={'text'} title={item.projectName}>
                                            {item.projectName}
                                        </div>
                                        <HIcon iconName={'icon-right'} />
                                    </div>
                                );
                            })}
                        </div>
                    </ContentCard>
                </Drawer>
                <Drawer placement="right" closable={false} maskClosable={false} mask={false} width={420} getContainer={false} visible={rightVisible}>
                    <div className={'content-block'}>
                        <ContentCard
                            title={'项目简介'}
                            engtitle={'Project Description'}
                            suffix={
                                <a className={'skin-link'} href={SkinRouter + '/' + leftActiveData.shareUrl} target={'_blank'} rel="noreferrer">
                                    <span>查看详情</span>
                                    <HIcon iconName={'icon-right'} />
                                </a>
                            }
                        >
                            <div className={'project-text-box'}>
                                <div className={'project-title'}>{activeText}</div>
                                <div className={'project-overview'}>{leftActiveData.overview && leftActiveData.overview}</div>
                                {leftActiveData.imgList && <ImageShowOff imageList={leftActiveData.imgList} />}
                            </div>
                        </ContentCard>
                        <ContentCard title={'里程碑'} engtitle={'milestone'}>
                            {leftActiveData.milestoneList && leftActiveData.milestoneList.length > 0 && (
                                // <TimelineTest data={leftActiveData.milestoneList} />
                                <Milestone data={leftActiveData.milestoneList} />
                            )}
                        </ContentCard>
                        <ContentCard title={'参建方'} engtitle={'Participating party'}>
                            <div className={'participating-box'}>
                                {leftActiveData.cooperateBuiltList &&
                                    leftActiveData.cooperateBuiltList.map((item, i) => {
                                        return (
                                            <div key={i} className={'content-box'}>
                                                <div>{item.unitTypeName}</div>
                                                <div>{item.unitName}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </ContentCard>
                    </div>
                </Drawer>
                <div className={'h-leadership-bottom'}>
                    <div className={'bottom-left'}>
                        {bottomButton.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`${item.stage === stage ? 'button-active' : ''}`}
                                    onClick={() => {
                                        this.setState({
                                            stage: item.stage,
                                            leftVisible: !leftVisible ? true : leftVisible,
                                            rightVisible: false,
                                            leftActiveData: {},
                                            leftActiveIndex: '',
                                            activeText: ''
                                        });
                                        HAMap.map.clearMap();
                                        this.getData(item.stage);
                                    }}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                    <div className={'bottom-right'}>
                        <Time />
                        <Divider type="vertical" />
                        <div className={'right-button'}>
                            {buttonList.map((r, i) => {
                                return (
                                    <div key={i} className={r.newClass}>
                                        <HIcon iconName={r.iconName} onClick={r.onClick} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Leadership;

const Time = ({ format = 'YYYY年MM月DD日 HH:mm:ss', step = 's', ...rest }) => {
    const [time, setTime] = useState(moment().format(format));
    const _step = {
        d: 24 * 60 * 60 * 1000,
        h: 60 * 60 * 1000,
        m: 60 * 1000,
        s: 1000
    }[step];
    useEffect(() => {
        setInterval(() => setTime(moment().format(format)), _step || 1000);
    }, []);
    return <span {...rest}>{time}</span>;
};
