/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react';
import { Button, Cascader, Input, message, Select, Tooltip } from 'antd';
import { HButton, HCollapse, HIcon, HInput, HLabelInput, HSelect, HTabs } from '../../../components';
import { MonitorService } from '../../../services/monitor';
import { EditProjectModel, ProjectModel } from './model';
import HAMap, { mapCenter } from '../../../utils/amap';
import poiMarker from '../../../assets/poi-marker-default.png';
import { SourceType } from '../../../utils/static-data';
import { actionCreators } from '../store';
import { connect } from 'react-redux';
import { EnvironmentFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';

const sourceList = [
    {
        value: 1,
        text: '繁易'
    },
    {
        value: 2,
        text: '院内'
    }
];
const tabList = [
    {
        title: '数据信息'
    },
    {
        title: '监测数据'
    }
];

/**
 * @description 表单内容
 */
interface Props {
    isAdd?: boolean;
    isEdit?: boolean;
    editData?: EditProjectModel; //外部传入
    menuKey: number;
    projectList: SourceType[]; //这个是map state获取
    areaList: any[];
    getAreaList: () => void;
    getAllProjectList?: (key?) => void; //map state获取
    onSuccess: () => void;
    onCancel: () => void;
}

interface State {
    id?: number;
    baseData: ProjectModel;
    validation: boolean;
    position: any;
    defaultRegion: string[];
    inputAddress: string; //输入的地址
    tabKey: number;
}

class FormContent extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            baseData: {
                code: '',
                id: undefined,
                lat: undefined,
                lon: undefined,
                name: '',
                position: undefined,
                projectCode: '',
                projectName: '',
                projectUnit: '',
                region: '',
                remark: '',
                resp: '',
                source: undefined,
                type: undefined, //可以写死2
                address: '',
                maintenanceUnit: '',
                phone: '',
                constructionUnit: '',
                completionTime: ''
            },
            defaultRegion: undefined,
            position: '',
            validation: true,
            inputAddress: '',
            tabKey: 0
        };
    }

    componentDidMount() {
        this.props.getAllProjectList();
        this.props.getAreaList(); //获取行政区的函数，调用接口
        this.setData();
    }

    //更新需要重新思考
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.editData !== prevProps.editData) {
            this.setData();
        }
    }

    /**
     * @description 设置data，同时调用初始化地图
     */

    setData = () => {
        const { isAdd, editData, menuKey } = this.props;
        if (!isAdd && editData) {
            //编辑情况下，读取数据
            const { lat, lon, region } = this.props.editData;
            const pos = [lon, lat];
            this.setState({
                baseData: {
                    ...editData,
                    type: menuKey
                },
                position: pos ? pos.join(',') : '121.456424, 31.234892',
                defaultRegion: region ? [region.slice(0, 9) + '000', region] : []
            });
            this.init(pos);
        } else {
            //非编辑情况下直接初始化
            this.init();
        }
    };

    //基本数据改变
    baseDataChange = (key, value) => {
        const { baseData } = this.state;
        baseData[key] = value;
        this.setState({
            baseData
        });
    };
    /**
     * @description 初始化表单地图，
     */
    static marker = null;
    init = (position?) => {
        const havePosition = position && position.length > 0;
        HAMap.init({
            id: 'get-plan-center-map',
            center: havePosition ? position : mapCenter
        });
        if (havePosition) {
            FormContent.marker = HAMap.addCenterMarker(poiMarker, position);
        }
        HAMap.map.on('click', e => {
            if (FormContent.marker) {
                FormContent.marker.setMap(null);
                FormContent.marker = null;
            }
            FormContent.marker = HAMap.addCenterMarker(poiMarker, [e.lnglat.getLng(), e.lnglat.getLat()]);

            this.setState({
                position: e.lnglat.getLng() + ',' + e.lnglat.getLat()
            });
        });
    };

    save = async () => {
        const { baseData, position } = this.state;
        const pos = position.split(',').map(i => Number(i));
        const formData = {
            ...baseData,
            lon: pos[0],
            lat: pos[1],
            type: 2 //泵站是2
        };
        const a = await MonitorService.addMonitor(formData);
        Promise.all([a]).then(res => {
            //这里最好再来个判断，得由后端做
            // message.success('添加成功');
            this.props.onSuccess();
        });
    };

    render() {
        const {
            baseData: {
                projectName,
                projectCode,
                projectUnit,
                name,
                code,
                source,
                resp,
                address,
                remark,
                maintenanceUnit,
                phone,
                constructionUnit,
                completionTime
            },
            validation,
            position,
            defaultRegion,
            inputAddress,
            tabKey
        } = this.state;
        const { projectList } = this.props;
        const { areaList } = this.props;
        // console.log(defaultRegion);
        return (
            <div>
                <HTabs data={tabList} activeKey={tabKey} onClick={value => this.setState({ tabKey: value })} />
                {tabKey === 0 ? (
                    <HCollapse title={'数据信息'}>
                        <HSelect
                            label={'项目名称'}
                            rule={{ required: true, validation, message: '请输入项目名称' }}
                            value={projectName}
                            dataSource={projectList}
                            onChange={selected => {
                                this.baseDataChange('projectCode', selected);
                            }}
                        />
                        <HInput
                            label={'项目单位'}
                            rule={{ required: true, validation, message: '请输入项目单位' }}
                            value={projectUnit}
                            onChange={e => {
                                this.baseDataChange('projectUnit', e.target.value);
                            }}
                        />
                        <HInput
                            label={'设备名称'}
                            rule={{ required: true, validation, message: '请输入设备名称' }}
                            value={name}
                            onChange={e => {
                                this.baseDataChange('name', e.target.value);
                            }}
                        />
                        <HInput
                            label={'序列号'}
                            rule={{ required: true, validation, message: '请输入序列号' }}
                            value={code}
                            onChange={e => {
                                this.baseDataChange('code', e.target.value);
                            }}
                        />
                        <HSelect
                            label={'数据来源'}
                            rule={{ required: true, validation, message: '请选择数据来源' }}
                            value={source}
                            dataSource={sourceList}
                            onChange={selected => {
                                this.baseDataChange('source', selected);
                            }}
                        />
                        <HInput
                            label={'负责人'}
                            rule={{ required: true, validation, message: '请输入负责人' }}
                            value={resp}
                            onChange={e => {
                                this.baseDataChange('resp', e.target.value);
                            }}
                        />
                        <HLabelInput label={'区域'}>
                            <Cascader
                                size="small"
                                fieldNames={{ label: 'name', value: 'areaCode' }}
                                value={defaultRegion}
                                options={areaList}
                                style={{ width: '100%' }}
                                onChange={e => {
                                    //e[1]就是选择的region
                                    this.setState({ defaultRegion: e[1] ? [e[1].slice(0, 9) + '000', e[1]] : [] });
                                    this.baseDataChange('region', e[1]);
                                    this.init([119.10710103038937, 25.320522258053902]); //根据相应的选择，展示区域的中心点,这里使用静态数据
                                }}
                                placeholder="请选择"
                            />
                        </HLabelInput>
                        <HLabelInput label={'坐标信息'}>
                            <Input
                                size="small"
                                value={position}
                                onChange={e => {
                                    this.setState({
                                        position: e.target.value
                                    });
                                }}
                            ></Input>
                            <Tooltip title="定位">
                                <Button
                                    onClick={() => {
                                        const pos = position.split(',').map(Number);
                                        this.init(pos); //init 里面的position应该是数组
                                    }}
                                    icon={<EnvironmentFilled />}
                                />
                            </Tooltip>
                        </HLabelInput>
                        <HLabelInput label={'地图搜索'}>
                            <Input
                                size="small"
                                value={inputAddress}
                                onChange={e => {
                                    this.setState({ inputAddress: e.target.value });
                                }}
                            ></Input>
                            <Tooltip title="搜索">
                                <Button
                                    onClick={() => {
                                        const getGeoCode = HAMap.geoCoder();
                                        const geocoder = new getGeoCode.Geocoder({
                                            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                                            // city: '010'
                                        });
                                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                                        const self = this;
                                        geocoder.getLocation(inputAddress, function (status, result) {
                                            if (status === 'complete' && result.info === 'OK') {
                                                const lnglat = result.geocodes[0].location; //这个就是经纬度
                                                // console.log(lnglat.lng + ',' + lnglat.lat);
                                                //好像这里失败了,可能是this指针丢失
                                                self.setState({
                                                    position: lnglat.lng + ',' + lnglat.lat
                                                });
                                                // console.log(position);
                                                const pos = position.split(',').map(Number);
                                                self.init([lnglat.lng, lnglat.lat]);
                                            } else {
                                                message.error('根据地址查询位置失败');
                                            }
                                        });
                                    }}
                                    icon={<SearchOutlined />}
                                />
                            </Tooltip>
                        </HLabelInput>
                        <HInput
                            label={'地址详情'}
                            value={address}
                            onChange={e => {
                                this.baseDataChange('address', e.target.value);
                            }}
                        />
                        <HInput
                            label={'规格说明'}
                            value={remark}
                            onChange={e => {
                                this.baseDataChange('remark', e.target.value);
                            }}
                        />
                        <HInput
                            label={'维养单位'}
                            value={maintenanceUnit}
                            onChange={e => {
                                this.baseDataChange('maintenanceUnit', e.target.value);
                            }}
                        />
                        <HInput
                            label={'联系方式'}
                            value={phone}
                            onChange={e => {
                                this.baseDataChange('phone', e.target.value);
                            }}
                        />
                        <HInput
                            label={'建设单位'}
                            value={constructionUnit}
                            onChange={e => {
                                this.baseDataChange('constructionUnit', e.target.value);
                            }}
                        />
                        <HInput
                            label={'竣工时间'}
                            value={completionTime}
                            onChange={e => {
                                this.baseDataChange('completionTime', e.target.value);
                            }}
                        />
                        <div className={'h-plan-map'} id={'get-plan-center-map'} />
                        <div className={'h-text-center'} style={{ padding: '13px 0' }}>
                            <HButton onClick={this.save}>保存数据</HButton>
                            <HButton onClick={this.props.onCancel}>取消</HButton>
                        </div>
                    </HCollapse>
                ) : (
                    <div></div>
                )}
            </div>
        );
    }
}

const mapState = state => ({
    projectList: state.getIn(['monitor', 'getAllProject']),
    areaList: state.getIn(['monitor', 'areaCode'])
});

const mapDispatch = dispatch => ({
    getAllProjectList(key) {
        dispatch(actionCreators.getAllProjectList(key));
    },
    getAreaList() {
        dispatch(actionCreators.getAreaList());
    }
});
export default connect(mapState, mapDispatch)(FormContent);
