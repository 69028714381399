import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    searchData: '',
    current: 1,
    count: 0,
    dataSource: [],
    normalUserList: [],
    leaderList: []
});

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_DATA:
            return state['set']('dataSource', action.value);
        case constants.SET_CURRENT:
            return state['set']('current', action.value);
        case constants.SET_COUNT:
            return state['set']('count', action.value);
        case constants.SET_NORMAL_USER_LIST:
            return state['set']('normalUserList', action.value);
        case constants.SET_LEADER_LIST:
            return state['set']('leaderList', action.value);
        default:
            return state;
    }
};

export default reducer;
