import React, { Component } from 'react';
import { message } from 'antd';
import { HButton, HCollapse } from '../../../components';
import { EditProjectModel, ProjectModel } from './model';
import { HSelect, HInput, HGetPosition } from '../../../components';
import { SourceType } from '../../../utils/static-data';
import ProjectService from '../../../services/project';

interface Props {
    isAdd: boolean;
    normalUserList: SourceType[];
    leaderList: SourceType[];
    onSuccess: () => void;
    editData?: EditProjectModel;
}

interface States {
    formData: ProjectModel;
    validation: boolean;
}

class HProjectForm extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                contractor: '',
                projectCode: '',
                projectName: '',
                projectRegion: '',
                // projectStage: "",
                remark: '',
                informant: [],
                leader: [],
                principal: [],
                userPool: [],
                position: []
            },
            validation: false
        };
    }

    componentDidMount(): void {
        this.setFormData();
    }

    setFormData = () => {
        const { editData, isAdd } = this.props;
        const { formData } = this.state;
        if (editData && !isAdd) {
            Object.keys(formData).map((key, i) => {
                if (key === 'leader' || key === 'informant' || key === 'principal' || key === 'userPool') {
                    if (editData[key]) formData[key] = editData[key].map(it => it.id);
                } else if (key === 'position') {
                    if (editData[key]) formData[key] = editData[key].join(',');
                } else {
                    formData[key] = editData[key];
                }
                if (i === Object.keys(formData).length - 1) {
                    formData['id'] = editData.id;
                    this.setState({
                        formData
                    });
                }
            });
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (this.props.editData !== prevProps.editData) {
            this.setFormData();
        }
    }

    onChange = (key, value) => {
        const { formData } = this.state;
        formData[key] = value;
        this.setState({ formData });
    };

    onSelectChange = (key, selected) => {
        const { formData } = this.state;
        formData[key] = selected;
        this.setState({
            formData
        });
    };

    // onCascaderChange = (key, selected) => {
    //     const {formData} = this.state;
    //     formData[key] = selected;
    //     this.setState({
    //         formData
    //     })
    // };

    save = () => {
        this.setState(
            {
                validation: true
            },
            () => {
                const { formData } = this.state;
                const { projectCode, projectName, position, leader, principal, userPool } = formData;
                if (projectCode && projectName && position.length > 0 && leader.length > 0 && principal.length > 0 && userPool.length > 0) {
                    ProjectService.edit(formData).then(() => {
                        message.success(`${formData.id ? '修改' : '添加'}成功`);
                        this.props.onSuccess();
                    });
                }
            }
        );
    };

    // cascaderChange = (value, options) => {
    //     if (value.length === 0) {
    //         this.onCascaderChange("projectRegion", "");
    //         this.onChange("position", "")
    //     } else {
    //         this.onCascaderChange("projectRegion", value.join(","));
    //         const data = options.pop();
    //         this.onChange("position", data.center)
    //     }
    // };

    getPosition = data => {
        this.onChange('position', data.position.join(','));
        this.onChange('projectRegion', data.address);
    };

    render() {
        const { isAdd, normalUserList, leaderList } = this.props;
        const {
            formData: {
                contractor,
                informant,
                leader,
                principal,
                projectCode,
                projectName,
                projectRegion,
                position,
                // projectStage,
                userPool
            },
            validation
        } = this.state;

        return (
            <div>
                <HCollapse title={'基本信息'}>
                    <HInput
                        label={'项目编号'}
                        rule={{ required: true, validation, message: '请输入项目编号' }}
                        value={projectCode}
                        onChange={e => this.onChange('projectCode', e.target.value)}
                    />
                    <HInput
                        label={'项目名称'}
                        rule={{ required: true, validation, message: '请输入项目名称' }}
                        value={projectName}
                        onChange={e => this.onChange('projectName', e.target.value)}
                    />
                    <HInput
                        label={'承担单位'}
                        rule={{ required: true, validation, message: '请输入承担单位' }}
                        value={contractor}
                        onChange={e => this.onChange('contractor', e.target.value)}
                    />
                    {/*<HCascader label={"项目地区"} rule={{required: true, validation, message: "请输入项目地区"}}*/}
                    {/*           value={projectRegion ? projectRegion.split(",") : []}*/}
                    {/*           onChange={this.cascaderChange}/>*/}
                    <HInput label={'坐标信息'} rule={{ required: true, validation, message: '请选择位置信息' }} value={position} readOnly={true} />
                    <HInput label={'项目地区'} rule={{ required: true, validation, message: '请输入项目地区' }} value={projectRegion} />
                    <HGetPosition onChange={this.getPosition} />
                    <HSelect
                        label={'领导'}
                        rule={{ required: true, validation, message: '请选择领导' }}
                        multiple={true}
                        value={leader}
                        dataSource={leaderList}
                        onChange={selected => this.onSelectChange('leader', selected)}
                    />
                    <HSelect
                        label={'负责人'}
                        rule={{ required: true, validation, message: '请选择负责人' }}
                        multiple={true}
                        value={principal}
                        dataSource={normalUserList}
                        onChange={selected => this.onSelectChange('principal', selected)}
                    />
                    <HSelect
                        label={'填报人'}
                        multiple={true}
                        value={informant}
                        dataSource={normalUserList}
                        onChange={selected => this.onSelectChange('informant', selected)}
                    />
                    <HSelect
                        label={'用户群'}
                        rule={{ required: true, validation, message: '请选择用户群' }}
                        multiple={true}
                        value={userPool}
                        dataSource={normalUserList}
                        onChange={selected => this.onSelectChange('userPool', selected)}
                    />
                    <div className={'h-text-center'} style={{ marginTop: 20 }}>
                        <HButton onClick={this.save}>{isAdd ? '确定' : '保存'}</HButton>
                    </div>
                </HCollapse>
            </div>
        );
    }
}

export default HProjectForm;
