/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';
import { HContentLeft, HContentRight, HContentCollapse, HIcon } from '../../../components';
import HSysBase from './base';
import Reference from './reference';
import axios from 'axios';
import { SysModel } from './model';
import SystemService from '../../../services/system';

interface States {
    sysData: SysModel;
}

class SystemSet extends Component<{}, States> {
    state = {
        sysData: null
    };

    componentDidMount(): void {
        this.getConfig();
    }

    getConfig = () => {
        SystemService.getData().then(data => {
            this.setState({
                sysData: data
            });
        });
    };

    render() {
        const { sysData } = this.state;
        return (
            <>
                <HContentLeft>
                    <HSysBase data={sysData} />
                    <Reference data={sysData} />

                    <HContentCollapse
                        title={'引擎'}
                        style={{
                            height: '136px'
                        }}
                        suffix={
                            <>
                                <HIcon iconName={'icon-lock'} />
                            </>
                        }
                        children={''}
                    ></HContentCollapse>
                </HContentLeft>
                <HContentRight>
                    <div className={'mini-login-box'}></div>
                    <div className={'system-right-content'}></div>
                </HContentRight>
            </>
        );
    }
}

export default SystemSet;
