import axios from 'axios';

export class CadService {
    static edit(data) {
        return axios.put('/cad/edit', data);
    }

    static del(id) {
        return axios.delete('/cad/delete/' + id);
    }
}
