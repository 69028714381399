/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { HMenu, HIcon, HSearch } from '../../components';
import HTerrain from './terrain';
import HModel from './model';
import HCad from './cad';
import HLabel from './label';
import HDocument from './document';
import HPanorama from './panorama';
import HSediment from './sediment';
import HInspection from './inspection';
import './index.scss';

const menuList = [
    {
        title: '地形数据'
    },
    {
        title: '模型数据'
    },
    {
        title: 'CAD数据'
    },
    {
        title: '标签数据'
    },
    {
        title: '文档数据'
    },
    {
        title: '全景数据'
    },
    {
        title: '冲淤数据'
    },
    {
        title: '巡检数据'
    },
    // {
    //     title: "回淤数据"
    // },
    // {
    //     title: "预测数据"
    // },
    // {
    //     title: "监测数据"
    // },
    // {
    //     title: "监控数据"
    // },
    // {
    //     title: "轨迹数据"
    // },
];

interface Props { }

interface State {
    menuActive: number;
}

class Upload extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            menuActive: 0
        };
    }

    changeMenu = value => {
        switch (value) {
            case 0:
                return <HTerrain />;
            case 1:
                return <HModel />;
            case 2:
                return <HCad />;
            case 3:
                return <HLabel />;
            case 4:
                return <HDocument />;
            case 5:
                return <HPanorama />;
            case 6:
                return <HSediment />;
            case 7:
                return <HInspection />;
            // case 8:
            //     return <div>预测数据</div>;
            // case 9:
            //     return <div>监测数据</div>;
            // case 9:
            //     return <div>监控数据</div>;
            // case 10:
            //     return <div>轨迹数据</div>;
        }
    };

    onSearch = e => {
        // console.log(e.target.value);
    };

    render() {
        const { menuActive } = this.state;
        return (
            <div>
                <HMenu
                    data={menuList}
                    activeKey={menuActive}
                    onClick={value =>
                        this.setState({
                            menuActive: value
                        })
                    }
                    suffix={
                        <>
                            <HSearch isUpdate={menuActive} onChange={this.onSearch} />
                        </>
                    }
                />
                <div className={'h-content-box'}>{this.changeMenu(menuActive)}</div>
            </div>
        );
    }
}

export default Upload;
