/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Component } from 'react';
import { Button, DatePicker, Input, Table } from 'antd';
import LogService from '../../services/log';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1
    },
    {
        title: '用户名',
        dataIndex: 'account',
        key: 'account',
        render: (text, record) => <span>{record.name + `（${text}）`}</span>
    },
    { title: '登录时间', dataIndex: 'createDate', key: 'createDate' },
    { title: 'IP', dataIndex: 'ip', key: 'ip' },
    { title: '地区', dataIndex: 'address', key: 'address' }
];

interface Props {}

interface States {
    beginTime: string;
    endTime: string;
    page: number;
    size: number;
    key: string;
    dataSource: any[];
    total: number;
}

class LoginLog extends Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            beginTime: '',
            endTime: '',
            page: 1,
            size: 12,
            key: '',
            dataSource: [],
            total: 0
        };
    }

    componentDidMount(): void {
        const { page, size } = this.state;
        this.getList({ page, size });
    }

    getList = param => {
        const { page, size } = param;
        LogService.getLoginLog(param).then(data => {
            if (data) {
                this.setState({
                    dataSource: data.list,
                    total: data.total,
                    page,
                    size
                });
            }
        });
    };

    dateChange = (date, dateString) => {
        this.setState(
            {
                beginTime: dateString[0] || '',
                endTime: dateString[1] || ''
            },
            () => {
                this.onSearch();
            }
        );
    };

    keyChange = e => {
        const key = e.target.value;
        this.setState(
            {
                key
            },
            () => {
                if (!key) {
                    this.onSearch();
                }
            }
        );
    };

    onPageChange = (page, pageSize) => {
        this.setState(
            {
                page,
                size: pageSize
            },
            () => {
                const { beginTime, endTime, size, key } = this.state;
                this.getList({ beginTime, endTime, page, size, key });
            }
        );
    };

    onSearch = () => {
        const { beginTime, endTime, size, key } = this.state;
        this.getList({ beginTime, endTime, page: 1, size, key });
    };

    render() {
        const { dataSource, page, size, total, key } = this.state;
        const paginationProps = {
            current: page,
            pageSize: size,
            total,
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: this.onPageChange,
            size: 'small' as 'default' | 'small'
        };

        return (
            <div className="bg">
                <div className="search-bar">
                    <RangePicker size="small" format={dateFormat} onChange={this.dateChange} />
                    <Input
                        size="small"
                        value={key}
                        style={{ width: 146, height: 24, paddingLeft: 12 }}
                        allowClear
                        placeholder={'关键词'}
                        onChange={this.keyChange}
                        onPressEnter={this.onSearch}
                    />
                    <Button type="primary" size="small" style={{ width: 56, height: 24 }} onClick={this.onSearch}>
                        查询
                    </Button>
                </div>
                <Table
                    className={'table'}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={record => record.id}
                    pagination={paginationProps}
                />
            </div>
        );
    }
}

export default LoginLog;
