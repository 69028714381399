import React, { Component } from 'react';
import { HContentLayout, HDataOverview, HDataTopBar, HDescriptions, HDrawer } from '../../../components';
import { TableLine, HInfoLine } from '../../../components';
import { connect } from 'react-redux';
import { actionCreators } from '../store';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import { DataCount, GetListParam } from '../store/model';
import { message } from 'antd';
import HModelForm from './form';
import { GroupDataTypeMap } from '../../../utils/static-data';
import { GroupModel } from '../../../components/model';
import GroupService from '../../../services/group';

const rightList = [
    {
        key: 'unit',
        title: '单位',
        value: '',
        suffix: { key: 'principal', title: '负责人', value: '' }
    },
    {
        key: 'createDate',
        title: '上传时间',
        value: ''
    },
    {
        key: 'dataFormat',
        title: '数据格式',
        value: ''
    },
    {
        key: 'size',
        title: '数据大小',
        value: ''
    }
];

interface Props {
    dataSource: any[];
    current: number;
    count: number;
    getList?: (data: GetListParam) => void;
    changeList?: (list) => void;
    getDataCount?: (url) => void;
    dataCount: DataCount;
    groupList: GroupModel[];
    getGroupList: () => void;
}

interface States {
    isPublic: number;
    isAdd: boolean;
    isEdit: boolean;
    activeButton: number;
    activeId: number;
    activeData: any;
    hasMore: boolean;
    editData: any;
}

class HModel extends Component<Props, States> {
    constructor(props) {
        super(props);
        this.state = {
            isPublic: 1,
            isAdd: false,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: undefined,
            hasMore: true,
            editData: {}
        };
    }

    componentDidMount() {
        const { isPublic } = this.state;
        this.props.getList({ isPublic, current: 1 });
        this.props.getDataCount('/model/model_count');
        this.props.getGroupList();
    }

    handleInfiniteOnLoad = page => {
        const { dataSource, getList, count } = this.props;
        const { isPublic } = this.state;
        if (page > count) {
            this.setState({
                hasMore: false
            });
        } else {
            getList({ isPublic, current: page, data: dataSource });
        }
    };

    publicChange = value => {
        this.setState({
            isPublic: value,
            hasMore: true,
            isAdd: false,
            isEdit: false
        });
        this.props.getList({ isPublic: value, current: 1 });
    };

    lineClick = data => {
        const { activeId, isEdit } = this.state;
        if (!isEdit) {
            rightList.map(r => {
                r.value = data[r.key];
                if (r.suffix) r.suffix.value = data[r.suffix.key];
            });
            this.setState({
                activeData: data.id === activeId ? undefined : { title: data.name, picUrl: data.picUrl, list: rightList },
                activeId: data.id === activeId ? 0 : data.id
            });
        }
    };

    onDelete = (id, key) => {
        const { dataSource } = this.props;
        const { activeId, activeData } = this.state;
        axios.delete('/model/delete/' + id).then(res => {
            if (res['code'] === 200) {
                message.success('删除成功');
                dataSource.splice(key, 1);
                const newList = [...dataSource];
                this.props.changeList(newList);
                this.setState({
                    activeData: id === activeId ? undefined : activeData,
                    activeId: id === activeId ? 0 : activeId
                });
            }
        });
    };

    onAdd = () => {
        const { isAdd } = this.state;
        this.setState({
            isAdd: !isAdd,
            isEdit: false,
            activeButton: 0,
            activeId: 0,
            activeData: {}
        });
    };

    // 编辑按钮事件
    onEditChange = (visible, data?, key?) => {
        this.setState({
            isEdit: visible,
            isAdd: false,
            activeId: visible ? data.id : 0,
            activeButton: visible ? key : 0,
            editData: visible ? data : {}
        });
    };

    closeDrawer = () => {
        const { isAdd } = this.state;
        if (isAdd) {
            this.setState({
                isAdd: false
            });
        } else {
            this.onEditChange(false);
        }
    };

    onSaveSuccess = () => {
        this.closeDrawer();
        const { isPublic } = this.state;
        this.props.getList({ isPublic, current: 1 });
    };

    addGroup = name => {
        GroupService.edit({
            isPublic: 1,
            name,
            type: GroupDataTypeMap.model
        }).then(() => {
            this.props.getGroupList();
        });
    };

    updateGroupChange = data => {
        GroupService.edit(data).then(() => {
            this.props.getGroupList();
        });
    };

    onDeleteGroup = id => {
        GroupService.del(id).then(() => {
            this.props.getGroupList();
        });
    };

    groupChange = id => {
        const { isPublic } = this.state;
        const { current } = this.props;
        this.setState({
            hasMore: true
        });
        const data = id ? { isPublic, groupId: id, current } : { isPublic, current };
        this.props.getList(data);
    };

    render() {
        const { isPublic, isAdd, isEdit, activeId, activeButton, hasMore, activeData, editData } = this.state;
        const { dataSource, dataCount, groupList } = this.props;
        return (
            <>
                <HContentLayout
                    left={
                        <div>
                            <HDataTopBar
                                addGroup={this.addGroup}
                                isPublic={isPublic}
                                groupList={groupList}
                                onGroupChange={this.groupChange}
                                onPublicChange={this.publicChange}
                                onAdd={this.onAdd}
                            />
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={1}
                                loadMore={this.handleInfiniteOnLoad}
                                hasMore={hasMore}
                                useWindow={false}
                            >
                                {dataSource.map((r, i) => {
                                    return (
                                        <TableLine
                                            key={r.id}
                                            data={r}
                                            imgUrl={r.picUrl}
                                            isPublic={isPublic}
                                            showDate={true}
                                            buttonList={[
                                                {
                                                    iconName: 'icon-edit',
                                                    activeIcon: 'icon-edit-fill',
                                                    onClick: (e, key) => {
                                                        // e.stopPropagation();
                                                        this.onEditChange(true, r, key);
                                                    }
                                                }
                                            ]}
                                            activeButton={activeButton}
                                            isButtonActive={r.id === activeId}
                                            activeTable={activeId}
                                            onDelete={() => this.onDelete(r.id, i)}
                                            onClick={() => this.lineClick(r)}
                                        />
                                    );
                                })}
                            </InfiniteScroll>
                        </div>
                    }
                    right={
                        <div>
                            {activeData && activeData.list ? (
                                <div>
                                    <div className="h-preview-img h-flex-center">
                                        <img src={activeData.picUrl} />
                                    </div>
                                    <HDescriptions title={activeData.title}>
                                        {activeData.list.map((it, i) => {
                                            return (
                                                <HInfoLine
                                                    key={i}
                                                    title={it.title}
                                                    value={it.value}
                                                    suffixWidth={150}
                                                    suffixBgColor={it.suffix ? 'rgba(240, 247, 253, 1)' : '#FBFBFB'}
                                                    suffix={
                                                        it.suffix ? (
                                                            <span>
                                                                {it.suffix.title}：{it.suffix.value}
                                                            </span>
                                                        ) : null
                                                    }
                                                />
                                            );
                                        })}
                                    </HDescriptions>
                                </div>
                            ) : (
                                <HDataOverview title={'模型数据总览'} data={dataCount} />
                            )}
                        </div>
                    }
                />
                {isAdd && (
                    <HDrawer iconName={'icon-upload'} title={'上传'} shutDown={this.closeDrawer}>
                        <HModelForm
                            isAdd={isAdd}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
                {isEdit && (
                    <HDrawer iconName={'icon-edit'} title={'编辑'} shutDown={this.closeDrawer}>
                        <HModelForm
                            isAdd={false}
                            addGroup={this.addGroup}
                            updateGroupChange={this.updateGroupChange}
                            onDeleteGroup={this.onDeleteGroup}
                            groupList={groupList}
                            editData={editData}
                            onSuccess={this.onSaveSuccess}
                        />
                    </HDrawer>
                )}
            </>
        );
    }
}

const mapState = state => ({
    dataSource: state.getIn(['upload', 'dataSource']),
    current: state.getIn(['upload', 'current']),
    count: state.getIn(['upload', 'count']),
    dataCount: state.getIn(['upload', 'dataCount']),
    groupList: state.getIn(['upload', 'groupList'])
});

const mapDispatch = dispatch => ({
    getList({ key, isPublic, current, data }) {
        dispatch(
            actionCreators.getPageList({
                url: '/model/query',
                key,
                isPublic,
                current,
                data
            })
        );
    },
    changeList(list) {
        dispatch(actionCreators.changeList(list));
    },
    getDataCount(url) {
        dispatch(actionCreators.getDataCount(url));
    },
    getGroupList() {
        dispatch(actionCreators.getGroupList({ isPublic: 1, type: GroupDataTypeMap.model }));
    }
});

export default connect(mapState, mapDispatch)(HModel);
