import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './utils/store';
import './utils/axios';
import './index.scss';
import './app.less';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <Router />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);
